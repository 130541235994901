import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PromotionScreens from 'src/app/modules/promotion/Screens'
import { FallbackView } from '../components/Layout/FallbackView'
import CustomerListScreens from '../modules/customer/Screens'
import DashboardScreens from '../modules/dashboard/Screens'
import GeneralConfigScreens from '../modules/general_config/Screens'
import InstantRewardsScreens from '../modules/instant_rewards/InstantRewardsScreens'
import MenuManagementScreens from '../modules/menu_management/Screens'
import OrderScreens from '../modules/order/Screens'
import OutletScreens from '../modules/outlet/Screens'
import AdminRoleScreens from '../modules/permissions/Screens'
import ProfileScreens from '../modules/profile/Screens'
import ListSectionBannerPage from '../modules/promotion/page/SectionBanner/ListSectionBanner.page'
import VoucherScreens from '../modules/voucher/Screens'
import { ModulesComponentScreens } from '../modules_component/Screens'
import TierBenefitScreens from '../modules/tier_benefit/Screens'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))
  const UserRolePage = lazy(() => import('../modules/permissions/AdminRoleRoutes'))
  const MenuManagmenetPage = lazy(() => import('../modules/menu_management/MenuManagementRoutes'))
  const CustomerPage = lazy(() => import('../modules/customer/CustomerListRoutes'))
  const IndexBannerPage = lazy(() => import('../modules/promotion/PromotionRoutes'))
  const PromotionPage = lazy(() => import('../modules/promotion/PromotionRoutes'))
  const OutletPage = lazy(() => import('../modules/outlet/OutletRoutes'))
  const VoucherPage = lazy(() => import('../modules/voucher/VoucherRoutes'))
  const GeneralConfigPage = lazy(() => import('../modules/general_config/GeneralConfigRoutes'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfileRoutes'))
  const OrderPage = lazy(() => import('../modules/order/OrderRoutes'))
  const InstantRewardsPage = lazy(() => import('../modules/instant_rewards/InstantRewardsRoutes'))

  //modules_components
  const ModulesComponentsPage = lazy(() => import('../modules_component/ModulesComponentRoutes'))

  // Loyalty
  const TierBenefitPage = lazy(()=> import('../modules/tier_benefit/TierBenefitRoutes'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
        <Route path={AdminRoleScreens.USER_ROLE.PATH} component={UserRolePage} />
        <Route path={CustomerListScreens.CUSTOMER.PATH} component={CustomerPage} />
        <Route path={PromotionScreens.PROMOTION.PATH} component={PromotionPage} />
        <Route path={OutletScreens.OUTLET.PATH} component={OutletPage} />
        <Route path={VoucherScreens.VOUCHER.PATH} component={VoucherPage} />
        <Route path={MenuManagementScreens.MENU_MANAGEMENT.PATH} component={MenuManagmenetPage} />
        <Route path={GeneralConfigScreens.GENERAL_CONFIG.PATH} component={GeneralConfigPage} />
        <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
        <Route path={PromotionScreens.PROMOTION.PATH} component={IndexBannerPage} />
        <Route path={PromotionScreens.SECTION_BANNER.PATH} component={ListSectionBannerPage} />
        <Route path={OrderScreens.ORDER_LIST.PATH} component={OrderPage} />
        <Route path={InstantRewardsScreens.INSTANT_REWARDS.PATH} component={InstantRewardsPage} />
        <Route
          path={ModulesComponentScreens.MODULES_COMPONENT.PATH}
          component={ModulesComponentsPage}
        />
        <Route
          path={TierBenefitScreens.TIER_BENEFIT.PATH}
          component={TierBenefitPage}
        />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
