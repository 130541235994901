import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormError} from '../FormError'
import {FormRadioProps} from './Radio.types'

const FormRadio: FC<FormRadioProps> = ({
  value,
  options,
  disabled = false,
  className,
  inputClassName,
  touched,
  error,
  checkedValue,
  changedValue,
  ...rest
}) => {
  return (
    <>
      <div
        data-testid='formradio-test'
        className={cn('w-full md:flex md:space-x-6 py-2', className)}
      >
        {options?.map((option, index) => {
          if (option.value === undefined)
            return <div key={option.value ?? index} className='flex-1'></div>

          const isDisabled = disabled || option.disabled;
          return (
            <div key={option.value as string} className='flex items-center flex-1 mb-4 md:mb-0'>
              <label className='flex items-center'>
                <input
                  type='radio'
                  checked={checkedValue === option.value}
                  onChange={() => changedValue(option.value)}
                  className={cn('text-success focus:ring-success0', inputClassName, { 'cursor-not-allowed': isDisabled, 'cursor-pointer': !isDisabled })}
                  disabled={isDisabled}
                  {...rest}
                />
                <span className='ml-4 text-neutral-80 text-fs-9'>{option.label}</span>
              </label>
            </div>
          )
        })}
      </div>

      {touched && error && <FormError>{error}</FormError>}
    </>
  )
}

export {FormRadio}
