import clsx from 'clsx'
import {FC, ReactNode} from 'react'
import VIcon from '../VIcon/VIcon'

export type VAvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export enum AvatarSizes {
  xs = 'w-6 h-6',
  sm = 'w-8 h-8',
  md = 'w-10 h-10',
  lg = 'w-12 h-12',
  xl = 'w-14 h-14',
  '2xl' = 'w-16 h-16',
}

export enum IconSizes {
  xs = 'w-4 h-4',
  sm = 'w-5 h-5',
  md = 'w-6 h-6',
  lg = 'w-7 h-7',
  xl = 'w-8 h-8',
  '2xl' = 'w-9 h-9',
}

export enum DotOulineSizes {
  xs = 'w-[8px] h-[8px]',
  sm = 'w-[10px] h-[10px]',
  md = 'w-[12px] h-[12px]',
  lg = 'w-[14px] h-[14px]',
  xl = 'w-[16px] h-[16px]',
  '2xl' = 'w-[18px] h-[18px]',
}

export interface VAvatarProps {
  children?: ReactNode
  size?: VAvatarSize
  dotStatus?: boolean
  customDotStatus?: ReactNode
}

const VAvatar: FC<VAvatarProps> = ({children, size = 'md', dotStatus, customDotStatus}) => {
  return (
    <div
      data-testid='vavatar-test'
      className={clsx(
        AvatarSizes[size],
        'relative rounded bg-primary-50 text-primary-500 flex items-center justify-center overflow-hidden'
      )}
    >
      {children ?? <VIcon icon='user' type='base' className={clsx(IconSizes[size])} />}

      {dotStatus && (
        <div
          className={clsx(
            'absolute bottom-[-1px] right-[-1px] bg-white flex items-end justify-end rounded-full rounded-br-none p-[2px]',
            DotOulineSizes[size]
          )}
        >
          {customDotStatus ?? (
            <div className={clsx('bg-success-500 rounded-full w-full h-full')}></div>
          )}
        </div>
      )}
    </div>
  )
}

export default VAvatar
