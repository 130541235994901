import clsx from 'clsx'
import {FC, ReactNode} from 'react'
import {Tooltip} from 'react-tooltip'

interface Props {
  tooltipId: string
  children?: ReactNode
  noArrow?: boolean
  typeContent?: 'email' | 'password' | 'period'
  variant?: 'LIGHT' | 'DARK'
  position?: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end'; 
}

const ContentTootips = (data: any) => {
  return (
  <div className='flex flex-row'>
    <div className='flex justify-center items-center mr-3'>
      <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3996 8C14.3996 11.5346 11.5342 14.4 7.99961 14.4C4.46499 14.4 1.59961 11.5346 1.59961 8C1.59961 4.46538 4.46499 1.6 7.99961 1.6C11.5342 1.6 14.3996 4.46538 14.3996 8ZM8.79961 4.8C8.79961 5.24183 8.44144 5.6 7.99961 5.6C7.55778 5.6 7.19961 5.24183 7.19961 4.8C7.19961 4.35817 7.55778 4 7.99961 4C8.44144 4 8.79961 4.35817 8.79961 4.8ZM7.19961 7.2C6.75778 7.2 6.39961 7.55817 6.39961 8C6.39961 8.44183 6.75778 8.8 7.19961 8.8V11.2C7.19961 11.6418 7.55778 12 7.99961 12H8.79961C9.24144 12 9.59961 11.6418 9.59961 11.2C9.59961 10.7582 9.24144 10.4 8.79961 10.4V8C8.79961 7.55817 8.44144 7.2 7.99961 7.2H7.19961Z" fill="currentColor"/>
      </svg>
    </div>
    <div className='flex flex-col mt-1 text-neutral-200 text-fs-9 text-wrap max-w-28'>
      {data.typeContent === 'period' ? 
        <span>Period is used as a cut-off membership level</span>
      :
        <>
          <span>Please input registered {data.typeContent == 'email' ? 'email' : 'password'} to access this</span>
          <span>dashboard or {data.typeContent == 'email' ? 'contact Super Admin' : 'reset password'}.</span>
        </>
      }
      
    </div>
  </div>
  )
}
const VTooltip: FC<Props> = ({tooltipId, children, variant = 'LIGHT', position = 'top', noArrow = false, typeContent}) => {
  // NOTES : PLEASE MAKE SURE YOU HAVE data-tooltip-id EQUAL TO tooltipId //
  return (
    <Tooltip
      data-testid='v-tooltip-test'
      id={tooltipId}
      className={clsx(
        'rounded-lg shadow-lg',
        variant === 'LIGHT' && 'bg-white text-neutral-800',
        variant === 'DARK' && 'bg-neutral-70',
      )}
      place={position}
      noArrow={noArrow}
    >
      {children ? children : <ContentTootips typeContent={typeContent} />}
    </Tooltip>
  )
}

export default VTooltip
