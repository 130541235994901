import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const MenuManagementScreens = {
  MENU_MANAGEMENT: {
    PATH: '/menu-management',
    TITLE: 'Menu Management',
    TITLE_ALT: 'Menu Management',
    PERMISSION: 'menu.get-list',
  },
  MENU_LIST: {
    PATH: '/menu-management/menu-list',
    HEADER_NAME: 'Menu',
    TITLE: 'Menu',
    TITLE_ALT: 'Menu',
    PERMISSION: 'menu.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  MENU_DETAIL: {
    PATH: '/menu-management/menu-list/detail/:id/:tab',
    HEADER_NAME: 'View Detail Menu',
    TITLE: 'View Detail Menu',
    TITLE_ALT: 'View Detail Menu',
    PERMISSION: 'menu.view-detail',
    breadcrumbs: defaultBreadcrumbs,
  },
  BEST_SELLER: {
    PATH: '/menu-management/best-seller/:tab',
    HEADER_NAME: 'Best Seller',
    TITLE: 'Best Seller',
    TITLE_ALT: 'Best Seller',
    PERMISSION: 'best-seller.get-list',
    buildPath: (tab: string) => `/menu-management/best-seller/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  CREATE_BEST_SELLER: {
    PATH: '/menu-management/best-seller/list/create',
    HEADER_NAME: 'Create Best Seller',
    TITLE: 'Create Best Seller',
    TITLE_ALT: 'Create Best Seller',
    PERMISSION: 'best-seller.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_BEST_SELLER: {
    PATH: '/menu-management/best-seller/list/edit/:id',
    HEADER_NAME: 'Edit Best Seller',
    TITLE: 'Edit Best Seller',
    TITLE_ALT: 'Edit Best Seller',
    PERMISSION: 'best-seller.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  BEST_SELLER_DETAIL: {
    PATH: '/menu-management/best-seller/list/detail/:id',
    HEADER_NAME: 'Best Seller Detail',
    TITLE: 'Best Seller Detail',
    TITLE_ALT: 'Best Seller Detail',
    PERMISSION: 'best-seller.view-detail',
    breadcrumbs: defaultBreadcrumbs,
  },
}


export const ListBestSellerTab = {
  FEATURED_BEST_SELLER: {
    PATH: '/menu-management/best-seller/featured',
    TITLE: 'Best Seller List',
    TITLE_ALT: 'Best Seller List',
    LABEL: 'Best Seller List',
    SLUG: 'featured',
    PERMISSION: 'best-seller.get-list',
  },
  LIST_BEST_SELLER: {
    PATH: '/menu-management/best-seller/list',
    TITLE: 'Best Seller List',
    TITLE_ALT: 'Best Seller List',
    LABEL: 'Best Seller List',
    SLUG: 'list',
    PERMISSION: 'best-seller.get-list',
  },
}

const listBreadcrumbs = generateBreadcrumb([MenuManagementScreens.MENU_MANAGEMENT])

MenuManagementScreens.BEST_SELLER.breadcrumbs = () => listBreadcrumbs
MenuManagementScreens.CREATE_BEST_SELLER.breadcrumbs = () => listBreadcrumbs
MenuManagementScreens.EDIT_BEST_SELLER.breadcrumbs = () => listBreadcrumbs

export const DetailMenuListTab = {
  MENU_INFO: {
    PATH: '/menu-management/menu-list/detail/:id/menu-info',
    TITLE: 'Menu Info',
    TITLE_ALT: 'Menu Info',
    LABEL: 'Menu Info',
    SLUG: 'menu-info',
  },
  OUTLET_INFO: {
    PATH: '/menu-management/menu-list/detail/:id/outlet-info',
    TITLE: 'Outlet Info',
    TITLE_ALT: 'Outlet Info',
    LABEL: 'Outlet Info',
    SLUG: 'outlet-info',
  },
  SCHEDULE_MENU: {
    PATH: '/menu-management/menu-list/detail/:id/schedule-menu',
    TITLE: 'Schedule Menu',
    TITLE_ALT: 'Schedule Menu',
    LABEL: 'Schedule Menu',
    SLUG: 'schedule-menu',
  },
  MENU_SET: {
    PATH: '/menu-management/menu-list/detail/:id/menu-set',
    TITLE: 'Menu Set',
    TITLE_ALT: 'Menu Set',
    LABEL: 'Menu Set',
    SLUG: 'menu-set',
  },
  DETAIL_PRICE: {
    PATH: '/menu-management/menu-list/detail/:id/detail-price',
    TITLE: 'Detail Price',
    TITLE_ALT: 'Detail Price',
    LABEL: 'Detail Price',
    SLUG: 'detail-price',
  },
}

export default MenuManagementScreens
