import clsx from 'clsx'
import {FC} from 'react'

interface Props {
  position?: 'Horizontal' | 'Vertical'
  variant?: 'dashed' | 'dotted' | 'solid'
}

export enum VDividerVariant {
  dashed = 'border-dashed',
  dotted = 'border-dotted',
  solid = 'border-solid',
}

export enum VDividerPosition {
  Horizontal = 'w-full border-t',
  Vertical = 'h-full border-l',
}

const VDivider: FC<Props> = ({position = 'Horizontal', variant = 'solid'}) => {
  return (
    <div
      data-testid='v-divider-test'
      className={clsx('border-neutral-300', VDividerVariant[variant], VDividerPosition[position])}
    />
  )
}

export default VDivider
