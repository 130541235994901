import clsx from 'clsx'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMasterLayout } from 'src/app/context/MasterContext'
import { toAbsoluteUrl } from 'src/app/utils/asset-helpers-utils'
import { cn } from 'src/app/utils/cn-utils'
import { Card } from '../../Libs'
import { KTSVG } from '../../Libs/Icon/KTSVG'
import { AsideMenu } from './AsideMenu'

const AsideDefault: FC = () => {
  const { minimize, setMinimize } = useMasterLayout()
  const [isHovering, setIsHovering] = useState(false)
  const onMouseEnter = () => setIsHovering(true)
  const onMouseLeave = () => setIsHovering(false)

  return (
    <div
      className={cn(
        'bg-neutral-100 fixed top-0 left-0 bottom-0 z-[110] transition-all duration-300 group flex flex-col -translate-x-full',
        'lg:translate-x-0',
        'dark:bg-inverse-primary-light-1',
        { 'lg:w-[72px]': minimize && !isHovering },
        { 'lg:w-[264px]': minimize && isHovering },
        { 'translate-x-0 w-[264px]': !minimize }
      )}
    >
      {/* LOGO  */}
      <div className='relative flex items-center justify-center'>
        <div className='flex justify-center items-center h-[80px] px-6 text-fs-5 font-semibold text-white'>
          <Link to='/dashboard' className='relative flex items-center justify-center'>
            {minimize ? (
              <>
                {isHovering ? (
                  <img
                    src={toAbsoluteUrl('/media/logos/Product.svg')}
                    alt='Product'
                    className='h-12'
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl('/media/logos/Product-Small.svg')}
                    alt='Product'
                    className='h-5'
                  />
                )}
              </>
            ) : (
              <img src={toAbsoluteUrl('/media/logos/Product.svg')} alt='Product' className='h-12' />
            )}
          </Link>
        </div>

        {/* TOGGLER */}
        <Card.Root
          data-testid='toggle-button'
          onClick={() => setMinimize(!minimize)}
          className={cn(
            'cursor-pointer z-1 absolute border-0 w-9 h-9 rounded top-50 right-0 translate-x-[50%] items-center justify-center',
            'lg:flex',
            { hidden: minimize },
            { flex: !minimize }
          )}
        >
          <KTSVG
            path={'/media/icons/IconDoubleChevronLeft.svg'}
            className={clsx('h-24px w-24px transition-all duration-300', {
              'rotate-180': minimize,
            })}
          />
        </Card.Root>
      </div>

      {/* begin::Aside menu */}
      <div className='flex flex-col flex-1 w-auto h-full p-0 m-0 grid content-between'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <AsideMenu />
        <div className='w-full grid justify-items-center'>
          <img src='/cms/media/logos/stripe.png' className='w-16 h-6' />
        </div>
      </div>
      {/* end::Aside menu */}
    </div>
  )
}

export default AsideDefault
