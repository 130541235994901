import { twMerge } from "tailwind-merge";
import { HelperTextProps } from "./HelperText.types";

export function HelperText({
  variant = 'error',
  children,
}: Readonly<HelperTextProps>) {

  const isChildrenString = typeof children === "string";

  return <div
    dangerouslySetInnerHTML={isChildrenString ? { __html: children } : undefined}
    className={twMerge(
      "mt-2 font-medium text-fs-10",
      variant === "success" && "text-success",
      variant === "error" && "text-danger",
      variant === "loading" && "text-neutral-50"
    )}
  >
    {isChildrenString ? undefined : children}
  </div>
}
