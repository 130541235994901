export enum VIconBase {
  '2-layers' = '/media/icons_v2/base/2-layers.svg',
  '3-layers' = '/media/icons_v2/base/3-layers.svg',
  'activity' = '/media/icons_v2/base/activity.svg',
  'airplay' = '/media/icons_v2/base/airplay.svg',
  'alert-circle' = '/media/icons_v2/base/alert-circle.svg',
  'alert-octagon' = '/media/icons_v2/base/alert-octagon.svg',
  'alert-triangle' = '/media/icons_v2/base/alert-triangle.svg',
  'align-center' = '/media/icons_v2/base/align-center.svg',
  'align-justify' = '/media/icons_v2/base/align-justify.svg',
  'align-left' = '/media/icons_v2/base/align-left.svg',
  'align-right' = '/media/icons_v2/base/align-right.svg',
  'anchor' = '/media/icons_v2/base/anchor.svg',
  'aperture' = '/media/icons_v2/base/aperture.svg',
  'archive' = '/media/icons_v2/base/archive.svg',
  'arrow-down-circle' = '/media/icons_v2/base/arrow-down-circle.svg',
  'arrow-down-left' = '/media/icons_v2/base/arrow-down-left.svg',
  'arrow-down-right' = '/media/icons_v2/base/arrow-down-right.svg',
  'arrow-down' = '/media/icons_v2/base/arrow-down-svg',
  'arrow-left-circle' = '/media/icons_v2/base/arrow-left-circle.svg',
  'arrow-left' = '/media/icons_v2/base/arrow-left.svg',
  'arrow-right-circle' = '/media/icons_v2/base/arrow-right-circle.svg',
  'arrow-right' = '/media/icons_v2/base/arrow-right.svg',
  'arrow-up-circle' = '/media/icons_v2/base/arrow-up-circle.svg',
  'arrow-up-left' = '/media/icons_v2/base/arrow-up-left.svg',
  'arrow-up-right' = '/media/icons_v2/base/arrow-up-right.svg',
  'arrow-up' = '/media/icons_v2/base/arrow-up-svg',
  'at-sign' = '/media/icons_v2/base/at-sign.svg',
  'award' = '/media/icons_v2/base/award.svg',
  'bar-chart-2' = '/media/icons_v2/base/bar-chart-2.svg',
  'bar-chart' = '/media/icons_v2/base/bar-chart.svg',
  'battery-charging' = '/media/icons_v2/base/battery-charging.svg',
  'battery' = '/media/icons_v2/base/battery.svg',
  'bell-off' = '/media/icons_v2/base/bell-off.svg',
  'bell' = '/media/icons_v2/base/bell.svg',
  'bluetooth' = '/media/icons_v2/base/bluetooth.svg',
  'bold' = '/media/icons_v2/base/bold.svg',
  'book-open' = '/media/icons_v2/base/book-open.svg',
  'book' = '/media/icons_v2/base/book.svg',
  'bookmark' = '/media/icons_v2/base/bookmark.svg',
  'box' = '/media/icons_v2/base/box.svg',
  'briefcase' = '/media/icons_v2/base/briefcase.svg',
  'calendar' = '/media/icons_v2/base/calendar.svg',
  'camera-off' = '/media/icons_v2/base/camera-off.svg',
  'camera' = '/media/icons_v2/base/camera.svg',
  'cast' = '/media/icons_v2/base/cast.svg',
  'check-circle-inside' = '/media/icons_v2/base/check-circle-inside.svg',
  'check-circle' = '/media/icons_v2/base/check-circle.svg',
  'check-square' = '/media/icons_v2/base/check-square.svg',
  'check' = '/media/icons_v2/base/check.svg',
  'chevron-down' = '/media/icons_v2/base/chevron-down.svg',
  'chevron-left' = '/media/icons_v2/base/chevron-left.svg',
  'chevron-right' = '/media/icons_v2/base/chevron-right.svg',
  'chevron-up' = '/media/icons_v2/base/chevron-up.svg',
  'chrome' = '/media/icons_v2/base/chrome.svg',
  'circle' = '/media/icons_v2/base/circle.svg',
  'clipboard' = '/media/icons_v2/base/clipboard.svg',
  'clock' = '/media/icons_v2/base/clock.svg',
  'cloud-drizzle' = '/media/icons_v2/base/cloud-drizzle.svg',
  'cloud-lightning' = '/media/icons_v2/base/cloud-lightning.svg',
  'cloud-off' = '/media/icons_v2/base/cloud-off.svg',
  'cloud-rain' = '/media/icons_v2/base/cloud-rain.svg',
  'cloud-snow' = '/media/icons_v2/base/cloud-snow.svg',
  'cloud' = '/media/icons_v2/base/cloud.svg',
  'code' = '/media/icons_v2/base/code.svg',
  'codepen' = '/media/icons_v2/base/codepen.svg',
  'codesandbox' = '/media/icons_v2/base/codesandbox.svg',
  'coffee' = '/media/icons_v2/base/coffee.svg',
  'coin-stack' = '/media/icons_v2/base/coin-stack.svg',
  'coins' = '/media/icons_v2/base/coins.svg',
  'columns' = '/media/icons_v2/base/columns.svg',
  'command' = '/media/icons_v2/base/command.svg',
  'compass' = '/media/icons_v2/base/compass.svg',
  'copy' = '/media/icons_v2/base/copy.svg',
  'corner-down-left' = '/media/icons_v2/base/corner-down-left.svg',
  'corner-down-right' = '/media/icons_v2/base/corner-down-right.svg',
  'corner-left-down' = '/media/icons_v2/base/corner-left-down.svg',
  'corner-left-up' = '/media/icons_v2/base/corner-left-up.svg',
  'corner-right-down' = '/media/icons_v2/base/corner-right-down.svg',
  'corner-right-up' = '/media/icons_v2/base/corner-right-up.svg',
  'corner-up-left' = '/media/icons_v2/base/corner-up-left.svg',
  'corner-up-right' = '/media/icons_v2/base/corner-up-right.svg',
  'cpu' = '/media/icons_v2/base/cpu.svg',
  'credit-card' = '/media/icons_v2/base/credit-card.svg',
  'crop' = '/media/icons_v2/base/crop.svg',
  'crosshair' = '/media/icons_v2/base/crosshair.svg',
  'database' = '/media/icons_v2/base/database.svg',
  'delete' = '/media/icons_v2/base/delete.svg',
  'disc' = '/media/icons_v2/base/disc.svg',
  'divide-circle' = '/media/icons_v2/base/divide-circle.svg',
  'divide-square' = '/media/icons_v2/base/divide-square.svg',
  'divide' = '/media/icons_v2/base/divide.svg',
  'dollar-sign' = '/media/icons_v2/base/dollar-sign.svg',
  'download-cloud' = '/media/icons_v2/base/download-cloud.svg',
  'download' = '/media/icons_v2/base/download.svg',
  'dribbbble' = '/media/icons_v2/base/dribbbble.svg',
  'droplet' = '/media/icons_v2/base/droplet.svg',
  'edit-2' = '/media/icons_v2/base/edit-2.svg',
  'edit-3' = '/media/icons_v2/base/edit-3.svg',
  'edit' = '/media/icons_v2/base/edit.svg',
  'external-link' = '/media/icons_v2/base/external-link.svg',
  'eye-off' = '/media/icons_v2/base/eye-off.svg',
  'eye' = '/media/icons_v2/base/eye.svg',
  'facebook' = '/media/icons_v2/base/facebook.svg',
  'fast-forward' = '/media/icons_v2/base/fast-forward.svg',
  'feather' = '/media/icons_v2/base/feather.svg',
  'figma' = '/media/icons_v2/base/figma.svg',
  'file-minus' = '/media/icons_v2/base/file-minus.svg',
  'file-plus' = '/media/icons_v2/base/file-plus.svg',
  'file-text' = '/media/icons_v2/base/file-text.svg',
  'file' = '/media/icons_v2/base/file.svg',
  'film' = '/media/icons_v2/base/film.svg',
  'filter-lines' = '/media/icons_v2/base/filter-lines.svg',
  'filter' = '/media/icons_v2/base/filter.svg',
  'flag' = '/media/icons_v2/base/flag.svg',
  'folder-minus' = '/media/icons_v2/base/folder-minus.svg',
  'folder-plus' = '/media/icons_v2/base/folder-plus.svg',
  'folder' = '/media/icons_v2/base/folder.svg',
  'framer' = '/media/icons_v2/base/framer.svg',
  'frown' = '/media/icons_v2/base/frown.svg',
  'gift' = '/media/icons_v2/base/gift.svg',
  'git-branch' = '/media/icons_v2/base/git-branch.svg',
  'git-commit' = '/media/icons_v2/base/git-commit.svg',
  'git-merge' = '/media/icons_v2/base/git-merge.svg',
  'git-pull-request' = '/media/icons_v2/base/git-pull-request.svg',
  'github' = '/media/icons_v2/base/github.svg',
  'gitlab' = '/media/icons_v2/base/gitlab.svg',
  'globe' = '/media/icons_v2/base/globe.svg',
  'grid' = '/media/icons_v2/base/grid.svg',
  'hard-drive' = '/media/icons_v2/base/hard-drive.svg',
  'hash' = '/media/icons_v2/base/hash.svg',
  'headphones' = '/media/icons_v2/base/headphones.svg',
  'heart' = '/media/icons_v2/base/heart.svg',
  'help-circle' = '/media/icons_v2/base/help-circle.svg',
  'hexagon' = '/media/icons_v2/base/hexagon.svg',
  'history' = '/media/icons_v2/base/history.svg',
  'home' = '/media/icons_v2/base/home.svg',
  'image' = '/media/icons_v2/base/image.svg',
  'inbox' = '/media/icons_v2/base/inbox.svg',
  'info' = '/media/icons_v2/base/info.svg',
  'instagram' = '/media/icons_v2/base/instagram.svg',
  'italic' = '/media/icons_v2/base/italic.svg',
  'key' = '/media/icons_v2/base/key.svg',
  'layout' = '/media/icons_v2/base/layout.svg',
  'life-buoy' = '/media/icons_v2/base/life-buoy.svg',
  'link-2' = '/media/icons_v2/base/link-2.svg',
  'link' = '/media/icons_v2/base/link.svg',
  'linkedin' = '/media/icons_v2/base/linkedin.svg',
  'list' = '/media/icons_v2/base/list.svg',
  'loader' = '/media/icons_v2/base/loader.svg',
  'lock' = '/media/icons_v2/base/lock.svg',
  'log-on' = '/media/icons_v2/base/log-on.svg',
  'log-out' = '/media/icons_v2/base/log-out.svg',
  'mail' = '/media/icons_v2/base/mail.svg',
  'map-in' = '/media/icons_v2/base/map-in.svg',
  'map' = '/media/icons_v2/base/map.svg',
  'maximize-2' = '/media/icons_v2/base/maximize-2.svg',
  'maximize' = '/media/icons_v2/base/maximize.svg',
  'meh' = '/media/icons_v2/base/meh.svg',
  'menu-2' = '/media/icons_v2/base/menu-2.svg',
  'menu' = '/media/icons_v2/base/menu.svg',
  'message-circle' = '/media/icons_v2/base/message-circle.svg',
  'message-square' = '/media/icons_v2/base/message-square.svg',
  'mic-off' = '/media/icons_v2/base/mic-off.svg',
  'mic' = '/media/icons_v2/base/mic.svg',
  'minimize-2' = '/media/icons_v2/base/minimize-2.svg',
  'minimize' = '/media/icons_v2/base/minimize.svg',
  'minus-circle' = '/media/icons_v2/base/minus-circle.svg',
  'minus-square' = '/media/icons_v2/base/minus-square.svg',
  'minus' = '/media/icons_v2/base/minus.svg',
  'monitor' = '/media/icons_v2/base/monitor.svg',
  'moon' = '/media/icons_v2/base/moon.svg',
  'more-horizontal' = '/media/icons_v2/base/more-horizontal.svg',
  'more-vertical' = '/media/icons_v2/base/more-vertical.svg',
  'mouse-pointer' = '/media/icons_v2/base/mouse-pointer.svg',
  'move' = '/media/icons_v2/base/move.svg',
  'music' = '/media/icons_v2/base/music.svg',
  'navigation-2' = '/media/icons_v2/base/navigation-2.svg',
  'navigation' = '/media/icons_v2/base/navigation.svg',
  'octagon' = '/media/icons_v2/base/octagon.svg',
  'package' = '/media/icons_v2/base/package.svg',
  'paperclip' = '/media/icons_v2/base/paperclip.svg',
  'pause-circle' = '/media/icons_v2/base/pause-circle.svg',
  'pause' = '/media/icons_v2/base/pause.svg',
  'pen-tool' = '/media/icons_v2/base/pen-tool.svg',
  'percent' = '/media/icons_v2/base/percent.svg',
  'phone-call' = '/media/icons_v2/base/phone-call.svg',
  'phone-forwarded' = '/media/icons_v2/base/phone-forwarded.svg',
  'phone-incoming' = '/media/icons_v2/base/phone-incoming.svg',
  'phone-missed' = '/media/icons_v2/base/phone-missed.svg',
  'phone-off' = '/media/icons_v2/base/phone-off.svg',
  'phone-outgoing' = '/media/icons_v2/base/phone-outgoing.svg',
  'phone' = '/media/icons_v2/base/phone.svg',
  'pie-chart' = '/media/icons_v2/base/pie-chart.svg',
  'play-circle' = '/media/icons_v2/base/play-circle.svg',
  'play' = '/media/icons_v2/base/play.svg',
  'plus-circle' = '/media/icons_v2/base/plus-circle.svg',
  'plus-square' = '/media/icons_v2/base/plus-square.svg',
  'plus' = '/media/icons_v2/base/plus.svg',
  'pocket' = '/media/icons_v2/base/pocket.svg',
  'power' = '/media/icons_v2/base/power.svg',
  'printer' = '/media/icons_v2/base/printer.svg',
  'radio' = '/media/icons_v2/base/radio.svg',
  'refresh-ccw' = '/media/icons_v2/base/refresh-ccw.svg',
  'refresh-cw' = '/media/icons_v2/base/refresh-cw.svg',
  'repeat' = '/media/icons_v2/base/repeat.svg',
  'rewind' = '/media/icons_v2/base/rewind.svg',
  'rocket' = '/media/icons_v2/base/rocket.svg',
  'rotate-ccw' = '/media/icons_v2/base/rotate-ccw.svg',
  'rotate-cw' = '/media/icons_v2/base/rotate-cw.svg',
  'rss' = '/media/icons_v2/base/rss.svg',
  'save' = '/media/icons_v2/base/save.svg',
  'scissors' = '/media/icons_v2/base/scissors.svg',
  'search' = '/media/icons_v2/base/search.svg',
  'send' = '/media/icons_v2/base/send.svg',
  'server' = '/media/icons_v2/base/server.svg',
  'settomgs' = '/media/icons_v2/base/settomgs.svg',
  'share-2' = '/media/icons_v2/base/share-2.svg',
  'share' = '/media/icons_v2/base/share.svg',
  'shiled-off' = '/media/icons_v2/base/shiled-off.svg',
  'shiled' = '/media/icons_v2/base/shiled.svg',
  'shopping-bag' = '/media/icons_v2/base/shopping-bag.svg',
  'shopping-cart' = '/media/icons_v2/base/shopping-cart.svg',
  'shuffle' = '/media/icons_v2/base/shuffle.svg',
  'sidebar' = '/media/icons_v2/base/sidebar.svg',
  'skip-back' = '/media/icons_v2/base/skip-back.svg',
  'skip-forward' = '/media/icons_v2/base/skip-forward.svg',
  'slack' = '/media/icons_v2/base/slack.svg',
  'slash-divider' = '/media/icons_v2/base/slash-divider.svg',
  'slash' = '/media/icons_v2/base/slash.svg',
  'sliders' = '/media/icons_v2/base/sliders.svg',
  'smartphone' = '/media/icons_v2/base/smartphone.svg',
  'smile' = '/media/icons_v2/base/smile.svg',
  'speaker' = '/media/icons_v2/base/speaker.svg',
  'square' = '/media/icons_v2/base/square.svg',
  'star' = '/media/icons_v2/base/star.svg',
  'stop-circle' = '/media/icons_v2/base/stop-circle.svg',
  'sun' = '/media/icons_v2/base/sun.svg',
  'sunrise' = '/media/icons_v2/base/sunrise.svg',
  'sunset' = '/media/icons_v2/base/sunset.svg',
  'tablet' = '/media/icons_v2/base/tablet.svg',
  'tag' = '/media/icons_v2/base/tag.svg',
  'target' = '/media/icons_v2/base/target.svg',
  'terminal' = '/media/icons_v2/base/terminal.svg',
  'thermometer' = '/media/icons_v2/base/thermometer.svg',
  'thumbs-down' = '/media/icons_v2/base/thumbs-down.svg',
  'thumbs-up' = '/media/icons_v2/base/thumbs-up.svg',
  'toggle-left' = '/media/icons_v2/base/toggle-left.svg',
  'toggle-right' = '/media/icons_v2/base/toggle-right.svg',
  'tool' = '/media/icons_v2/base/tool.svg',
  'trash-2' = '/media/icons_v2/base/trash-2.svg',
  'trash' = '/media/icons_v2/base/trash.svg',
  'trello' = '/media/icons_v2/base/trello.svg',
  'trending-down' = '/media/icons_v2/base/trending-down.svg',
  'trending-up' = '/media/icons_v2/base/trending-up.svg',
  'triangle' = '/media/icons_v2/base/triangle.svg',
  'truck' = '/media/icons_v2/base/truck.svg',
  'tv' = '/media/icons_v2/base/tv.svg',
  'twitch' = '/media/icons_v2/base/twitch.svg',
  'twitter' = '/media/icons_v2/base/twitter.svg',
  'type' = '/media/icons_v2/base/type.svg',
  'umbrella' = '/media/icons_v2/base/umbrella.svg',
  'underline' = '/media/icons_v2/base/underline.svg',
  'unlock' = '/media/icons_v2/base/unlock.svg',
  'upload-cloud' = '/media/icons_v2/base/upload-cloud.svg',
  'upload' = '/media/icons_v2/base/upload.svg',
  'user-check' = '/media/icons_v2/base/uschecklus.svg',
  'user-minus' = '/media/icons_v2/base/usminuslus.svg',
  'user-plus' = '/media/icons_v2/base/user-plus.svg',
  'user-x' = '/media/icons_v2/base/user-x.svg',
  'user' = '/media/icons_v2/base/user.svg',
  'users' = '/media/icons_v2/base/users.svg',
  'video-off' = '/media/icons_v2/base/video-off.svg',
  'video' = '/media/icons_v2/base/video.svg',
  'voicemail' = '/media/icons_v2/base/voicemail.svg',
  'volume-1' = '/media/icons_v2/base/volume-1.svg',
  'volume-2' = '/media/icons_v2/base/volume-2.svg',
  'volume-x' = '/media/icons_v2/base/volume-x.svg',
  'volume' = '/media/icons_v2/base/volume.svg',
  'watch' = '/media/icons_v2/base/watch.svg',
  'wifi-off' = '/media/icons_v2/base/wifi-off.svg',
  'wifi' = '/media/icons_v2/base/wifi.svg',
  'wind' = '/media/icons_v2/base/wind.svg',
  'x-circle' = '/media/icons_v2/base/x-circle.svg',
  'x-square' = '/media/icons_v2/base/x-square.svg',
  'x' = '/media/icons_v2/base/x.svg',
  'youtube' = '/media/icons_v2/base/youtube.svg',
  'zap-off' = '/media/icons_v2/base/zap-off.svg',
  'zap' = '/media/icons_v2/base/zap.svg',
  'zoom-in' = '/media/icons_v2/base/zoom-in.svg',
  'zoom-out' = '/media/icons_v2/base/zoom-out.svg',
}
