import {PageLink} from 'src/app/context/PageDataContext'
import PromotionScreens from '../../Screens'

export const breadcrumbsCreateBanner: PageLink[] = [
  {
    isActive: false,
    path: PromotionScreens.MAIN_BANNER.FEATURE,
    title: PromotionScreens.MAIN_BANNER.HEADER_NAME,
  },
  { isActive: false, path: '', title: '', isSeparator: true },
]

export const breadcrumbsDetailBanner: PageLink[] = [
  {
    isActive: false,
    path: PromotionScreens.MAIN_BANNER.FEATURE,
    title: PromotionScreens.MAIN_BANNER.HEADER_NAME,
  },
  { isActive: false, path: '', title: '', isSeparator: true },
]

export const breadcrumbsMainBanner: PageLink[] = [
  {
    isActive: false,
    path: PromotionScreens.MAIN_BANNER.FEATURE,
    title: PromotionScreens.MAIN_BANNER.HEADER_NAME,
  },
  { isActive: false, path: '', title: '', isSeparator: true },
]
