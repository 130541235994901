
const InstantRewardsScreens = {
  INSTANT_REWARDS: {
    PATH: '/instant-rewards',
    TITLE: 'Instant Rewards',
    TITLE_ALT: 'Instant Rewards',
    PERMISSION: 'instant-reward.get-list',
  },
  LIST_INSTANT_REWARDS: {
    PATH: '/instant-rewards/list',
    TITLE: 'Instant Rewards List',
    TITLE_ALT: 'Instant Rewards List',
    PERMISSION: 'instant-reward.get-list',
  },
  ADD_INSTANT_REWARDS: {
    PATH: '/instant-rewards/add',
    TITLE: 'Create Instant Rewards',
    TITLE_ALT: 'Create Instant Rewards',
    PERMISSION: 'instant-reward.create',
  },
  DETAIL_INSTANT_REWARDS: {
    PATH: '/instant-rewards/detail/:id',
    TITLE: 'View Detail Instant Rewards',
    TITLE_ALT: 'View Detail Instant Rewards',
    PERMISSION: 'instant-reward.view-detail',
  },
  EDIT_INSTANT_REWARDS: {
    PATH: '/instant-rewards/edit/:id',
    TITLE: 'Edit Instant Rewards',
    TITLE_ALT: 'Edit Instant Rewards',
    PERMISSION: 'instant-reward.update',
  }
};

export default InstantRewardsScreens;
