import { hasArray } from "src/app/utils/array-utils";
import { SelectOption } from "../Select";
import { MultiSelectAllOption } from "./MultiSelectAll.types";
import { TaggingProps } from "./Tagging.types";

export function Tagging<T extends MultiSelectAllOption | SelectOption>({
  onDeleteItem,
  data
}: Readonly<TaggingProps<T>>) {

  const handleDeleteItem = (item: T, index: number) => {
    data?.splice(index, 1);
    onDeleteItem?.(item, index, data!);
  };

  if (!hasArray(data)) {
    return null;
  }

  return (
    <div className="p-4 mt-2 bg-white border rounded-2xl border-neutral-30">
      <div className="grid grid-cols-4 gap-2 overflow-y-auto max-h-48">
        {data?.map((item, index) => (
          <div
            key={`${item.value}`}
            className="flex items-center px-2 py-1 rounded-md bg-info-light-2"
          >
            <span className="flex-grow text-sm text-info">{item.value}</span>
            <button className="text-info-dark-1 hover:text-info-dark-2" onClick={() => handleDeleteItem(item, index)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}