import {cva} from 'class-variance-authority'

export const iconButton = cva(
  ['outline-0', 'border-0', 'p-0', 'w-8', 'h-8', 'border-none', 'rounded-xl'],
  {
    variants: {
      colors: {
        primary: ['bg-primary-light-2', 'text-primary'],
        secondary: ['bg-secondary-light-2', 'text-secondary'],
        success: ['bg-success-light-2', 'text-success'],
        danger: ['bg-danger-light-2', 'text-danger'],
        warning: ['bg-warning-light-2', 'text-warning'],
        info: ['bg-info-light-2', 'text-info'],
        neutral: ['bg-neutral-20', 'text-neutral-60'],
        transparent: ['bg-transparent', 'text-neutral-60'],
      },
    },
    compoundVariants: [
      {
        colors: 'primary',
      },
    ],
    defaultVariants: {
      colors: 'primary',
    },
  }
)
