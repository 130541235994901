import { FC, MouseEventHandler, useState } from "react";
import { FormLabel, FormSelect, GButton, GModal } from "../../Libs";
import GIcon from "../../Libs/Icon/GIcon";
import { Calendar, DateObject } from "react-multi-date-picker";

interface CalendarModalProps {
  title: string
  range?: boolean
  isShowCalendar: boolean
  setIsShowCalendar: Function
  setSelectedDate: Function
  value: any
  maxDate?: Date
  setValue: Function
  showSelectYear?: boolean
  reset: MouseEventHandler
  apply: MouseEventHandler
}

interface Option {
  label: string
  value: number
}

const CalendarModal: FC<CalendarModalProps> = ({
  title,
  range = true,
  isShowCalendar,
  setIsShowCalendar,
  setSelectedDate,
  value,
  maxDate,
  setValue,
  showSelectYear = false,
  reset,
  apply
}) => {
  const [year, setYear] = useState<number>()
  const generateYearOptions = (): Option[] => {
    const currentYear: number = new Date().getFullYear()
    const options: Option[] = []

    for (let year = currentYear; year >= 1970; year--) {
      options.push({ label: String(year), value: year })
    }

    return options
  };

  const options: Option[] = generateYearOptions()

  return (
    <GModal show={isShowCalendar} onHide={() => setIsShowCalendar(false)} className='w-full'>
      < div className='w-full flex justify-between items-center px-4 py-3 gap-4 border-b border-neutral-30' >
        <div className='font-bold text-lg'>{title}</div>
        <div><GIcon className='h-6 cursor-pointer' onClick={() => setIsShowCalendar(false)} icon='IconClose'></GIcon></div>
      </div>
      {showSelectYear && <div className="w-full grid grid-cols-2 items-center px-6 py-4 border-b border-neutral-30">
        <FormLabel required={true}>Year of Birth</FormLabel>
        <FormSelect options={options} placeholder="Select Year" selectedValue={year} changedValue={(val) => {
          setYear(val.value as number)
          setValue(
            new DateObject(value).set({
              year: val.value as number,
            })
          );
        }} className="z-[999999]" />
      </div>
      }
      <div className='flex justify-center items-center py-3 px-4'>
        <Calendar
          range={range}
          highlightToday={false}
          rangeHover
          value={value}
          onChange={(date) => setValue(date)}
          format={'DD/MM/YYYY'}
          numberOfMonths={2}
          className='custom-calendar'
          shadow={false}
          maxDate={maxDate}
          style={{
            border: 'none'
          }} />
      </div>
      <div className='w-full flex justify-end items-center px-4 py-3 gap-4 border-t border-neutral-30'>
        <GButton
          size='small'
          variant='OUTLINED'
          className='bg-netral border-primary hover:bg-primary-light-1'
          onClick={reset}
        >
          Reset
        </GButton>
        <GButton
          size='small'
          variant='FILLED'
          onClick={apply}
        >
          Apply
        </GButton>
      </div>
    </GModal >)
}

export default CalendarModal