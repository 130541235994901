import clsx from 'clsx'
import React, {useMemo} from 'react'
import {FileRejection, useDropzone} from 'react-dropzone'
import {GButton} from '../../Button/Button.component'
import GIcon from '../../Icon/GIcon'

export interface FormUploadImage2Props {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void
  onClear: () => void
  preview: string | undefined
  title: string
  message: string
  disabled?: boolean
  required?: boolean
  error?: string
  touched?: boolean
}

const FormUploadImage2: React.FC<FormUploadImage2Props> = ({
  onDrop,
  onClear,
  preview,
  title,
  message,
  disabled,
  required,
  error,
  touched,
}) => {
  const {getRootProps, getInputProps, isDragActive} = useDropzone(
    useMemo(
      () => ({
        onDrop,
        multiple: false,
        accept: ['.jpg', '.jpeg', '.png'],
      }),
      [onDrop]
    )
  )

  return (
    <div className='w-100'>
      <div {...(disabled ? {} : getRootProps())} className={clsx('w-100 rounded-lg')}>
        {disabled ? null : <input {...getInputProps()} />}
        <div className='w-full'>
          {preview ? (
            <div className='w-full flex items-center h-[120px]'>
              <div className='bg-neutral-30 h-[120px] w-[120px] rounded-full flex items-center justify-center overflow-hidden'>
                <img
                  alt={title}
                  src={preview}
                  className='object-contain w-full h-full rounded-lg'
                />
              </div>
              <div className='flex flex-col items-start justify-between h-full py-4 ml-9 text-neutral-70'>
                <div>
                  <div className='mb-2 font-medium text-fs-10'>Upload Image (JPG/PNG)</div>
                  <div className='text-fs-11'>
                    {isDragActive ? `Drop the files here ...` : message}
                  </div>
                </div>
                <div className='flex'>
                  <GButton type='button' size='small'>
                    Change Photo
                  </GButton>

                  <GButton
                    data-testid='gbutton-test'
                    variant='OUTLINED'
                    className='ml-3'
                    size='small'
                    type='button'
                    onClick={(event) => {
                      event.stopPropagation()
                      onClear()
                    }}
                  >
                    Remove Photo
                  </GButton>
                </div>
              </div>
            </div>
          ) : (
            <div className='w-full flex items-center h-[120px]'>
              <div className='bg-neutral-30 h-[120px] w-[120px] rounded-full flex items-center justify-center overflow-hidden'>
                <GIcon icon='IconUserFriends' className='text-white' />
              </div>
              <div className='flex flex-col items-start justify-between h-full py-4 ml-9 text-neutral-70'>
                <div>
                  <div className='mb-2 font-medium text-fs-10'>Upload Image (JPG/PNG)</div>
                  <div className='text-fs-11'>
                    {isDragActive ? `Drop the files here ...` : message}
                  </div>
                </div>
                <GButton type='button' size='small'>
                  Select Photo
                </GButton>
              </div>
            </div>
          )}
        </div>
      </div>
      {error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export {FormUploadImage2}
