import clsx from 'clsx'
import { FC, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMasterLayout } from 'src/app/context/MasterContext'
import { useClickOutside } from 'src/app/hooks/click-outside-hook'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import ProfileScreens from 'src/app/modules/profile/Screens'
import { RootState } from 'src/setup'
import { Card } from '../../Libs'
import GIcon from '../../Libs/Icon/GIcon'
import { KTSVG } from '../../Libs/Icon/KTSVG'
import BasicModal from '../../Widgets/ModalCollection/BasicModal'
import { DefaultTitle } from './DefaultTitle'
import { usePermissions } from 'src/app/hooks/permissions-hook'

const HeaderWrapper: FC = () => {
  const history = useHistory()
  const { hasAccess } = usePermissions()
  const { minimize, setMinimize } = useMasterLayout()
  const user: any = useSelector<RootState>(({ auth }) => auth?.user, shallowEqual)
  const dispatch = useDispatch()
  const [clickOutsideRef, isShow, setIsShow] = useClickOutside(false)
  const [logoutModal, setLogoutModal] = useState<boolean>(false)
  const [imageError, setImageError] = useState<boolean>(false);

  const handleLogout = () => {
    setIsShow(false)
    dispatch(AuthRedux.actions.logout())
  }

  return (
    <>
      <div
        className={clsx(
          'fixed top-0 right-0 z-[99] h-[55px] p-0 shadow-none bg-white flex items-stretch transition-all duration-300',
          'lg:h-[80px]',
          'dark:bg-inverse-primary-light-1 dark:text-white',
          { 'left-0 lg:left-[264px]': !minimize },
          { 'left-0 lg:left-[72px]': minimize }
        )}
      >
        <div className={clsx('px-8 flex items-stretch justify-between w-full mx-auto')}>
          <div className='flex items-center mr-1 lg:hidden'>
            <div
              onClick={() => setMinimize(!minimize)}
              className='inline-flex items-center justify-center w-10 h-10 px-4 border-0 shadow-none outline-none'
            >
              <KTSVG path='/media/icons/IconBurger.svg' className='text-neutral-100' />
            </div>
          </div>

          <div className={clsx('hidden items-stretch justify-between', 'lg:grow lg:flex')}>
            <div className='flex items-center'>
              <DefaultTitle />
            </div>
          </div>

          <div className='flex items-stretch'>
            <div className='flex items-center justify-center'>
              <div className='relative' ref={clickOutsideRef}>
                <div
                  onClick={() => setIsShow(!isShow)}
                  className='flex items-center cursor-pointer select-none'
                  data-testid="toggle-header"
                >
                  <div className='text-end'>
                    <div className='font-semibold text-fs-9'>
                      Hi, {user?.name}
                    </div>
                    <div className='text-fs-10'>{user?.role?.name}</div>
                  </div>
                  <div className='flex items-center justify-center ml-4'>
                    {user?.photo && !imageError && <img src={user?.photo} alt='Preview' className='object-cover w-10 h-10 rounded-full' onError={() => setImageError(true)} />}
                    {!user?.photo || imageError && <div className='flex items-center justify-center w-10 h-10 font-bold rounded-full bg-neutral-20 text-primary'>
                      {user?.name?.charAt(0).toUpperCase()}
                    </div>}
                  </div>
                </div>
                {isShow && (
                  <Card.Root className='absolute right-0 top-16 w-[400px] rounded-lg animate-opacity-to-full'>
                    <div className='flex items-center justify-between py-4 px-9'>
                      <div className='flex items-center'>
                        {user?.photo && !imageError && <img src={user?.photo} alt='Preview' className='object-cover w-10 h-10 rounded-full' onError={() => setImageError(true)} />}
                        {!user?.photo || imageError && <div className='flex items-center justify-center w-10 h-10 font-bold rounded-full bg-neutral-20 text-primary'>
                          {user?.name?.charAt(0).toUpperCase()}
                        </div>}
                        <div className='ml-4'>
                          <div className='font-semibold text-fs-9'>
                            {user?.name}
                          </div>
                          <div className='text-fs-10 text-neutral-70'>{user?.email}</div>
                        </div>
                      </div>
                    </div>

                    <div className='border-t border-neutral-30 text-fs-9'>
                      {hasAccess('profile.get-access') && (
                        <div className='cursor-pointer' onClick={() => {
                          setIsShow(false)
                          history.replace(ProfileScreens.PROFILE.PATH)
                        }}>
                          <div className='py-6 font-semibold px-9'>My Profile</div>
                        </div>
                      )}
                      <div
                        data-testid="button-logout"
                        className='flex items-center justify-between py-3 font-semibold border-t px-9 border-neutral-30 text-primary'
                        onClick={() => {
                          setLogoutModal(true)
                        }}
                      >
                        <label className='cursor-pointer'>Sign Out</label>
                        <div
                          className='flex items-center justify-center w-10 h-10 rounded-lg cursor-pointer'
                        >
                          <GIcon icon='IconSignOut' className='text-danger' />
                        </div>
                      </div>
                    </div>
                  </Card.Root>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BasicModal
        header='Logout'
        show={logoutModal}
        handleSuccess={handleLogout}
        handleClose={() => setLogoutModal(false)}
        positiveLabel='Logout'
        negativeLabel='Cancel'
        size='sm'
      >
        Do you want to logout?
      </BasicModal>
    </>
  )
}

export default HeaderWrapper
