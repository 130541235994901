import {FC} from 'react'
import {GButton} from '../Libs'

interface EmptyTableProps {
  title?: string
  body?: string
  buttonTitle?: string
  buttonHref?: string
}

const EmptyTable: FC<EmptyTableProps> = ({title, body, buttonHref, buttonTitle}) => {
  return (
    <div className='flex flex-col items-center justify-center h-[30vh] -my-8'>
      <div className='mb-2 font-bold text-2xl text-neutral-80'>{title}</div>
      <div className='mb-8 text-neutral-50'>{body}</div>
      {buttonTitle && buttonHref && (
        <div>
          <GButton href={buttonHref} size='large'>
            {buttonTitle}
          </GButton>
        </div>
      )}
    </div>
  )
}

export default EmptyTable
