import clsx from 'clsx'
import {FC, MouseEventHandler, ReactNode} from 'react'
import VIcon from '../VIcon/VIcon'

export type VAlertVariant = 'filled' | 'outline' | 'ghost'
export type VAlertColor =
  | 'primary'
  | 'danger'
  | 'warning'
  | 'info'
  | 'success'
  | 'secondary'
  | 'tertiary'

type Props = {
  children?: ReactNode
  header?: string
  variant?: VAlertVariant
  color?: VAlertColor
  customWidth?: string
  customStartIcon?: ReactNode
  endIcon?: boolean
  customEndIcon?: ReactNode
  clickEndIcon?: MouseEventHandler
}

const VAlert: FC<Props> = ({
  children,
  variant = 'filled',
  color = 'primary',
  header,
  endIcon,
  customEndIcon,
  customStartIcon,
  customWidth,
  clickEndIcon,
}) => {
  return (
    <div
      data-testid='valert-test'
      className={clsx(
        `p-4 rounded-lg shadow-md`,
        customWidth ?? 'w-full',
        `v-alert-${variant}-${color}`
      )}
    >
      <div className='flex'>
        <div className='mr-3'>
          {customStartIcon ?? <VIcon icon='check-circle' type='swm-outline' className='w-6 h-6 ' />}
        </div>

        <div className='w-full'>
          <div className='flex justify-between mb-1'>
            <div className='flex items-center'>
              <div className='text-fs-9'>
                <div className='font-semibold'>{header}</div>
              </div>
            </div>
            {endIcon && (
              <div data-testid='v-alert-end-icon' onClick={clickEndIcon} className='cursor-pointer'>
                {customEndIcon ?? <VIcon icon='cross' type='swm-curved' className='w-6 h-6' />}
              </div>
            )}
          </div>
          <div className='mt-1'>
            <div className='text-fs-9'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VAlert
