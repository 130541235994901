import React from 'react'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  return (
    <div
      data-testid='content-wrapper-component'
      className='flex flex-row items-center justify-center w-screen h-max'
    >
      <div className='flex flex-col items-center justify-between w-1/2 bg-primary h-screen max-h-screen'>
        <div className='flex items-center justify-center'>
          <img src={toAbsoluteUrl('/logo_login.png')} alt='KFC' className='w-36 h-auto mt-12'/> 
        </div>
        <div className='flex items-center justify-center'>
          <img src={toAbsoluteUrl('/image_login.png')} alt='KFC' className='w-80 h-auto max-h-80 my-0' />
        </div>
        <div className='flex flex-col justify-start h-60 w-[36rem] px-16 rounded-t-3xl bg-neutral-10'>
          <div className='w-full flex justify-center'>
            <img src={toAbsoluteUrl('/kfc_stripe.svg')} alt='KFC' className='w-20 h-6' />
          </div>
          <div className='w-full'>
            <h1 className='text-fs-2 font-national2Condensed font-bold text-center mt-8'>Streamline Your Workflow</h1>
            <p className='text-fs-8 text-center mt-2'>Unlock the power of seamless organization with our CMS dashboard. Streamline your workflow, amplify productivity, and elevate collaboration within our office ecosystem.</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center justify-between h-max-[688px] w-1/2 bg-white'>
        <div className='flex-col items-center justify-between h-full py-16 rounded-lg'>
          <div className='w-[30rem]'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default GuestWrapper
