import clsx from 'clsx'
import {FC} from 'react'
import {VIconType} from '../../../VIcon/Icon'
import VIcon from '../../../VIcon/VIcon'
import {VFormTextareaProps} from './VFormTextarea.models'

export const VFormTextarea: FC<VFormTextareaProps> = ({
  className,
  inputClassName,
  error,
  touched,
  type = 'text',
  prependIcon,
  prependInnerIcon,
  appendIcon,
  appendInnerIcon,
  prependClassname,
  prependInnerClassname,
  appendClassname,
  appendInnerClassname,
  clickPrependIcon,
  clickPrependInnerIcon,
  clickAppendIcon,
  clickAppendInnerIcon,
  prependNode,
  appendNode,
  prefix,
  suffix,
  iconType,
  ...rest
}) => {
  return (
    <div className={className}>
      <div className='relative flex items-center border rounded-lg border-neutral-300'>
        {prependNode && <div className={clsx('ml-2')}>{prependNode}</div>}
        {prependIcon && (
          <VIcon
            icon={prependIcon as any}
            type={iconType as VIconType}
            className={clsx('cursor-pointer mr-2 w-5 h-5', prependClassname)}
            onClick={clickPrependIcon}
          />
        )}
        <div className='relative flex items-center flex-grow'>
          {prependInnerIcon && (
            <VIcon
              icon={prependInnerIcon as any}
              type={iconType as VIconType}
              className={clsx('cursor-pointer absolute left-4 w-5 h-5', prependInnerClassname)}
              onClick={clickPrependInnerIcon}
            />
          )}
          {prefix && (
            <div
              data-testid='formtext-prefix'
              className='absolute w-5 h-5 font-medium left-4 text-fs-9'
            >
              {prefix}
            </div>
          )}
          <textarea
            className={clsx(
              'text-fs-8 font-normal',
              'focus:bg-transparent focus:outline-none focus:ring focus:ring-primary-200 focus:border-primary-500',
              'placeholder:text-neutral-400 placeholder:font-normal',
              'disabled:bg-neutral-300 disabled:text-neutral-400',
              {'v-form-invalid': touched && error},
              {'pl-14': prependInnerIcon ?? prefix},
              {'pr-14': appendInnerIcon ?? suffix},
              `${inputClassName ?? ''}`
            )}
            rows={5}
            {...rest}
          />
          {suffix && (
            <div
              data-testid='formtext-suffix'
              className='absolute w-5 h-5 font-medium right-4 text-fs-9'
            >
              {suffix}
            </div>
          )}
          {appendInnerIcon && (
            <VIcon
              type={iconType as VIconType}
              icon={appendInnerIcon as any}
              className={clsx('cursor-pointer absolute right-4 w-5 h-5', appendInnerClassname)}
              onClick={clickAppendInnerIcon}
            />
          )}
        </div>
        {appendIcon && (
          <VIcon
            type={iconType as VIconType}
            icon={appendIcon as any}
            className={clsx('cursor-pointer ml-2 w-5 h-5', appendClassname)}
            onClick={clickAppendIcon}
          />
        )}
        {appendNode && <div className={clsx('ml-2')}>{appendNode}</div>}
      </div>
      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}
