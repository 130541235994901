import { FC, useEffect, useMemo, useState } from "react";
import { GIconButton } from "src/app/components/Libs";
import { TableHeaders } from "src/app/components/Libs/Table/GTable";
import HandleStatusBoolean from "src/app/components/Widgets/HandleStatusBoolean";
import { usePermissions } from "src/app/hooks/permissions-hook";
import { formatDateWib } from "src/app/utils/input-utils";
import { SectionBannerModel } from "../../models/SectionBanner.model";
import PromotionScreens from "../../Screens";

interface ActionButtonProps {
  cell: { row: any }
  setSelected: React.Dispatch<React.SetStateAction<SectionBannerModel | undefined>>
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  countData: number
}

export function useTableFeaturedBanner(data: any, setSelected: any, setDeleteModal: any) {
  const { hasAccess } = usePermissions()

  const ActionButton: FC<ActionButtonProps> = ({ cell: { row }, setSelected, setDeleteModal, countData }) => {
    const color = countData <= 1 ? 'neutral' : 'warning'
    return <div className='flex justify-center gap-2'>
      {hasAccess('section-banner.update') && <GIconButton
        data-tooltip-id='delete-tooltip'
        colors={color}
        icon='IconRemove'
        disabled={!countData}
        onClick={() => {
          if (countData > 1) {
            setDeleteModal(true)
            setSelected(row.original)
          }
        }}
      />}
      {hasAccess('section-banner.view-detail') && <GIconButton
        data-tooltip-id='view-tooltip'
        colors='info'
        href={`${PromotionScreens.DETAIL_SECTION_BANNER.PATH}`.replace(':id', row.original.id)}
        icon='IconView'
      />
      }
    </div>
  }

  const StatusBanner: FC<{ cell: { row: any } }> = ({ cell: { row } }) => <HandleStatusBoolean status={row.values.status === 'ACTIVE'} />

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "no",
        align: "left",
        width: 50,
        Cell: ({ cell: { row } }: { cell: { row: any } }) => (row.index + 1),
      },
      {
        Header: "Section Banner Name",
        accessor: "name",
        align: "left",
        width: 500,
      },
      {
        Header: "Total Content",
        accessor: "total_content",
        align: "left",
        width: 200,

      },
      {
        Header: "Start Date",
        accessor: "start_date",
        align: "left",
        Cell: ({ cell: { row } }: { cell: { row: any } }) => formatDateWib(row.values.start_date),
        width: 150,
      },
      {
        Header: "End Date",
        accessor: "end_date",
        align: "left",
        Cell: ({ cell: { row } }: { cell: { row: any } }) => formatDateWib(row.values.end_date),
        width: 150,
      },
      {
        Header: "Status",
        accessor: "status",
        align: "left",
        Cell: StatusBanner,
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        Cell: ({ cell: { row } }: { cell: { row: any } }) => ActionButton({ cell: { row }, setSelected, setDeleteModal, countData: data.length }),
      },
    ],
    [data]
  );

  return { columns }
}

export function useTableSectionBanner(state: any, data?: any, maxSequences: number = 0) {
  const { hasAccess } = usePermissions()

  const [selectedRows, setSelectedRows] = useState<[]>([]);
  const [listBannerActive, setListBannerActive] = useState<any[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const newListBannerActive = data.map((row: any) => row.is_featured ? row.id : null).filter((row: null) => row !== null);
      setListBannerActive(newListBannerActive);
      setSelectedRows(newListBannerActive);
    }
  }, [data]);

  const handleCheckboxChange = (id: any) => {
    const index = listBannerActive.indexOf(id);
    const updatedList: any = [...listBannerActive];
    if (listBannerActive.length < maxSequences || index !== -1) {
      if (index === -1) {
        updatedList.push(id);
      } else {
        updatedList.splice(index, 1);
      }
      setListBannerActive(updatedList);
      setSelectedRows(updatedList);
    }
  }

  const headers: TableHeaders<SectionBannerModel>[] = useMemo(
    () => [
      {
        value: 'number',
        headerName: 'NO',
        renderCell: (params, index) => (state.page - 1) * (state?.limit || 10) + index + 1,
        width: 50,
      },
      {
        value: 'name',
        headerName: 'SECTION BANNER NAME',
        sort: false,
      },
      {
        value: 'total_content',
        headerName: 'TOTAL CONTENT',
        sort: false,
      },
      {
        value: 'start_date',
        headerName: 'START DATE',
        sort: true,
        renderCell: (params) => formatDateWib(params.start_date),
        width: 140,
      },
      {
        value: 'end_date',
        headerName: 'END DATE',
        sort: true,
        renderCell: (params) => formatDateWib(params.end_date),
        width: 140,
      },
      {
        value: 'action',
        headerName: 'ACTION',
        className: 'dark:bg-inverse-primary-light-2',
        renderCell: (params) => {
          return (
            <div className='flex justify-start gap-2'>
              {hasAccess('section-banner.update') && (
                <input
                  className='disabled:opacity-30'
                  type='checkbox'
                  checked={listBannerActive.includes(params.id)}
                  onChange={() => { handleCheckboxChange(params.id) }}
                  disabled={false}
                />
              )}
            </div>
          )
        },
      },
    ],
    [state.limit, state.page, maxSequences, listBannerActive]
  )

  return { headers, selectedRows, listBannerActive }
} 