import clsx from 'clsx'
import {FC} from 'react'
import VIcon from '../../../VIcon/VIcon'
import {VFormTextProps} from './VFormText.models'

export const VFormText: FC<VFormTextProps> = ({
  className,
  inputClassName,
  error,
  touched,
  type = 'text',
  prependIcon,
  prependInnerIcon,
  appendIcon,
  appendInnerIcon,
  prependClassname,
  prependInnerClassname,
  appendClassname,
  appendInnerClassname,
  iconType = 'base',
  clickPrependIcon,
  clickPrependInnerIcon,
  clickAppendIcon,
  clickAppendInnerIcon,
  prependNode,
  appendNode,
  prefix,
  suffix,
  ...rest
}) => {
  return (
    <div className={className}>
      <div className='relative flex items-center border rounded-lg '>
        {prependNode && <div className={clsx('ml-2')}>{prependNode}</div>}
        {prependIcon && (
          <VIcon
            icon={prependIcon as any}
            type={iconType}
            className={clsx('cursor-pointer mr-2 w-5 h-5', prependClassname)}
            onClick={clickPrependIcon}
          />
        )}
        <div className='relative flex items-center flex-grow'>
          {prependInnerIcon && (
            <VIcon
              icon={prependInnerIcon as any}
              type={iconType}
              className={clsx('cursor-pointer absolute left-4 w-5 h-5', prependInnerClassname)}
              onClick={clickPrependInnerIcon}
            />
          )}
          {prefix && (
            <div
              data-testid='formtext-prefix'
              className='absolute w-5 h-5 font-medium left-4 text-fs-9'
            >
              {prefix}
            </div>
          )}
          <input
            type={type}
            className={clsx(
              'text-fs-8 font-normal border border-solid',
              'placeholder:text-neutral-400 placeholder:font-normal',
              'disabled:bg-neutral-300 disabled:text-neutral-400',
              'focus:bg-transparent focus:outline-none focus:ring',
              {'focus:ring-primary-200 border-neutral-300': !touched && !error},
              {
                'focus:ring-danger-200 focus:border-danger-300 border-danger-300': touched && error,
              },
              {'pl-14': prependInnerIcon ?? prefix},
              {'pr-14': appendInnerIcon ?? suffix},
              `${inputClassName}`
            )}
            {...rest}
          />
          {suffix && (
            <div
              data-testid='formtext-suffix'
              className='absolute w-5 h-5 font-medium right-4 text-fs-9'
            >
              {suffix}
            </div>
          )}
          {appendInnerIcon && (
            <VIcon
              icon={appendInnerIcon as any}
              type={iconType}
              className={clsx('cursor-pointer absolute right-4 w-5 h-5', appendInnerClassname)}
              onClick={clickAppendInnerIcon}
            />
          )}
        </div>
        {appendIcon && (
          <VIcon
            icon={appendIcon as any}
            type={iconType}
            className={clsx('cursor-pointer ml-2 w-5 h-5', appendClassname)}
            onClick={clickAppendIcon}
          />
        )}
        {appendNode && <div className={clsx('ml-2')}>{appendNode}</div>}
      </div>
      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}
