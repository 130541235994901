import clsx from 'clsx'
import { MouseEventHandler, useCallback, useMemo } from 'react'
import { Order } from 'src/app/models/api.types'
import { twMerge } from 'tailwind-merge'
import IconSort from '../IconSort'
import Pagination, { PaginationType } from '../Pagination'
import TableLoading from '../TableLoading'
import TableNotFoundSearch from '../TableNotFoundSearch'
import TableNotFoundSearch2 from '../TableNotFoundSearch2'
import EmptyTable from 'src/app/components/Widgets/EmptyTable'
 
export interface TableData<Q> {
  query?: Q
  page?: number
  limit?: number
  total?: number
}
 
type TableExtention = 'action' | 'number'
 
export interface TableHeaders<T> {
  value: Extract<keyof T, string> | TableExtention | string
  headerName?: string
  width?: number
  renderCell?: (params: T, index: number) => void
  sort?: boolean
  className?: string
  classNameHead?: string
  actionSticky?: boolean
  rowClass?: (params: T) => string
}
 
export interface QueryTableData<T, Q> {
  state: TableData<Q>
  data?: T[]
  setPage?: any
  setLimit?: any
  setQuery?: any
  isError?: boolean
  isFetching?: boolean
}
 
export interface QueryTableProps<T, Q> {
  render: QueryTableData<T, Q>
  headers: TableHeaders<T>[]
  minWidth?: string | number
  notFoundType?: 'TYPE_1' | 'TYPE_2'
  paginationType?: PaginationType
  onResetFilterClick?: MouseEventHandler
  showPagination?: boolean
  isStickyHeader?: boolean
  isFilter?: boolean
  emptyTable?: any
}
 
const QueryTable = <T, Q extends { order?: Order; sort?: string }>({
  render,
  headers,
  minWidth,
  notFoundType = 'TYPE_1',
  onResetFilterClick,
  paginationType = 'TYPE_1',
  showPagination,
  isStickyHeader,
  isFilter,
  emptyTable,
}: QueryTableProps<T, Q>) => {
  const { setQuery, data, state, setPage, setLimit, isError, isFetching } = render
 
  const orderFn = useCallback(
    (sort: string) => {
      setQuery((prev: any) => {
        if (prev.sort === sort && prev.order === Order.DESC)
          return { ...prev, sort: undefined, order: undefined }
        return {
          ...prev,
          sort,
          order: prev.sort === sort && prev.order === Order.ASC ? Order.DESC : Order.ASC,
        }
      })
    },
    [setQuery]
  )
 
  const renderNotFound = useMemo(() => {
    if (notFoundType === 'TYPE_1') return <TableNotFoundSearch />
    if (notFoundType === 'TYPE_2') return <TableNotFoundSearch2 onClick={onResetFilterClick} />
    //eslint-disable-next-line
  }, [notFoundType])
 
  return (
    <>
      {isFetching ? (
        <TableLoading />
      ) : (
        <>
          {!isError && (
            <div className={`relative ${isFilter ? 'mt-4' : ''}`}>
              <div className={`overflow-auto ${isStickyHeader ? 'h-96' : ''}`}>
                <table
                  className={`w-full ${isFilter ? 'mt-6' : ''} mb-2 align-middle border-collapse border-spacing-3 ${isStickyHeader ? 'sticky-header' : ''}`}
                  style={{ minWidth: minWidth ?? 900 }}
                >
                  <thead className='font-medium border-collapse align-center mb-8 pb-8'>
                    <tr className={clsx('uppercase bg-white border-b text-fs-9 text-neutral-70 border-neutral-30 dark:border-neutral-70 dark:text-neutral-30', isStickyHeader ? 'sticky top-0' : '')}>
                      {headers?.map((data) => {
                        return (
                          <th
                            key={data.headerName}
                            className={clsx(
                              'relative font-bold pl-3 py-4 pr-4 text-start bg-white',
                              data.className,
                              data.classNameHead,
                              data.actionSticky && 'sticky right-0 bg-white',
                            )}
                            style={{ width: data.width, maxWidth: data.width }}
                          >
                            {data.actionSticky && <HeaderStickyDivider />}
                            <div
                              className={twMerge("flex items-center", data.actionSticky && "justify-end mr-2")}
                            >
                              {data.headerName}
 
                              {data.sort && (
                                <span className='ml-2'>
                                  <IconSort
                                    name={data.value}
                                    trigger={orderFn}
                                    currentOrder={state?.query?.order}
                                    currentSort={state?.query?.sort}
                                  />
                                </span>
                              )}
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody className='align-middle'>
                    {data?.map((item: any, index) => {
                      return (
                        <tr
                          key={item.value || index}
                          className='border-b text-fs-9 text-neutral-80 dark:text-neutral-20 border-neutral-30 dark:border-neutral-70'
                        >
                          {headers?.map((head: any) => {
                            if (head.renderCell) {
                              return (
                                <td
                                  key={head.value}
                                  className={clsx('pl-3 py-2 pr-4', head.className, head.actionSticky && 'sticky right-0 bg-white', head.width && 'break-words', head.rowClass && head.rowClass(item))}
                                  style={{ width: head.width, maxWidth: head.width }}
                                >
                                  {head.actionSticky && <HeaderStickyDivider />}
                                  <div
                                    className={head.width && 'pr-4'}
                                    style={{ width: head.width, maxWidth: head.width }}
                                  >
                                    {head.renderCell(item, index)}
                                  </div>
                                </td>
                              )
                            }
                            return (
                              <td
                                key={head.value}
                                className={clsx('pl-3 py-4 pr-4 text-start', head.className, head.actionSticky && 'sticky right-0 bg-white', head.width && 'break-words', head.rowClass && head.rowClass(item))}
                                style={{ width: head.width, maxWidth: head.width }}
                              >
                                {head.actionSticky && <HeaderStickyDivider />}
                                <div
                                  className={head.width && 'pr-4'}
                                  style={{ width: head.width, maxWidth: head.width }}
                                >
                                  {item[head.value]}
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                {state.total === 0 && !emptyTable &&
                  <div className='flex flex-col items-center justify-center w-full gap-4 my-8'>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 24.3166V24.3333M16 7.66667V19.3333M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span className='font-semibold text-neutral-50'>No matching records found</span>
                  </div>
                }
                {state.total === 0 && emptyTable &&
                  <EmptyTable
                    title={emptyTable.title ?? 'Data is Empty'}
                    body={emptyTable.body ?? 'Please click “Create data” to create.'}
                  />
                }
              </div>
 
              {(state.total !== 0 && showPagination) && <Pagination
                page={state.page ?? 1}
                limit={Number(state.limit ?? 10)}
                setPage={setPage}
                setPerPage={setLimit}
                maxData={state.total ?? 0}
                type={paginationType}
              />}
            </div>
          )}
        </>
      )}
    </>
  )
}
 
export default QueryTable
 
function HeaderStickyDivider() {
  return <div className="absolute top-0 left-0 h-full border-l border-neutral-30" />
}