import {QueryKey, QueryOptions, useQuery} from '@tanstack/react-query'
import {useCallback, useMemo, useState} from 'react'
import {useHeaderToast} from '../context/ToastContext'
import {getErrorMessage} from '../utils/api-utils'
import {useOnline} from './online-hook'

type UsePaginatorOptions<T, Q> = {
  queryKey: string[]
  queryFn: (params: Q) => any
  enabled?: boolean
  queryOptions?: QueryOptions<T> & QueryKey
}

type DefaultValue<Q> = {
  page: number
  size: number
  total: number
  total_rows: number
  limit: number
  query: Q
}

export function usePaginationQuery<T, Q>(
  p: UsePaginatorOptions<T, Q>,
  defaultValue: DefaultValue<Q> = {
    page: 1,
    size: 10,
    total: 0,
    total_rows: 0,
    limit: 10,
    query: {} as Q,
  }
) {
  const {isOnline, errorOffline} = useOnline()
  const {addPageToasts} = useHeaderToast()

  const [state, setState] = useState(defaultValue)

  const renderParams = useMemo(() => {
    const mapQuery = {...state.query}
    const params = {page: state.page, limit: state.limit, ...mapQuery}
    return params
  }, [state])

  const query = useQuery<T>({
    queryKey: [...p.queryKey, renderParams],
    queryFn: () => p.queryFn(renderParams),
    onError: (e) => {
      if (isOnline) addPageToasts({scheme: 'danger', text: getErrorMessage(e, true)})
      else addPageToasts({scheme: 'danger', text: errorOffline})
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: p.enabled,
    ...p.queryOptions,
  })

  const setPage = useCallback((page: number) => {
    setState((prev) => {
      if (prev.total_rows > 0 && !Math.ceil(prev.total_rows / prev.limit)) return prev
      return {
        ...prev,
        page,
      }
    })
  }, [])

  const setQuery = useCallback((builder: (query: Q) => Q) => {
    setState((prev) => ({
      ...prev,
      page: 1,
      query: builder(prev.query),
    }))
  }, [])

  const setLimit = useCallback((limit: number) => {
    setState((prev) => ({
      ...prev,
      page: 1,
      limit,
    }))
  }, [])

  const setTotal = useCallback((total: number) => {
    setState((prev) => ({
      ...prev,
      total,
    }))
  }, [])

  return {
    state,
    setPage,
    setQuery,
    setLimit,
    setTotal,
    setState,
    ...query,
  }
}
