import { FC, HTMLAttributes } from "react";
import { cn } from "src/app/utils/cn-utils";

interface DragHandleProps extends HTMLAttributes<HTMLDivElement> {
  isDragging?: boolean;
}

export const DragHandle: FC<DragHandleProps> = ({ isDragging, ...props }) => {
  return (
    <div
      {...props}
      className={cn(
        `w-4 mr-2 align-center inline-block`,
        isDragging ? "cursor-grabbing" : "cursor-move"
      )}
    >
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.375 1.67C3.375 1.025 2.815 0.5 2.125 0.5C1.435 0.5 0.875 1.025 0.875 1.67C0.875 2.316 1.435 2.84 2.125 2.84C2.815 2.84 3.375 2.316 3.375 1.67ZM3.375 10.33C3.375 9.684 2.815 9.16 2.125 9.16C1.435 9.16 0.875 9.684 0.875 10.33C0.875 10.975 1.435 11.5 2.125 11.5C2.815 11.5 3.375 10.975 3.375 10.33ZM2.125 4.83C2.815 4.83 3.375 5.355 3.375 6C3.375 6.645 2.815 7.17 2.125 7.17C1.435 7.17 0.875 6.645 0.875 6C0.875 5.355 1.435 4.83 2.125 4.83ZM7.125 1.67C7.125 1.025 6.565 0.5 5.875 0.5C5.185 0.5 4.625 1.025 4.625 1.67C4.625 2.316 5.185 2.84 5.875 2.84C6.565 2.84 7.125 2.316 7.125 1.67ZM5.875 9.16C6.565 9.16 7.125 9.684 7.125 10.33C7.125 10.975 6.565 11.5 5.875 11.5C5.185 11.5 4.625 10.975 4.625 10.33C4.625 9.684 5.185 9.16 5.875 9.16ZM7.125 6C7.125 5.355 6.565 4.83 5.875 4.83C5.185 4.83 4.625 5.355 4.625 6C4.625 6.645 5.185 7.17 5.875 7.17C6.565 7.17 7.125 6.645 7.125 6Z" fill="#94A3B8" />
      </svg>
    </div>
  );
}
