import { FC } from 'react'
import { GChip } from '../Libs'

type Props = {
  status?: boolean
  type?: string
}

const HandleStatusBoolean: FC<Props> = ({ status, type = 'plain' }) => {
  let chipContent;
  let svgContent;

  if (status) {
    chipContent = 'ACTIVE';
    svgContent = (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20ZM14.4141 8.16406L9.41406 13.1641C9.04688 13.5312 8.45312 13.5312 8.08984 13.1641L5.58984 10.6641C5.22266 10.2969 5.22266 9.70312 5.58984 9.33984C5.95703 8.97656 6.55078 8.97266 6.91406 9.33984L8.75 11.1758L13.0859 6.83594C13.4531 6.46875 14.0469 6.46875 14.4102 6.83594C14.7734 7.20312 14.7773 7.79687 14.4102 8.16016L14.4141 8.16406Z" fill="#10B981" />
      </svg>
    );
  } else {
    chipContent = 'INACTIVE';
    svgContent = (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10480_15081)">
          <path d="M10.9999 21.5599C16.8327 21.5599 21.5599 16.8327 21.5599 10.9999C21.5599 5.16719 16.8327 0.439941 10.9999 0.439941C5.16719 0.439941 0.439941 5.16719 0.439941 10.9999C0.439941 16.8327 5.16719 21.5599 10.9999 21.5599Z" fill="#CBD5E1" />
          <path d="M14.7254 8.39261C15.0346 8.08338 15.0346 7.58119 14.7254 7.27196C14.4162 6.96273 13.914 6.96273 13.6047 7.27196L10.9998 9.87939L8.39237 7.27444C8.08314 6.96521 7.58095 6.96521 7.27172 7.27444C6.96249 7.58367 6.96249 8.08585 7.27172 8.39508L9.87914 11L7.27419 13.6075C6.96496 13.9167 6.96496 14.4189 7.27419 14.7281C7.58342 15.0373 8.08561 15.0373 8.39484 14.7281L10.9998 12.1207L13.6072 14.7256C13.9165 15.0349 14.4186 15.0349 14.7279 14.7256C15.0371 14.4164 15.0371 13.9142 14.7279 13.605L12.1204 11L14.7254 8.39261Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_10480_15081">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <>
      {type === 'plain' ? (
        <GChip variant={status ? 'success' : 'neutral'}>{chipContent}</GChip>
      ) : (
        svgContent
      )}
    </>
  );
};

export default HandleStatusBoolean;
