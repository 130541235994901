import { HTMLAttributes } from "react";
import { DropdownProps } from "./Dropdown.types";

export function Dropdown({
  renderButton,
  children,
  onClose,
  isOpen
}: Readonly<DropdownProps>) {
  return (
    <div className="relative">
      {renderButton}
      {isOpen ? <Blanket onClick={onClose} /> : null}
      {isOpen ? <Menu>{children}</Menu> : null}
    </div>
  );
}

function Menu(props: Readonly<HTMLAttributes<HTMLDivElement>>) {
  return <div className="absolute left-0 z-20 w-full p-3 translate-y-2 bg-white border rounded-lg shadow-lg top-full border-neutral-20" {...props} />
}

function Blanket(props: Readonly<HTMLAttributes<HTMLDivElement>>) {
  return <div className="fixed inset-0 z-10" {...props} />;
}
