
export function hasArray(arr: any): arr is any[] {
  return Array.isArray(arr) && arr.length > 0;
}

export function safeArray<T extends any>(arr?: T | T[]): T[] {
  return hasArray(arr) ? arr : [];
}

export function truncateLabel<T>(
  arr?: T[],
  mapArr: (item: T) => string = (_) => _ as string,
  maxLength: number = 3
): string {

  if (!Array.isArray(arr)) {
    return ('-');
  }

  const mappingArr = arr.map((_: T) => mapArr(_));
  if (arr.length < maxLength + 1) {
    return mappingArr.join(', ');
  }
  const label = mappingArr.slice(0, maxLength).join(', ');
  return (label + '...');
};
