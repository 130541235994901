export enum VIconSWMBroken {
  'air' = '/media/icons_v2/swm/broken/air.svg',
  'alarm' = '/media/icons_v2/swm/broken/alarm.svg',
  'arrow-circle-down' = '/media/icons_v2/swm/broken/arrow-circle-down.svg',
  'arrow-circle-left' = '/media/icons_v2/swm/broken/arrow-circle-left.svg',
  'arrow-circle-right' = '/media/icons_v2/swm/broken/arrow-circle-right.svg',
  'arrow-circle-up' = '/media/icons_v2/swm/broken/arrow-circle-up.svg',
  'arrow-down' = '/media/icons_v2/swm/broken/arrow-down.svg',
  'arrow-left' = '/media/icons_v2/swm/broken/arrow-left.svg',
  'arrow-right' = '/media/icons_v2/swm/broken/arrow-right.svg',
  'arrow-small-down' = '/media/icons_v2/swm/broken/arrow-small-down.svg',
  'arrow-small-left' = '/media/icons_v2/swm/broken/arrow-small-left.svg',
  'arrow-small-right' = '/media/icons_v2/swm/broken/arrow-small-right.svg',
  'arrow-small-up' = '/media/icons_v2/swm/broken/arrow-small-up.svg',
  'arrow-up' = '/media/icons_v2/swm/broken/arrow-up.svg',
  'at-email' = '/media/icons_v2/swm/broken/at-email.svg',
  'attachment' = '/media/icons_v2/swm/broken/attachment.svg',
  'basket' = '/media/icons_v2/swm/broken/basket.svg',
  'basketball' = '/media/icons_v2/swm/broken/basketball.svg',
  'bell-disabled' = '/media/icons_v2/swm/broken/bell-disabled.svg',
  'bell' = '/media/icons_v2/swm/broken/bell.svg',
  'block-1' = '/media/icons_v2/swm/broken/block-1.svg',
  'block-2' = '/media/icons_v2/swm/broken/block-2.svg',
  'bolt' = '/media/icons_v2/swm/broken/bolt.svg',
  'bone-broken' = '/media/icons_v2/swm/broken/bone-broken.svg',
  'bone' = '/media/icons_v2/swm/broken/bone.svg',
  'bookmark' = '/media/icons_v2/swm/broken/bookmark.svg',
  'calendar-check' = '/media/icons_v2/swm/broken/calendar-check.svg',
  'calendar-clock' = '/media/icons_v2/swm/broken/calendar-clock.svg',
  'calendar-cross' = '/media/icons_v2/swm/broken/calendar-cross.svg',
  'calendar-edit' = '/media/icons_v2/swm/broken/calendar-edit.svg',
  'calendar-link' = '/media/icons_v2/swm/broken/calendar-link.svg',
  'calendar-lock' = '/media/icons_v2/swm/broken/calendar-lock.svg',
  'calendar-minus' = '/media/icons_v2/swm/broken/calendar-minus.svg',
  'calendar-plus' = '/media/icons_v2/swm/broken/calendar-plus.svg',
  'calendar-user' = '/media/icons_v2/swm/broken/calendar-user.svg',
  'calendar-warning' = '/media/icons_v2/swm/broken/calendar-warning.svg',
  'calendar' = '/media/icons_v2/swm/broken/calendar.svg',
  'cam-disabled' = '/media/icons_v2/swm/broken/cam-disabled.svg',
  'cam' = '/media/icons_v2/swm/broken/cam.svg',
  'camera-disabled' = '/media/icons_v2/swm/broken/camera-disabled.svg',
  'camera' = '/media/icons_v2/swm/broken/camera.svg',
  'capsule' = '/media/icons_v2/swm/broken/capsule.svg',
  'cardiology' = '/media/icons_v2/swm/broken/cardiology.svg',
  'cart-1' = '/media/icons_v2/swm/broken/cart-1.svg',
  'cart-2' = '/media/icons_v2/swm/broken/cart-2.svg',
  'cart-3' = '/media/icons_v2/swm/broken/cart-3.svg',
  'cart-4' = '/media/icons_v2/swm/broken/cart-4.svg',
  'cast' = '/media/icons_v2/swm/broken/cast.svg',
  'chart-vertical' = '/media/icons_v2/swm/broken/chart-vertical.svg',
  'chart' = '/media/icons_v2/swm/broken/chart.svg',
  'check-circle' = '/media/icons_v2/swm/broken/check-circle.svg',
  'check-small' = '/media/icons_v2/swm/broken/check-small.svg',
  'check' = '/media/icons_v2/swm/broken/check.svg',
  'chevron-circle-down' = '/media/icons_v2/swm/broken/chevron-circle-down.svg',
  'chevron-circle-left' = '/media/icons_v2/swm/broken/chevron-circle-left.svg',
  'chevron-circle-right' = '/media/icons_v2/swm/broken/chevron-circle-right.svg',
  'chevron-circle-up' = '/media/icons_v2/swm/broken/chevron-circle-up.svg',
  'chevron-down' = '/media/icons_v2/swm/broken/chevron-down.svg',
  'chevron-left' = '/media/icons_v2/swm/broken/chevron-left.svg',
  'chevron-right' = '/media/icons_v2/swm/broken/chevron-right.svg',
  'chevron-small-down' = '/media/icons_v2/swm/broken/chevron-small-down.svg',
  'chevron-small-left' = '/media/icons_v2/swm/broken/chevron-small-left.svg',
  'chevron-small-right' = '/media/icons_v2/swm/broken/chevron-small-right.svg',
  'chevron-small-up' = '/media/icons_v2/swm/broken/chevron-small-up.svg',
  'chevron-up' = '/media/icons_v2/swm/broken/chevron-up.svg',
  'circle' = '/media/icons_v2/swm/broken/circle.svg',
  'clocl' = '/media/icons_v2/swm/broken/clocl.svg',
  'cloud' = '/media/icons_v2/swm/broken/cloud.svg',
  'coin' = '/media/icons_v2/swm/broken/coin.svg',
  'command' = '/media/icons_v2/swm/broken/command.svg',
  'copy' = '/media/icons_v2/swm/broken/copy.svg',
  'credit-card' = '/media/icons_v2/swm/broken/credit-card.svg',
  'cross-circle' = '/media/icons_v2/swm/broken/cross-circle.svg',
  'cross-small' = '/media/icons_v2/swm/broken/cross-small.svg',
  'cross' = '/media/icons_v2/swm/broken/cross.svg',
  'crown-1' = '/media/icons_v2/swm/broken/crown-1.svg',
  'crown-2' = '/media/icons_v2/swm/broken/crown-2.svg',
  'cut' = '/media/icons_v2/swm/broken/cut.svg',
  'delete' = '/media/icons_v2/swm/broken/delete.svg',
  'dislike' = '/media/icons_v2/swm/broken/dislike.svg',
  'dna' = '/media/icons_v2/swm/broken/dna.svg',
  'document-check' = '/media/icons_v2/swm/broken/document-check.svg',
  'document-clean' = '/media/icons_v2/swm/broken/document-clean.svg',
  'document-cross' = '/media/icons_v2/swm/broken/document-cross.svg',
  'document-filled' = '/media/icons_v2/swm/broken/document-filled.svg',
  'document-minus' = '/media/icons_v2/swm/broken/document-minus.svg',
  'document-plus' = '/media/icons_v2/swm/broken/document-plus.svg',
  'download' = '/media/icons_v2/swm/broken/download.svg',
  'edit-1' = '/media/icons_v2/swm/broken/edit-1.svg',
  'edit-2' = '/media/icons_v2/swm/broken/edit-2.svg',
  'edit-3' = '/media/icons_v2/swm/broken/edit-3.svg',
  'edit-4' = '/media/icons_v2/swm/broken/edit-4.svg',
  'emote-normal' = '/media/icons_v2/swm/broken/emote-normal.svg',
  'emote-sad' = '/media/icons_v2/swm/broken/emote-sad.svg',
  'emote-smile' = '/media/icons_v2/swm/broken/emote-smile.svg',
  'explore' = '/media/icons_v2/swm/broken/explore.svg',
  'eye-closed' = '/media/icons_v2/swm/broken/eye-closed.svg',
  'eye-open' = '/media/icons_v2/swm/broken/eye-open.svg',
  'faceid' = '/media/icons_v2/swm/broken/faceid.svg',
  'female' = '/media/icons_v2/swm/broken/female.svg',
  'filters-1' = '/media/icons_v2/swm/broken/filters-1.svg',
  'filters-2' = '/media/icons_v2/swm/broken/filters-2.svg',
  'filters-3' = '/media/icons_v2/swm/broken/filters-3.svg',
  'flag-1' = '/media/icons_v2/swm/broken/flag-1.svg',
  'flag-2' = '/media/icons_v2/swm/broken/flag-2.svg',
  'flag-3' = '/media/icons_v2/swm/broken/flag-3.svg',
  'forward' = '/media/icons_v2/swm/broken/forward.svg',
  'fullscreen' = '/media/icons_v2/swm/broken/fullscreen.svg',
  'gift-1' = '/media/icons_v2/swm/broken/gift-1.svg',
  'globe-1' = '/media/icons_v2/swm/broken/globe-1.svg',
  'grid-2-horizontal' = '/media/icons_v2/swm/broken/grid-2-horizontal.svg',
  'grid-2-vertical' = '/media/icons_v2/swm/broken/grid-2-vertical.svg',
  'grid-4' = '/media/icons_v2/swm/broken/grid-4.svg',
  'headphones' = '/media/icons_v2/swm/broken/headphones.svg',
  'heart' = '/media/icons_v2/swm/broken/heart.svg',
  'home-1' = '/media/icons_v2/swm/broken/home-1.svg',
  'home-2' = '/media/icons_v2/swm/broken/home-2.svg',
  'home-hospital' = '/media/icons_v2/swm/broken/home-hospital.svg',
  'horizontal' = '/media/icons_v2/swm/broken/horizontal.svg',
  'hourglass' = '/media/icons_v2/swm/broken/hourglass.svg',
  'ice' = '/media/icons_v2/swm/broken/ice.svg',
  'image-1' = '/media/icons_v2/swm/broken/image-1.svg',
  'inbox' = '/media/icons_v2/swm/broken/inbox.svg',
  'info-circle' = '/media/icons_v2/swm/broken/info-circle.svg',
  'info-small' = '/media/icons_v2/swm/broken/info-small.svg',
  'info' = '/media/icons_v2/swm/broken/info.svg',
  'key' = '/media/icons_v2/swm/broken/key.svg',
  'laptop' = '/media/icons_v2/swm/broken/laptop.svg',
  'like' = '/media/icons_v2/swm/broken/like.svg',
  'link' = '/media/icons_v2/swm/broken/link.svg',
  'list-center' = '/media/icons_v2/swm/broken/list-center.svg',
  'list-left' = '/media/icons_v2/swm/broken/list-left.svg',
  'list-pointers' = '/media/icons_v2/swm/broken/list-pointers.svg',
  'list-right' = '/media/icons_v2/swm/broken/list-right.svg',
  'location-1' = '/media/icons_v2/swm/broken/location-1.svg',
  'location-2' = '/media/icons_v2/swm/broken/location-2.svg',
  'location-med-1' = '/media/icons_v2/swm/broken/location-med-1.svg',
  'location-med-2' = '/media/icons_v2/swm/broken/location-med-2.svg',
  'lock-off' = '/media/icons_v2/swm/broken/lock-off.svg',
  'lock-on' = '/media/icons_v2/swm/broken/lock-on.svg',
  'login' = '/media/icons_v2/swm/broken/login.svg',
  'logout' = '/media/icons_v2/swm/broken/logout.svg',
  'mail' = '/media/icons_v2/swm/broken/mail.svg',
  'male' = '/media/icons_v2/swm/broken/male.svg',
  'map' = '/media/icons_v2/swm/broken/map.svg',
  'medkit' = '/media/icons_v2/swm/broken/medkit.svg',
  'meds' = '/media/icons_v2/swm/broken/meds.svg',
  'menu-hamburger' = '/media/icons_v2/swm/broken/menu-hamburger.svg',
  'menu-horizontal' = '/media/icons_v2/swm/broken/menu-horizontal.svg',
  'menu-vertical' = '/media/icons_v2/swm/broken/menu-vertical.svg',
  'messages-circle-dots' = '/media/icons_v2/swm/broken/messages-circle-dots.svg',
  'messages-circle-lines' = '/media/icons_v2/swm/broken/messages-circle-lines.svg',
  'messages-circle' = '/media/icons_v2/swm/broken/messages-circle.svg',
  'messages-square-dots' = '/media/icons_v2/swm/broken/messages-square-dots.svg',
  'messages-square-lines' = '/media/icons_v2/swm/broken/messages-square-lines.svg',
  'messages-square' = '/media/icons_v2/swm/broken/messages-square.svg',
  'microphone-disabled' = '/media/icons_v2/swm/broken/microphone-disabled.svg',
  'microphone' = '/media/icons_v2/swm/broken/microphone.svg',
  'minus-circle' = '/media/icons_v2/swm/broken/minus-circle.svg',
  'minus-small' = '/media/icons_v2/swm/broken/minus-small.svg',
  'minus' = '/media/icons_v2/swm/broken/minus.svg',
  'money' = '/media/icons_v2/swm/broken/money.svg',
  'moon' = '/media/icons_v2/swm/broken/moon.svg',
  'music' = '/media/icons_v2/swm/broken/music.svg',
  'navigation' = '/media/icons_v2/swm/broken/navigation.svg',
  'newscreen' = '/media/icons_v2/swm/broken/newscreen.svg',
  'next' = '/media/icons_v2/swm/broken/next.svg',
  'offer' = '/media/icons_v2/swm/broken/offer.svg',
  'package' = '/media/icons_v2/swm/broken/package.svg',
  'pause' = '/media/icons_v2/swm/broken/pause.svg',
  'phone-call' = '/media/icons_v2/swm/broken/phone-call.svg',
  'phone-cross' = '/media/icons_v2/swm/broken/phone-cross.svg',
  'phone-down' = '/media/icons_v2/swm/broken/phone-down.svg',
  'pin-1' = '/media/icons_v2/swm/broken/pin-1.svg',
  'pin-2' = '/media/icons_v2/swm/broken/pin-2.svg',
  'pinpaper-check' = '/media/icons_v2/swm/broken/pinpaper-check.svg',
  'pinpaper-cross' = '/media/icons_v2/swm/broken/pinpaper-cross.svg',
  'pinpaper-filled' = '/media/icons_v2/swm/broken/pinpaper-filled.svg',
  'pinpaper-minus' = '/media/icons_v2/swm/broken/pinpaper-minus.svg',
  'pinpaper-plus' = '/media/icons_v2/swm/broken/pinpaper-plus.svg',
  'play' = '/media/icons_v2/swm/broken/play.svg',
  'plus-circle' = '/media/icons_v2/swm/broken/plus-circle.svg',
  'plus-small' = '/media/icons_v2/swm/broken/plus-small.svg',
  'plus' = '/media/icons_v2/swm/broken/plus.svg',
  'power' = '/media/icons_v2/swm/broken/power.svg',
  'previous' = '/media/icons_v2/swm/broken/previous.svg',
  'print' = '/media/icons_v2/swm/broken/print.svg',
  'question-circle' = '/media/icons_v2/swm/broken/question-circle.svg',
  'question-small' = '/media/icons_v2/swm/broken/question-small.svg',
  'question' = '/media/icons_v2/swm/broken/question.svg',
  'quote' = '/media/icons_v2/swm/broken/quote.svg',
  'redo-circle' = '/media/icons_v2/swm/broken/redo-circle.svg',
  'redo-small' = '/media/icons_v2/swm/broken/redo-small.svg',
  'redo' = '/media/icons_v2/swm/broken/redo.svg',
  'refresh-circle' = '/media/icons_v2/swm/broken/refresh-circle.svg',
  'refresh-small' = '/media/icons_v2/swm/broken/refresh-small.svg',
  'refresh' = '/media/icons_v2/swm/broken/refresh.svg',
  'resize-circle-horizontal' = '/media/icons_v2/swm/broken/resize-circle-horizontal.svg',
  'resize-circle-vertical' = '/media/icons_v2/swm/broken/resize-circle-vertical.svg',
  'resize-small-horizontal' = '/media/icons_v2/swm/broken/resize-small-horizontal.svg',
  'resize-small-vertical' = '/media/icons_v2/swm/broken/resize-small-vertical.svg',
  'rewind' = '/media/icons_v2/swm/broken/rewind.svg',
  'rotate-circle-left' = '/media/icons_v2/swm/broken/rotate-circle-left.svg',
  'rotate-circle-right' = '/media/icons_v2/swm/broken/rotate-circle-right.svg',
  'rotate-small-left' = '/media/icons_v2/swm/broken/rotate-small-left.svg',
  'rotate-small-right' = '/media/icons_v2/swm/broken/rotate-small-right.svg',
  'save' = '/media/icons_v2/swm/broken/save.svg',
  'screen-disabled' = '/media/icons_v2/swm/broken/screen-disabled.svg',
  'screen-share' = '/media/icons_v2/swm/broken/screen-share.svg',
  'screen' = '/media/icons_v2/swm/broken/screen.svg',
  'search' = '/media/icons_v2/swm/broken/search.svg',
  'send-1' = '/media/icons_v2/swm/broken/send-1.svg',
  'send-2' = '/media/icons_v2/swm/broken/send-2.svg',
  'settings' = '/media/icons_v2/swm/broken/settings.svg',
  'share-1' = '/media/icons_v2/swm/broken/share-1.svg',
  'share-2' = '/media/icons_v2/swm/broken/share-2.svg',
  'shield-check' = '/media/icons_v2/swm/broken/shield-check.svg',
  'shield-cross' = '/media/icons_v2/swm/broken/shield-cross.svg',
  'shield-empty' = '/media/icons_v2/swm/broken/shield-empty.svg',
  'shirt' = '/media/icons_v2/swm/broken/shirt.svg',
  'smartphone' = '/media/icons_v2/swm/broken/smartphone.svg',
  'sound-0' = '/media/icons_v2/swm/broken/sound-0.svg',
  'sound-1' = '/media/icons_v2/swm/broken/sound-1.svg',
  'sound-2' = '/media/icons_v2/swm/broken/sound-2.svg',
  'speaker-0' = '/media/icons_v2/swm/broken/speaker-0.svg',
  'speaker-1' = '/media/icons_v2/swm/broken/speaker-1.svg',
  'speaker-2' = '/media/icons_v2/swm/broken/speaker-2.svg',
  'speaker-cross' = '/media/icons_v2/swm/broken/speaker-cross.svg',
  'speaker-disabled' = '/media/icons_v2/swm/broken/speaker-disabled.svg',
  'star-1' = '/media/icons_v2/swm/broken/star-1.svg',
  'star-2' = '/media/icons_v2/swm/broken/star-2.svg',
  'stop' = '/media/icons_v2/swm/broken/stop.svg',
  'stopwatch' = '/media/icons_v2/swm/broken/stopwatch.svg',
  'suitcase' = '/media/icons_v2/swm/broken/suitcase.svg',
  'sun' = '/media/icons_v2/swm/broken/sun.svg',
  'syringe' = '/media/icons_v2/swm/broken/syringe.svg',
  'tag' = '/media/icons_v2/swm/broken/tag.svg',
  'test-tube' = '/media/icons_v2/swm/broken/test-tube.svg',
  'tooth' = '/media/icons_v2/swm/broken/tooth.svg',
  'trash-1' = '/media/icons_v2/swm/broken/trash-1.svg',
  'trash-2' = '/media/icons_v2/swm/broken/trash-2.svg',
  'trending-down' = '/media/icons_v2/swm/broken/trending-down.svg',
  'trending-up' = '/media/icons_v2/swm/broken/trending-up.svg',
  'trophy' = '/media/icons_v2/swm/broken/trophy.svg',
  'umbrella-1' = '/media/icons_v2/swm/broken/umbrella-1.svg',
  'umbrella-2' = '/media/icons_v2/swm/broken/umbrella-2.svg',
  'undo-circle' = '/media/icons_v2/swm/broken/undo-circle.svg',
  'undo-small' = '/media/icons_v2/swm/broken/undo-small.svg',
  'undo' = '/media/icons_v2/swm/broken/undo.svg',
  'upload' = '/media/icons_v2/swm/broken/upload.svg',
  'user-1' = '/media/icons_v2/swm/broken/user-1.svg',
  'user-2' = '/media/icons_v2/swm/broken/user-2.svg',
  'user-check' = '/media/icons_v2/swm/broken/user-check.svg',
  'user-cross' = '/media/icons_v2/swm/broken/user-cross.svg',
  'user-info' = '/media/icons_v2/swm/broken/user-info.svg',
  'user-minus' = '/media/icons_v2/swm/broken/user-minus.svg',
  'user-plus' = '/media/icons_v2/swm/broken/user-plus.svg',
  'user-question' = '/media/icons_v2/swm/broken/user-question.svg',
  'user-warning' = '/media/icons_v2/swm/broken/user-warning.svg',
  'users-more' = '/media/icons_v2/swm/broken/users-more.svg',
  'users' = '/media/icons_v2/swm/broken/users.svg',
  'vertical' = '/media/icons_v2/swm/broken/vertical.svg',
  'virus' = '/media/icons_v2/swm/broken/virus.svg',
  'wallet' = '/media/icons_v2/swm/broken/wallet.svg',
  'wand' = '/media/icons_v2/swm/broken/wand.svg',
  'warning-circle' = '/media/icons_v2/swm/broken/warning-circle.svg',
  'warning-small' = '/media/icons_v2/swm/broken/warning-small.svg',
  'warning' = '/media/icons_v2/swm/broken/warning.svg',
  'waterdrop' = '/media/icons_v2/swm/broken/waterdrop.svg',
  'windows' = '/media/icons_v2/swm/broken/windows.svg',
  'zoom-in' = '/media/icons_v2/swm/broken/zoom-in.svg',
  'zoom-out' = '/media/icons_v2/swm/broken/zoom-out.svg',
}

export enum VIconSWMCurved {
  'air' = '/media/icons_v2/swm/curved/air.svg',
  'alarm' = '/media/icons_v2/swm/curved/alarm.svg',
  'arrow-circle-down' = '/media/icons_v2/swm/curved/arrow-circle-down.svg',
  'arrow-circle-left' = '/media/icons_v2/swm/curved/arrow-circle-left.svg',
  'arrow-circle-right' = '/media/icons_v2/swm/curved/arrow-circle-right.svg',
  'arrow-circle-up' = '/media/icons_v2/swm/curved/arrow-circle-up.svg',
  'arrow-down' = '/media/icons_v2/swm/curved/arrow-down.svg',
  'arrow-left' = '/media/icons_v2/swm/curved/arrow-left.svg',
  'arrow-right' = '/media/icons_v2/swm/curved/arrow-right.svg',
  'arrow-small-down' = '/media/icons_v2/swm/curved/arrow-small-down.svg',
  'arrow-small-left' = '/media/icons_v2/swm/curved/arrow-small-left.svg',
  'arrow-small-right' = '/media/icons_v2/swm/curved/arrow-small-right.svg',
  'arrow-small-up' = '/media/icons_v2/swm/curved/arrow-small-up.svg',
  'arrow-up' = '/media/icons_v2/swm/curved/arrow-up.svg',
  'at-email' = '/media/icons_v2/swm/curved/at-email.svg',
  'attachment' = '/media/icons_v2/swm/curved/attachment.svg',
  'basket' = '/media/icons_v2/swm/curved/basket.svg',
  'basketball' = '/media/icons_v2/swm/curved/basketball.svg',
  'bell-disabled' = '/media/icons_v2/swm/curved/bell-disabled.svg',
  'bell' = '/media/icons_v2/swm/curved/bell.svg',
  'block-1' = '/media/icons_v2/swm/curved/block-1.svg',
  'block-2' = '/media/icons_v2/swm/curved/block-2.svg',
  'bolt' = '/media/icons_v2/swm/curved/bolt.svg',
  'bone-curved' = '/media/icons_v2/swm/curved/bone-curved.svg',
  'bone' = '/media/icons_v2/swm/curved/bone.svg',
  'bookmark' = '/media/icons_v2/swm/curved/bookmark.svg',
  'calendar-check' = '/media/icons_v2/swm/curved/calendar-check.svg',
  'calendar-clock' = '/media/icons_v2/swm/curved/calendar-clock.svg',
  'calendar-cross' = '/media/icons_v2/swm/curved/calendar-cross.svg',
  'calendar-edit' = '/media/icons_v2/swm/curved/calendar-edit.svg',
  'calendar-link' = '/media/icons_v2/swm/curved/calendar-link.svg',
  'calendar-lock' = '/media/icons_v2/swm/curved/calendar-lock.svg',
  'calendar-minus' = '/media/icons_v2/swm/curved/calendar-minus.svg',
  'calendar-plus' = '/media/icons_v2/swm/curved/calendar-plus.svg',
  'calendar-user' = '/media/icons_v2/swm/curved/calendar-user.svg',
  'calendar-warning' = '/media/icons_v2/swm/curved/calendar-warning.svg',
  'calendar' = '/media/icons_v2/swm/curved/calendar.svg',
  'cam-disabled' = '/media/icons_v2/swm/curved/cam-disabled.svg',
  'cam' = '/media/icons_v2/swm/curved/cam.svg',
  'camera-disabled' = '/media/icons_v2/swm/curved/camera-disabled.svg',
  'camera' = '/media/icons_v2/swm/curved/camera.svg',
  'capsule' = '/media/icons_v2/swm/curved/capsule.svg',
  'cardiology' = '/media/icons_v2/swm/curved/cardiology.svg',
  'cart-1' = '/media/icons_v2/swm/curved/cart-1.svg',
  'cart-2' = '/media/icons_v2/swm/curved/cart-2.svg',
  'cart-3' = '/media/icons_v2/swm/curved/cart-3.svg',
  'cart-4' = '/media/icons_v2/swm/curved/cart-4.svg',
  'cast' = '/media/icons_v2/swm/curved/cast.svg',
  'chart-vertical' = '/media/icons_v2/swm/curved/chart-vertical.svg',
  'chart' = '/media/icons_v2/swm/curved/chart.svg',
  'check-circle' = '/media/icons_v2/swm/curved/check-circle.svg',
  'check-small' = '/media/icons_v2/swm/curved/check-small.svg',
  'check' = '/media/icons_v2/swm/curved/check.svg',
  'chevron-circle-down' = '/media/icons_v2/swm/curved/chevron-circle-down.svg',
  'chevron-circle-left' = '/media/icons_v2/swm/curved/chevron-circle-left.svg',
  'chevron-circle-right' = '/media/icons_v2/swm/curved/chevron-circle-right.svg',
  'chevron-circle-up' = '/media/icons_v2/swm/curved/chevron-circle-up.svg',
  'chevron-down' = '/media/icons_v2/swm/curved/chevron-down.svg',
  'chevron-left' = '/media/icons_v2/swm/curved/chevron-left.svg',
  'chevron-right' = '/media/icons_v2/swm/curved/chevron-right.svg',
  'chevron-small-down' = '/media/icons_v2/swm/curved/chevron-small-down.svg',
  'chevron-small-left' = '/media/icons_v2/swm/curved/chevron-small-left.svg',
  'chevron-small-right' = '/media/icons_v2/swm/curved/chevron-small-right.svg',
  'chevron-small-up' = '/media/icons_v2/swm/curved/chevron-small-up.svg',
  'chevron-up' = '/media/icons_v2/swm/curved/chevron-up.svg',
  'circle' = '/media/icons_v2/swm/curved/circle.svg',
  'clocl' = '/media/icons_v2/swm/curved/clocl.svg',
  'cloud' = '/media/icons_v2/swm/curved/cloud.svg',
  'coin' = '/media/icons_v2/swm/curved/coin.svg',
  'command' = '/media/icons_v2/swm/curved/command.svg',
  'copy' = '/media/icons_v2/swm/curved/copy.svg',
  'credit-card' = '/media/icons_v2/swm/curved/credit-card.svg',
  'cross-circle' = '/media/icons_v2/swm/curved/cross-circle.svg',
  'cross-small' = '/media/icons_v2/swm/curved/cross-small.svg',
  'cross' = '/media/icons_v2/swm/curved/cross.svg',
  'crown-1' = '/media/icons_v2/swm/curved/crown-1.svg',
  'crown-2' = '/media/icons_v2/swm/curved/crown-2.svg',
  'cut' = '/media/icons_v2/swm/curved/cut.svg',
  'delete' = '/media/icons_v2/swm/curved/delete.svg',
  'dislike' = '/media/icons_v2/swm/curved/dislike.svg',
  'dna' = '/media/icons_v2/swm/curved/dna.svg',
  'document-check' = '/media/icons_v2/swm/curved/document-check.svg',
  'document-clean' = '/media/icons_v2/swm/curved/document-clean.svg',
  'document-cross' = '/media/icons_v2/swm/curved/document-cross.svg',
  'document-filled' = '/media/icons_v2/swm/curved/document-filled.svg',
  'document-minus' = '/media/icons_v2/swm/curved/document-minus.svg',
  'document-plus' = '/media/icons_v2/swm/curved/document-plus.svg',
  'download' = '/media/icons_v2/swm/curved/download.svg',
  'edit-1' = '/media/icons_v2/swm/curved/edit-1.svg',
  'edit-2' = '/media/icons_v2/swm/curved/edit-2.svg',
  'edit-3' = '/media/icons_v2/swm/curved/edit-3.svg',
  'edit-4' = '/media/icons_v2/swm/curved/edit-4.svg',
  'emote-normal' = '/media/icons_v2/swm/curved/emote-normal.svg',
  'emote-sad' = '/media/icons_v2/swm/curved/emote-sad.svg',
  'emote-smile' = '/media/icons_v2/swm/curved/emote-smile.svg',
  'explore' = '/media/icons_v2/swm/curved/explore.svg',
  'eye-closed' = '/media/icons_v2/swm/curved/eye-closed.svg',
  'eye-open' = '/media/icons_v2/swm/curved/eye-open.svg',
  'faceid' = '/media/icons_v2/swm/curved/faceid.svg',
  'female' = '/media/icons_v2/swm/curved/female.svg',
  'filters-1' = '/media/icons_v2/swm/curved/filters-1.svg',
  'filters-2' = '/media/icons_v2/swm/curved/filters-2.svg',
  'filters-3' = '/media/icons_v2/swm/curved/filters-3.svg',
  'flag-1' = '/media/icons_v2/swm/curved/flag-1.svg',
  'flag-2' = '/media/icons_v2/swm/curved/flag-2.svg',
  'flag-3' = '/media/icons_v2/swm/curved/flag-3.svg',
  'forward' = '/media/icons_v2/swm/curved/forward.svg',
  'fullscreen' = '/media/icons_v2/swm/curved/fullscreen.svg',
  'gift-1' = '/media/icons_v2/swm/curved/gift-1.svg',
  'globe-1' = '/media/icons_v2/swm/curved/globe-1.svg',
  'grid-2-horizontal' = '/media/icons_v2/swm/curved/grid-2-horizontal.svg',
  'grid-2-vertical' = '/media/icons_v2/swm/curved/grid-2-vertical.svg',
  'grid-4' = '/media/icons_v2/swm/curved/grid-4.svg',
  'headphones' = '/media/icons_v2/swm/curved/headphones.svg',
  'heart' = '/media/icons_v2/swm/curved/heart.svg',
  'home-1' = '/media/icons_v2/swm/curved/home-1.svg',
  'home-2' = '/media/icons_v2/swm/curved/home-2.svg',
  'home-hospital' = '/media/icons_v2/swm/curved/home-hospital.svg',
  'horizontal' = '/media/icons_v2/swm/curved/horizontal.svg',
  'hourglass' = '/media/icons_v2/swm/curved/hourglass.svg',
  'ice' = '/media/icons_v2/swm/curved/ice.svg',
  'image-1' = '/media/icons_v2/swm/curved/image-1.svg',
  'inbox' = '/media/icons_v2/swm/curved/inbox.svg',
  'info-circle' = '/media/icons_v2/swm/curved/info-circle.svg',
  'info-small' = '/media/icons_v2/swm/curved/info-small.svg',
  'info' = '/media/icons_v2/swm/curved/info.svg',
  'key' = '/media/icons_v2/swm/curved/key.svg',
  'laptop' = '/media/icons_v2/swm/curved/laptop.svg',
  'like' = '/media/icons_v2/swm/curved/like.svg',
  'link' = '/media/icons_v2/swm/curved/link.svg',
  'list-center' = '/media/icons_v2/swm/curved/list-center.svg',
  'list-left' = '/media/icons_v2/swm/curved/list-left.svg',
  'list-pointers' = '/media/icons_v2/swm/curved/list-pointers.svg',
  'list-right' = '/media/icons_v2/swm/curved/list-right.svg',
  'location-1' = '/media/icons_v2/swm/curved/location-1.svg',
  'location-2' = '/media/icons_v2/swm/curved/location-2.svg',
  'location-med-1' = '/media/icons_v2/swm/curved/location-med-1.svg',
  'location-med-2' = '/media/icons_v2/swm/curved/location-med-2.svg',
  'lock-off' = '/media/icons_v2/swm/curved/lock-off.svg',
  'lock-on' = '/media/icons_v2/swm/curved/lock-on.svg',
  'login' = '/media/icons_v2/swm/curved/login.svg',
  'logout' = '/media/icons_v2/swm/curved/logout.svg',
  'mail' = '/media/icons_v2/swm/curved/mail.svg',
  'male' = '/media/icons_v2/swm/curved/male.svg',
  'map' = '/media/icons_v2/swm/curved/map.svg',
  'medkit' = '/media/icons_v2/swm/curved/medkit.svg',
  'meds' = '/media/icons_v2/swm/curved/meds.svg',
  'menu-hamburger' = '/media/icons_v2/swm/curved/menu-hamburger.svg',
  'menu-horizontal' = '/media/icons_v2/swm/curved/menu-horizontal.svg',
  'menu-vertical' = '/media/icons_v2/swm/curved/menu-vertical.svg',
  'messages-circle-dots' = '/media/icons_v2/swm/curved/messages-circle-dots.svg',
  'messages-circle-lines' = '/media/icons_v2/swm/curved/messages-circle-lines.svg',
  'messages-circle' = '/media/icons_v2/swm/curved/messages-circle.svg',
  'messages-square-dots' = '/media/icons_v2/swm/curved/messages-square-dots.svg',
  'messages-square-lines' = '/media/icons_v2/swm/curved/messages-square-lines.svg',
  'messages-square' = '/media/icons_v2/swm/curved/messages-square.svg',
  'microphone-disabled' = '/media/icons_v2/swm/curved/microphone-disabled.svg',
  'microphone' = '/media/icons_v2/swm/curved/microphone.svg',
  'minus-circle' = '/media/icons_v2/swm/curved/minus-circle.svg',
  'minus-small' = '/media/icons_v2/swm/curved/minus-small.svg',
  'minus' = '/media/icons_v2/swm/curved/minus.svg',
  'money' = '/media/icons_v2/swm/curved/money.svg',
  'moon' = '/media/icons_v2/swm/curved/moon.svg',
  'music' = '/media/icons_v2/swm/curved/music.svg',
  'navigation' = '/media/icons_v2/swm/curved/navigation.svg',
  'newscreen' = '/media/icons_v2/swm/curved/newscreen.svg',
  'next' = '/media/icons_v2/swm/curved/next.svg',
  'offer' = '/media/icons_v2/swm/curved/offer.svg',
  'package' = '/media/icons_v2/swm/curved/package.svg',
  'pause' = '/media/icons_v2/swm/curved/pause.svg',
  'phone-call' = '/media/icons_v2/swm/curved/phone-call.svg',
  'phone-cross' = '/media/icons_v2/swm/curved/phone-cross.svg',
  'phone-down' = '/media/icons_v2/swm/curved/phone-down.svg',
  'pin-1' = '/media/icons_v2/swm/curved/pin-1.svg',
  'pin-2' = '/media/icons_v2/swm/curved/pin-2.svg',
  'pinpaper-check' = '/media/icons_v2/swm/curved/pinpaper-check.svg',
  'pinpaper-cross' = '/media/icons_v2/swm/curved/pinpaper-cross.svg',
  'pinpaper-filled' = '/media/icons_v2/swm/curved/pinpaper-filled.svg',
  'pinpaper-minus' = '/media/icons_v2/swm/curved/pinpaper-minus.svg',
  'pinpaper-plus' = '/media/icons_v2/swm/curved/pinpaper-plus.svg',
  'play' = '/media/icons_v2/swm/curved/play.svg',
  'plus-circle' = '/media/icons_v2/swm/curved/plus-circle.svg',
  'plus-small' = '/media/icons_v2/swm/curved/plus-small.svg',
  'plus' = '/media/icons_v2/swm/curved/plus.svg',
  'power' = '/media/icons_v2/swm/curved/power.svg',
  'previous' = '/media/icons_v2/swm/curved/previous.svg',
  'print' = '/media/icons_v2/swm/curved/print.svg',
  'question-circle' = '/media/icons_v2/swm/curved/question-circle.svg',
  'question-small' = '/media/icons_v2/swm/curved/question-small.svg',
  'question' = '/media/icons_v2/swm/curved/question.svg',
  'quote' = '/media/icons_v2/swm/curved/quote.svg',
  'redo-circle' = '/media/icons_v2/swm/curved/redo-circle.svg',
  'redo-small' = '/media/icons_v2/swm/curved/redo-small.svg',
  'redo' = '/media/icons_v2/swm/curved/redo.svg',
  'refresh-circle' = '/media/icons_v2/swm/curved/refresh-circle.svg',
  'refresh-small' = '/media/icons_v2/swm/curved/refresh-small.svg',
  'refresh' = '/media/icons_v2/swm/curved/refresh.svg',
  'resize-circle-horizontal' = '/media/icons_v2/swm/curved/resize-circle-horizontal.svg',
  'resize-circle-vertical' = '/media/icons_v2/swm/curved/resize-circle-vertical.svg',
  'resize-small-horizontal' = '/media/icons_v2/swm/curved/resize-small-horizontal.svg',
  'resize-small-vertical' = '/media/icons_v2/swm/curved/resize-small-vertical.svg',
  'rewind' = '/media/icons_v2/swm/curved/rewind.svg',
  'rotate-circle-left' = '/media/icons_v2/swm/curved/rotate-circle-left.svg',
  'rotate-circle-right' = '/media/icons_v2/swm/curved/rotate-circle-right.svg',
  'rotate-small-left' = '/media/icons_v2/swm/curved/rotate-small-left.svg',
  'rotate-small-right' = '/media/icons_v2/swm/curved/rotate-small-right.svg',
  'save' = '/media/icons_v2/swm/curved/save.svg',
  'screen-disabled' = '/media/icons_v2/swm/curved/screen-disabled.svg',
  'screen-share' = '/media/icons_v2/swm/curved/screen-share.svg',
  'screen' = '/media/icons_v2/swm/curved/screen.svg',
  'search' = '/media/icons_v2/swm/curved/search.svg',
  'send-1' = '/media/icons_v2/swm/curved/send-1.svg',
  'send-2' = '/media/icons_v2/swm/curved/send-2.svg',
  'settings' = '/media/icons_v2/swm/curved/settings.svg',
  'share-1' = '/media/icons_v2/swm/curved/share-1.svg',
  'share-2' = '/media/icons_v2/swm/curved/share-2.svg',
  'shield-check' = '/media/icons_v2/swm/curved/shield-check.svg',
  'shield-cross' = '/media/icons_v2/swm/curved/shield-cross.svg',
  'shield-empty' = '/media/icons_v2/swm/curved/shield-empty.svg',
  'shirt' = '/media/icons_v2/swm/curved/shirt.svg',
  'smartphone' = '/media/icons_v2/swm/curved/smartphone.svg',
  'sound-0' = '/media/icons_v2/swm/curved/sound-0.svg',
  'sound-1' = '/media/icons_v2/swm/curved/sound-1.svg',
  'sound-2' = '/media/icons_v2/swm/curved/sound-2.svg',
  'speaker-0' = '/media/icons_v2/swm/curved/speaker-0.svg',
  'speaker-1' = '/media/icons_v2/swm/curved/speaker-1.svg',
  'speaker-2' = '/media/icons_v2/swm/curved/speaker-2.svg',
  'speaker-cross' = '/media/icons_v2/swm/curved/speaker-cross.svg',
  'speaker-disabled' = '/media/icons_v2/swm/curved/speaker-disabled.svg',
  'star-1' = '/media/icons_v2/swm/curved/star-1.svg',
  'star-2' = '/media/icons_v2/swm/curved/star-2.svg',
  'stop' = '/media/icons_v2/swm/curved/stop.svg',
  'stopwatch' = '/media/icons_v2/swm/curved/stopwatch.svg',
  'suitcase' = '/media/icons_v2/swm/curved/suitcase.svg',
  'sun' = '/media/icons_v2/swm/curved/sun.svg',
  'syringe' = '/media/icons_v2/swm/curved/syringe.svg',
  'tag' = '/media/icons_v2/swm/curved/tag.svg',
  'test-tube' = '/media/icons_v2/swm/curved/test-tube.svg',
  'tooth' = '/media/icons_v2/swm/curved/tooth.svg',
  'trash-1' = '/media/icons_v2/swm/curved/trash-1.svg',
  'trash-2' = '/media/icons_v2/swm/curved/trash-2.svg',
  'trending-down' = '/media/icons_v2/swm/curved/trending-down.svg',
  'trending-up' = '/media/icons_v2/swm/curved/trending-up.svg',
  'trophy' = '/media/icons_v2/swm/curved/trophy.svg',
  'umbrella-1' = '/media/icons_v2/swm/curved/umbrella-1.svg',
  'umbrella-2' = '/media/icons_v2/swm/curved/umbrella-2.svg',
  'undo-circle' = '/media/icons_v2/swm/curved/undo-circle.svg',
  'undo-small' = '/media/icons_v2/swm/curved/undo-small.svg',
  'undo' = '/media/icons_v2/swm/curved/undo.svg',
  'upload' = '/media/icons_v2/swm/curved/upload.svg',
  'user-1' = '/media/icons_v2/swm/curved/user-1.svg',
  'user-2' = '/media/icons_v2/swm/curved/user-2.svg',
  'user-check' = '/media/icons_v2/swm/curved/user-check.svg',
  'user-cross' = '/media/icons_v2/swm/curved/user-cross.svg',
  'user-info' = '/media/icons_v2/swm/curved/user-info.svg',
  'user-minus' = '/media/icons_v2/swm/curved/user-minus.svg',
  'user-plus' = '/media/icons_v2/swm/curved/user-plus.svg',
  'user-question' = '/media/icons_v2/swm/curved/user-question.svg',
  'user-warning' = '/media/icons_v2/swm/curved/user-warning.svg',
  'users-more' = '/media/icons_v2/swm/curved/users-more.svg',
  'users' = '/media/icons_v2/swm/curved/users.svg',
  'vertical' = '/media/icons_v2/swm/curved/vertical.svg',
  'virus' = '/media/icons_v2/swm/curved/virus.svg',
  'wallet' = '/media/icons_v2/swm/curved/wallet.svg',
  'wand' = '/media/icons_v2/swm/curved/wand.svg',
  'warning-circle' = '/media/icons_v2/swm/curved/warning-circle.svg',
  'warning-small' = '/media/icons_v2/swm/curved/warning-small.svg',
  'warning' = '/media/icons_v2/swm/curved/warning.svg',
  'waterdrop' = '/media/icons_v2/swm/curved/waterdrop.svg',
  'windows' = '/media/icons_v2/swm/curved/windows.svg',
  'zoom-in' = '/media/icons_v2/swm/curved/zoom-in.svg',
  'zoom-out' = '/media/icons_v2/swm/curved/zoom-out.svg',
}

export enum VIconSWMDuotone {
  'air' = '/media/icons_v2/swm/duotone/air.svg',
  'alarm' = '/media/icons_v2/swm/duotone/alarm.svg',
  'arrow-circle-down' = '/media/icons_v2/swm/duotone/arrow-circle-down.svg',
  'arrow-circle-left' = '/media/icons_v2/swm/duotone/arrow-circle-left.svg',
  'arrow-circle-right' = '/media/icons_v2/swm/duotone/arrow-circle-right.svg',
  'arrow-circle-up' = '/media/icons_v2/swm/duotone/arrow-circle-up.svg',
  'arrow-down' = '/media/icons_v2/swm/duotone/arrow-down.svg',
  'arrow-left' = '/media/icons_v2/swm/duotone/arrow-left.svg',
  'arrow-right' = '/media/icons_v2/swm/duotone/arrow-right.svg',
  'arrow-small-down' = '/media/icons_v2/swm/duotone/arrow-small-down.svg',
  'arrow-small-left' = '/media/icons_v2/swm/duotone/arrow-small-left.svg',
  'arrow-small-right' = '/media/icons_v2/swm/duotone/arrow-small-right.svg',
  'arrow-small-up' = '/media/icons_v2/swm/duotone/arrow-small-up.svg',
  'arrow-up' = '/media/icons_v2/swm/duotone/arrow-up.svg',
  'at-email' = '/media/icons_v2/swm/duotone/at-email.svg',
  'attachment' = '/media/icons_v2/swm/duotone/attachment.svg',
  'basket' = '/media/icons_v2/swm/duotone/basket.svg',
  'basketball' = '/media/icons_v2/swm/duotone/basketball.svg',
  'bell-disabled' = '/media/icons_v2/swm/duotone/bell-disabled.svg',
  'bell' = '/media/icons_v2/swm/duotone/bell.svg',
  'block-1' = '/media/icons_v2/swm/duotone/block-1.svg',
  'block-2' = '/media/icons_v2/swm/duotone/block-2.svg',
  'bolt' = '/media/icons_v2/swm/duotone/bolt.svg',
  'bone-duotone' = '/media/icons_v2/swm/duotone/bone-duotone.svg',
  'bone' = '/media/icons_v2/swm/duotone/bone.svg',
  'bookmark' = '/media/icons_v2/swm/duotone/bookmark.svg',
  'calendar-check' = '/media/icons_v2/swm/duotone/calendar-check.svg',
  'calendar-clock' = '/media/icons_v2/swm/duotone/calendar-clock.svg',
  'calendar-cross' = '/media/icons_v2/swm/duotone/calendar-cross.svg',
  'calendar-edit' = '/media/icons_v2/swm/duotone/calendar-edit.svg',
  'calendar-link' = '/media/icons_v2/swm/duotone/calendar-link.svg',
  'calendar-lock' = '/media/icons_v2/swm/duotone/calendar-lock.svg',
  'calendar-minus' = '/media/icons_v2/swm/duotone/calendar-minus.svg',
  'calendar-plus' = '/media/icons_v2/swm/duotone/calendar-plus.svg',
  'calendar-user' = '/media/icons_v2/swm/duotone/calendar-user.svg',
  'calendar-warning' = '/media/icons_v2/swm/duotone/calendar-warning.svg',
  'calendar' = '/media/icons_v2/swm/duotone/calendar.svg',
  'cam-disabled' = '/media/icons_v2/swm/duotone/cam-disabled.svg',
  'cam' = '/media/icons_v2/swm/duotone/cam.svg',
  'camera-disabled' = '/media/icons_v2/swm/duotone/camera-disabled.svg',
  'camera' = '/media/icons_v2/swm/duotone/camera.svg',
  'capsule' = '/media/icons_v2/swm/duotone/capsule.svg',
  'cardiology' = '/media/icons_v2/swm/duotone/cardiology.svg',
  'cart-1' = '/media/icons_v2/swm/duotone/cart-1.svg',
  'cart-2' = '/media/icons_v2/swm/duotone/cart-2.svg',
  'cart-3' = '/media/icons_v2/swm/duotone/cart-3.svg',
  'cart-4' = '/media/icons_v2/swm/duotone/cart-4.svg',
  'cast' = '/media/icons_v2/swm/duotone/cast.svg',
  'chart-vertical' = '/media/icons_v2/swm/duotone/chart-vertical.svg',
  'chart' = '/media/icons_v2/swm/duotone/chart.svg',
  'check-circle' = '/media/icons_v2/swm/duotone/check-circle.svg',
  'check-small' = '/media/icons_v2/swm/duotone/check-small.svg',
  'check' = '/media/icons_v2/swm/duotone/check.svg',
  'chevron-circle-down' = '/media/icons_v2/swm/duotone/chevron-circle-down.svg',
  'chevron-circle-left' = '/media/icons_v2/swm/duotone/chevron-circle-left.svg',
  'chevron-circle-right' = '/media/icons_v2/swm/duotone/chevron-circle-right.svg',
  'chevron-circle-up' = '/media/icons_v2/swm/duotone/chevron-circle-up.svg',
  'chevron-down' = '/media/icons_v2/swm/duotone/chevron-down.svg',
  'chevron-left' = '/media/icons_v2/swm/duotone/chevron-left.svg',
  'chevron-right' = '/media/icons_v2/swm/duotone/chevron-right.svg',
  'chevron-small-down' = '/media/icons_v2/swm/duotone/chevron-small-down.svg',
  'chevron-small-left' = '/media/icons_v2/swm/duotone/chevron-small-left.svg',
  'chevron-small-right' = '/media/icons_v2/swm/duotone/chevron-small-right.svg',
  'chevron-small-up' = '/media/icons_v2/swm/duotone/chevron-small-up.svg',
  'chevron-up' = '/media/icons_v2/swm/duotone/chevron-up.svg',
  'circle' = '/media/icons_v2/swm/duotone/circle.svg',
  'clocl' = '/media/icons_v2/swm/duotone/clocl.svg',
  'cloud' = '/media/icons_v2/swm/duotone/cloud.svg',
  'coin' = '/media/icons_v2/swm/duotone/coin.svg',
  'command' = '/media/icons_v2/swm/duotone/command.svg',
  'copy' = '/media/icons_v2/swm/duotone/copy.svg',
  'credit-card' = '/media/icons_v2/swm/duotone/credit-card.svg',
  'cross-circle' = '/media/icons_v2/swm/duotone/cross-circle.svg',
  'cross-small' = '/media/icons_v2/swm/duotone/cross-small.svg',
  'cross' = '/media/icons_v2/swm/duotone/cross.svg',
  'crown-1' = '/media/icons_v2/swm/duotone/crown-1.svg',
  'crown-2' = '/media/icons_v2/swm/duotone/crown-2.svg',
  'cut' = '/media/icons_v2/swm/duotone/cut.svg',
  'delete' = '/media/icons_v2/swm/duotone/delete.svg',
  'dislike' = '/media/icons_v2/swm/duotone/dislike.svg',
  'dna' = '/media/icons_v2/swm/duotone/dna.svg',
  'document-check' = '/media/icons_v2/swm/duotone/document-check.svg',
  'document-clean' = '/media/icons_v2/swm/duotone/document-clean.svg',
  'document-cross' = '/media/icons_v2/swm/duotone/document-cross.svg',
  'document-filled' = '/media/icons_v2/swm/duotone/document-filled.svg',
  'document-minus' = '/media/icons_v2/swm/duotone/document-minus.svg',
  'document-plus' = '/media/icons_v2/swm/duotone/document-plus.svg',
  'download' = '/media/icons_v2/swm/duotone/download.svg',
  'edit-1' = '/media/icons_v2/swm/duotone/edit-1.svg',
  'edit-2' = '/media/icons_v2/swm/duotone/edit-2.svg',
  'edit-3' = '/media/icons_v2/swm/duotone/edit-3.svg',
  'edit-4' = '/media/icons_v2/swm/duotone/edit-4.svg',
  'emote-normal' = '/media/icons_v2/swm/duotone/emote-normal.svg',
  'emote-sad' = '/media/icons_v2/swm/duotone/emote-sad.svg',
  'emote-smile' = '/media/icons_v2/swm/duotone/emote-smile.svg',
  'explore' = '/media/icons_v2/swm/duotone/explore.svg',
  'eye-closed' = '/media/icons_v2/swm/duotone/eye-closed.svg',
  'eye-open' = '/media/icons_v2/swm/duotone/eye-open.svg',
  'faceid' = '/media/icons_v2/swm/duotone/faceid.svg',
  'female' = '/media/icons_v2/swm/duotone/female.svg',
  'filters-1' = '/media/icons_v2/swm/duotone/filters-1.svg',
  'filters-2' = '/media/icons_v2/swm/duotone/filters-2.svg',
  'filters-3' = '/media/icons_v2/swm/duotone/filters-3.svg',
  'flag-1' = '/media/icons_v2/swm/duotone/flag-1.svg',
  'flag-2' = '/media/icons_v2/swm/duotone/flag-2.svg',
  'flag-3' = '/media/icons_v2/swm/duotone/flag-3.svg',
  'forward' = '/media/icons_v2/swm/duotone/forward.svg',
  'fullscreen' = '/media/icons_v2/swm/duotone/fullscreen.svg',
  'gift-1' = '/media/icons_v2/swm/duotone/gift-1.svg',
  'globe-1' = '/media/icons_v2/swm/duotone/globe-1.svg',
  'grid-2-horizontal' = '/media/icons_v2/swm/duotone/grid-2-horizontal.svg',
  'grid-2-vertical' = '/media/icons_v2/swm/duotone/grid-2-vertical.svg',
  'grid-4' = '/media/icons_v2/swm/duotone/grid-4.svg',
  'headphones' = '/media/icons_v2/swm/duotone/headphones.svg',
  'heart' = '/media/icons_v2/swm/duotone/heart.svg',
  'home-1' = '/media/icons_v2/swm/duotone/home-1.svg',
  'home-2' = '/media/icons_v2/swm/duotone/home-2.svg',
  'home-hospital' = '/media/icons_v2/swm/duotone/home-hospital.svg',
  'horizontal' = '/media/icons_v2/swm/duotone/horizontal.svg',
  'hourglass' = '/media/icons_v2/swm/duotone/hourglass.svg',
  'ice' = '/media/icons_v2/swm/duotone/ice.svg',
  'image-1' = '/media/icons_v2/swm/duotone/image-1.svg',
  'inbox' = '/media/icons_v2/swm/duotone/inbox.svg',
  'info-circle' = '/media/icons_v2/swm/duotone/info-circle.svg',
  'info-small' = '/media/icons_v2/swm/duotone/info-small.svg',
  'info' = '/media/icons_v2/swm/duotone/info.svg',
  'key' = '/media/icons_v2/swm/duotone/key.svg',
  'laptop' = '/media/icons_v2/swm/duotone/laptop.svg',
  'like' = '/media/icons_v2/swm/duotone/like.svg',
  'link' = '/media/icons_v2/swm/duotone/link.svg',
  'list-center' = '/media/icons_v2/swm/duotone/list-center.svg',
  'list-left' = '/media/icons_v2/swm/duotone/list-left.svg',
  'list-pointers' = '/media/icons_v2/swm/duotone/list-pointers.svg',
  'list-right' = '/media/icons_v2/swm/duotone/list-right.svg',
  'location-1' = '/media/icons_v2/swm/duotone/location-1.svg',
  'location-2' = '/media/icons_v2/swm/duotone/location-2.svg',
  'location-med-1' = '/media/icons_v2/swm/duotone/location-med-1.svg',
  'location-med-2' = '/media/icons_v2/swm/duotone/location-med-2.svg',
  'lock-off' = '/media/icons_v2/swm/duotone/lock-off.svg',
  'lock-on' = '/media/icons_v2/swm/duotone/lock-on.svg',
  'login' = '/media/icons_v2/swm/duotone/login.svg',
  'logout' = '/media/icons_v2/swm/duotone/logout.svg',
  'mail' = '/media/icons_v2/swm/duotone/mail.svg',
  'male' = '/media/icons_v2/swm/duotone/male.svg',
  'map' = '/media/icons_v2/swm/duotone/map.svg',
  'medkit' = '/media/icons_v2/swm/duotone/medkit.svg',
  'meds' = '/media/icons_v2/swm/duotone/meds.svg',
  'menu-hamburger' = '/media/icons_v2/swm/duotone/menu-hamburger.svg',
  'menu-horizontal' = '/media/icons_v2/swm/duotone/menu-horizontal.svg',
  'menu-vertical' = '/media/icons_v2/swm/duotone/menu-vertical.svg',
  'messages-circle-dots' = '/media/icons_v2/swm/duotone/messages-circle-dots.svg',
  'messages-circle-lines' = '/media/icons_v2/swm/duotone/messages-circle-lines.svg',
  'messages-circle' = '/media/icons_v2/swm/duotone/messages-circle.svg',
  'messages-square-dots' = '/media/icons_v2/swm/duotone/messages-square-dots.svg',
  'messages-square-lines' = '/media/icons_v2/swm/duotone/messages-square-lines.svg',
  'messages-square' = '/media/icons_v2/swm/duotone/messages-square.svg',
  'microphone-disabled' = '/media/icons_v2/swm/duotone/microphone-disabled.svg',
  'microphone' = '/media/icons_v2/swm/duotone/microphone.svg',
  'minus-circle' = '/media/icons_v2/swm/duotone/minus-circle.svg',
  'minus-small' = '/media/icons_v2/swm/duotone/minus-small.svg',
  'minus' = '/media/icons_v2/swm/duotone/minus.svg',
  'money' = '/media/icons_v2/swm/duotone/money.svg',
  'moon' = '/media/icons_v2/swm/duotone/moon.svg',
  'music' = '/media/icons_v2/swm/duotone/music.svg',
  'navigation' = '/media/icons_v2/swm/duotone/navigation.svg',
  'newscreen' = '/media/icons_v2/swm/duotone/newscreen.svg',
  'next' = '/media/icons_v2/swm/duotone/next.svg',
  'offer' = '/media/icons_v2/swm/duotone/offer.svg',
  'package' = '/media/icons_v2/swm/duotone/package.svg',
  'pause' = '/media/icons_v2/swm/duotone/pause.svg',
  'phone-call' = '/media/icons_v2/swm/duotone/phone-call.svg',
  'phone-cross' = '/media/icons_v2/swm/duotone/phone-cross.svg',
  'phone-down' = '/media/icons_v2/swm/duotone/phone-down.svg',
  'pin-1' = '/media/icons_v2/swm/duotone/pin-1.svg',
  'pin-2' = '/media/icons_v2/swm/duotone/pin-2.svg',
  'pinpaper-check' = '/media/icons_v2/swm/duotone/pinpaper-check.svg',
  'pinpaper-cross' = '/media/icons_v2/swm/duotone/pinpaper-cross.svg',
  'pinpaper-filled' = '/media/icons_v2/swm/duotone/pinpaper-filled.svg',
  'pinpaper-minus' = '/media/icons_v2/swm/duotone/pinpaper-minus.svg',
  'pinpaper-plus' = '/media/icons_v2/swm/duotone/pinpaper-plus.svg',
  'play' = '/media/icons_v2/swm/duotone/play.svg',
  'plus-circle' = '/media/icons_v2/swm/duotone/plus-circle.svg',
  'plus-small' = '/media/icons_v2/swm/duotone/plus-small.svg',
  'plus' = '/media/icons_v2/swm/duotone/plus.svg',
  'power' = '/media/icons_v2/swm/duotone/power.svg',
  'previous' = '/media/icons_v2/swm/duotone/previous.svg',
  'print' = '/media/icons_v2/swm/duotone/print.svg',
  'question-circle' = '/media/icons_v2/swm/duotone/question-circle.svg',
  'question-small' = '/media/icons_v2/swm/duotone/question-small.svg',
  'question' = '/media/icons_v2/swm/duotone/question.svg',
  'quote' = '/media/icons_v2/swm/duotone/quote.svg',
  'redo-circle' = '/media/icons_v2/swm/duotone/redo-circle.svg',
  'redo-small' = '/media/icons_v2/swm/duotone/redo-small.svg',
  'redo' = '/media/icons_v2/swm/duotone/redo.svg',
  'refresh-circle' = '/media/icons_v2/swm/duotone/refresh-circle.svg',
  'refresh-small' = '/media/icons_v2/swm/duotone/refresh-small.svg',
  'refresh' = '/media/icons_v2/swm/duotone/refresh.svg',
  'resize-circle-horizontal' = '/media/icons_v2/swm/duotone/resize-circle-horizontal.svg',
  'resize-circle-vertical' = '/media/icons_v2/swm/duotone/resize-circle-vertical.svg',
  'resize-small-horizontal' = '/media/icons_v2/swm/duotone/resize-small-horizontal.svg',
  'resize-small-vertical' = '/media/icons_v2/swm/duotone/resize-small-vertical.svg',
  'rewind' = '/media/icons_v2/swm/duotone/rewind.svg',
  'rotate-circle-left' = '/media/icons_v2/swm/duotone/rotate-circle-left.svg',
  'rotate-circle-right' = '/media/icons_v2/swm/duotone/rotate-circle-right.svg',
  'rotate-small-left' = '/media/icons_v2/swm/duotone/rotate-small-left.svg',
  'rotate-small-right' = '/media/icons_v2/swm/duotone/rotate-small-right.svg',
  'save' = '/media/icons_v2/swm/duotone/save.svg',
  'screen-disabled' = '/media/icons_v2/swm/duotone/screen-disabled.svg',
  'screen-share' = '/media/icons_v2/swm/duotone/screen-share.svg',
  'screen' = '/media/icons_v2/swm/duotone/screen.svg',
  'search' = '/media/icons_v2/swm/duotone/search.svg',
  'send-1' = '/media/icons_v2/swm/duotone/send-1.svg',
  'send-2' = '/media/icons_v2/swm/duotone/send-2.svg',
  'settings' = '/media/icons_v2/swm/duotone/settings.svg',
  'share-1' = '/media/icons_v2/swm/duotone/share-1.svg',
  'share-2' = '/media/icons_v2/swm/duotone/share-2.svg',
  'shield-check' = '/media/icons_v2/swm/duotone/shield-check.svg',
  'shield-cross' = '/media/icons_v2/swm/duotone/shield-cross.svg',
  'shield-empty' = '/media/icons_v2/swm/duotone/shield-empty.svg',
  'shirt' = '/media/icons_v2/swm/duotone/shirt.svg',
  'smartphone' = '/media/icons_v2/swm/duotone/smartphone.svg',
  'sound-0' = '/media/icons_v2/swm/duotone/sound-0.svg',
  'sound-1' = '/media/icons_v2/swm/duotone/sound-1.svg',
  'sound-2' = '/media/icons_v2/swm/duotone/sound-2.svg',
  'speaker-0' = '/media/icons_v2/swm/duotone/speaker-0.svg',
  'speaker-1' = '/media/icons_v2/swm/duotone/speaker-1.svg',
  'speaker-2' = '/media/icons_v2/swm/duotone/speaker-2.svg',
  'speaker-cross' = '/media/icons_v2/swm/duotone/speaker-cross.svg',
  'speaker-disabled' = '/media/icons_v2/swm/duotone/speaker-disabled.svg',
  'star-1' = '/media/icons_v2/swm/duotone/star-1.svg',
  'star-2' = '/media/icons_v2/swm/duotone/star-2.svg',
  'stop' = '/media/icons_v2/swm/duotone/stop.svg',
  'stopwatch' = '/media/icons_v2/swm/duotone/stopwatch.svg',
  'suitcase' = '/media/icons_v2/swm/duotone/suitcase.svg',
  'sun' = '/media/icons_v2/swm/duotone/sun.svg',
  'syringe' = '/media/icons_v2/swm/duotone/syringe.svg',
  'tag' = '/media/icons_v2/swm/duotone/tag.svg',
  'test-tube' = '/media/icons_v2/swm/duotone/test-tube.svg',
  'tooth' = '/media/icons_v2/swm/duotone/tooth.svg',
  'trash-1' = '/media/icons_v2/swm/duotone/trash-1.svg',
  'trash-2' = '/media/icons_v2/swm/duotone/trash-2.svg',
  'trending-down' = '/media/icons_v2/swm/duotone/trending-down.svg',
  'trending-up' = '/media/icons_v2/swm/duotone/trending-up.svg',
  'trophy' = '/media/icons_v2/swm/duotone/trophy.svg',
  'umbrella-1' = '/media/icons_v2/swm/duotone/umbrella-1.svg',
  'umbrella-2' = '/media/icons_v2/swm/duotone/umbrella-2.svg',
  'undo-circle' = '/media/icons_v2/swm/duotone/undo-circle.svg',
  'undo-small' = '/media/icons_v2/swm/duotone/undo-small.svg',
  'undo' = '/media/icons_v2/swm/duotone/undo.svg',
  'upload' = '/media/icons_v2/swm/duotone/upload.svg',
  'user-1' = '/media/icons_v2/swm/duotone/user-1.svg',
  'user-2' = '/media/icons_v2/swm/duotone/user-2.svg',
  'user-check' = '/media/icons_v2/swm/duotone/user-check.svg',
  'user-cross' = '/media/icons_v2/swm/duotone/user-cross.svg',
  'user-info' = '/media/icons_v2/swm/duotone/user-info.svg',
  'user-minus' = '/media/icons_v2/swm/duotone/user-minus.svg',
  'user-plus' = '/media/icons_v2/swm/duotone/user-plus.svg',
  'user-question' = '/media/icons_v2/swm/duotone/user-question.svg',
  'user-warning' = '/media/icons_v2/swm/duotone/user-warning.svg',
  'users-more' = '/media/icons_v2/swm/duotone/users-more.svg',
  'users' = '/media/icons_v2/swm/duotone/users.svg',
  'vertical' = '/media/icons_v2/swm/duotone/vertical.svg',
  'virus' = '/media/icons_v2/swm/duotone/virus.svg',
  'wallet' = '/media/icons_v2/swm/duotone/wallet.svg',
  'wand' = '/media/icons_v2/swm/duotone/wand.svg',
  'warning-circle' = '/media/icons_v2/swm/duotone/warning-circle.svg',
  'warning-small' = '/media/icons_v2/swm/duotone/warning-small.svg',
  'warning' = '/media/icons_v2/swm/duotone/warning.svg',
  'waterdrop' = '/media/icons_v2/swm/duotone/waterdrop.svg',
  'windows' = '/media/icons_v2/swm/duotone/windows.svg',
  'zoom-in' = '/media/icons_v2/swm/duotone/zoom-in.svg',
  'zoom-out' = '/media/icons_v2/swm/duotone/zoom-out.svg',
}

export enum VIconSWMOutline {
  'air' = '/media/icons_v2/swm/outline/air.svg',
  'alarm' = '/media/icons_v2/swm/outline/alarm.svg',
  'arrow-circle-down' = '/media/icons_v2/swm/outline/arrow-circle-down.svg',
  'arrow-circle-left' = '/media/icons_v2/swm/outline/arrow-circle-left.svg',
  'arrow-circle-right' = '/media/icons_v2/swm/outline/arrow-circle-right.svg',
  'arrow-circle-up' = '/media/icons_v2/swm/outline/arrow-circle-up.svg',
  'arrow-down' = '/media/icons_v2/swm/outline/arrow-down.svg',
  'arrow-left' = '/media/icons_v2/swm/outline/arrow-left.svg',
  'arrow-right' = '/media/icons_v2/swm/outline/arrow-right.svg',
  'arrow-small-down' = '/media/icons_v2/swm/outline/arrow-small-down.svg',
  'arrow-small-left' = '/media/icons_v2/swm/outline/arrow-small-left.svg',
  'arrow-small-right' = '/media/icons_v2/swm/outline/arrow-small-right.svg',
  'arrow-small-up' = '/media/icons_v2/swm/outline/arrow-small-up.svg',
  'arrow-up' = '/media/icons_v2/swm/outline/arrow-up.svg',
  'at-email' = '/media/icons_v2/swm/outline/at-email.svg',
  'attachment' = '/media/icons_v2/swm/outline/attachment.svg',
  'basket' = '/media/icons_v2/swm/outline/basket.svg',
  'basketball' = '/media/icons_v2/swm/outline/basketball.svg',
  'bell-disabled' = '/media/icons_v2/swm/outline/bell-disabled.svg',
  'bell' = '/media/icons_v2/swm/outline/bell.svg',
  'block-1' = '/media/icons_v2/swm/outline/block-1.svg',
  'block-2' = '/media/icons_v2/swm/outline/block-2.svg',
  'bolt' = '/media/icons_v2/swm/outline/bolt.svg',
  'bone-outline' = '/media/icons_v2/swm/outline/bone-outline.svg',
  'bone' = '/media/icons_v2/swm/outline/bone.svg',
  'bookmark' = '/media/icons_v2/swm/outline/bookmark.svg',
  'calendar-check' = '/media/icons_v2/swm/outline/calendar-check.svg',
  'calendar-clock' = '/media/icons_v2/swm/outline/calendar-clock.svg',
  'calendar-cross' = '/media/icons_v2/swm/outline/calendar-cross.svg',
  'calendar-edit' = '/media/icons_v2/swm/outline/calendar-edit.svg',
  'calendar-link' = '/media/icons_v2/swm/outline/calendar-link.svg',
  'calendar-lock' = '/media/icons_v2/swm/outline/calendar-lock.svg',
  'calendar-minus' = '/media/icons_v2/swm/outline/calendar-minus.svg',
  'calendar-plus' = '/media/icons_v2/swm/outline/calendar-plus.svg',
  'calendar-user' = '/media/icons_v2/swm/outline/calendar-user.svg',
  'calendar-warning' = '/media/icons_v2/swm/outline/calendar-warning.svg',
  'calendar' = '/media/icons_v2/swm/outline/calendar.svg',
  'cam-disabled' = '/media/icons_v2/swm/outline/cam-disabled.svg',
  'cam' = '/media/icons_v2/swm/outline/cam.svg',
  'camera-disabled' = '/media/icons_v2/swm/outline/camera-disabled.svg',
  'camera' = '/media/icons_v2/swm/outline/camera.svg',
  'capsule' = '/media/icons_v2/swm/outline/capsule.svg',
  'cardiology' = '/media/icons_v2/swm/outline/cardiology.svg',
  'cart-1' = '/media/icons_v2/swm/outline/cart-1.svg',
  'cart-2' = '/media/icons_v2/swm/outline/cart-2.svg',
  'cart-3' = '/media/icons_v2/swm/outline/cart-3.svg',
  'cart-4' = '/media/icons_v2/swm/outline/cart-4.svg',
  'cast' = '/media/icons_v2/swm/outline/cast.svg',
  'chart-vertical' = '/media/icons_v2/swm/outline/chart-vertical.svg',
  'chart' = '/media/icons_v2/swm/outline/chart.svg',
  'check-circle' = '/media/icons_v2/swm/outline/check-circle.svg',
  'check-small' = '/media/icons_v2/swm/outline/check-small.svg',
  'check' = '/media/icons_v2/swm/outline/check.svg',
  'chevron-circle-down' = '/media/icons_v2/swm/outline/chevron-circle-down.svg',
  'chevron-circle-left' = '/media/icons_v2/swm/outline/chevron-circle-left.svg',
  'chevron-circle-right' = '/media/icons_v2/swm/outline/chevron-circle-right.svg',
  'chevron-circle-up' = '/media/icons_v2/swm/outline/chevron-circle-up.svg',
  'chevron-down' = '/media/icons_v2/swm/outline/chevron-down.svg',
  'chevron-left' = '/media/icons_v2/swm/outline/chevron-left.svg',
  'chevron-right' = '/media/icons_v2/swm/outline/chevron-right.svg',
  'chevron-small-down' = '/media/icons_v2/swm/outline/chevron-small-down.svg',
  'chevron-small-left' = '/media/icons_v2/swm/outline/chevron-small-left.svg',
  'chevron-small-right' = '/media/icons_v2/swm/outline/chevron-small-right.svg',
  'chevron-small-up' = '/media/icons_v2/swm/outline/chevron-small-up.svg',
  'chevron-up' = '/media/icons_v2/swm/outline/chevron-up.svg',
  'circle' = '/media/icons_v2/swm/outline/circle.svg',
  'clocl' = '/media/icons_v2/swm/outline/clocl.svg',
  'cloud' = '/media/icons_v2/swm/outline/cloud.svg',
  'coin' = '/media/icons_v2/swm/outline/coin.svg',
  'command' = '/media/icons_v2/swm/outline/command.svg',
  'copy' = '/media/icons_v2/swm/outline/copy.svg',
  'credit-card' = '/media/icons_v2/swm/outline/credit-card.svg',
  'cross-circle' = '/media/icons_v2/swm/outline/cross-circle.svg',
  'cross-small' = '/media/icons_v2/swm/outline/cross-small.svg',
  'cross' = '/media/icons_v2/swm/outline/cross.svg',
  'crown-1' = '/media/icons_v2/swm/outline/crown-1.svg',
  'crown-2' = '/media/icons_v2/swm/outline/crown-2.svg',
  'cut' = '/media/icons_v2/swm/outline/cut.svg',
  'delete' = '/media/icons_v2/swm/outline/delete.svg',
  'dislike' = '/media/icons_v2/swm/outline/dislike.svg',
  'dna' = '/media/icons_v2/swm/outline/dna.svg',
  'document-check' = '/media/icons_v2/swm/outline/document-check.svg',
  'document-clean' = '/media/icons_v2/swm/outline/document-clean.svg',
  'document-cross' = '/media/icons_v2/swm/outline/document-cross.svg',
  'document-filled' = '/media/icons_v2/swm/outline/document-filled.svg',
  'document-minus' = '/media/icons_v2/swm/outline/document-minus.svg',
  'document-plus' = '/media/icons_v2/swm/outline/document-plus.svg',
  'download' = '/media/icons_v2/swm/outline/download.svg',
  'edit-1' = '/media/icons_v2/swm/outline/edit-1.svg',
  'edit-2' = '/media/icons_v2/swm/outline/edit-2.svg',
  'edit-3' = '/media/icons_v2/swm/outline/edit-3.svg',
  'edit-4' = '/media/icons_v2/swm/outline/edit-4.svg',
  'emote-normal' = '/media/icons_v2/swm/outline/emote-normal.svg',
  'emote-sad' = '/media/icons_v2/swm/outline/emote-sad.svg',
  'emote-smile' = '/media/icons_v2/swm/outline/emote-smile.svg',
  'explore' = '/media/icons_v2/swm/outline/explore.svg',
  'eye-closed' = '/media/icons_v2/swm/outline/eye-closed.svg',
  'eye-open' = '/media/icons_v2/swm/outline/eye-open.svg',
  'faceid' = '/media/icons_v2/swm/outline/faceid.svg',
  'female' = '/media/icons_v2/swm/outline/female.svg',
  'filters-1' = '/media/icons_v2/swm/outline/filters-1.svg',
  'filters-2' = '/media/icons_v2/swm/outline/filters-2.svg',
  'filters-3' = '/media/icons_v2/swm/outline/filters-3.svg',
  'flag-1' = '/media/icons_v2/swm/outline/flag-1.svg',
  'flag-2' = '/media/icons_v2/swm/outline/flag-2.svg',
  'flag-3' = '/media/icons_v2/swm/outline/flag-3.svg',
  'forward' = '/media/icons_v2/swm/outline/forward.svg',
  'fullscreen' = '/media/icons_v2/swm/outline/fullscreen.svg',
  'gift-1' = '/media/icons_v2/swm/outline/gift-1.svg',
  'globe-1' = '/media/icons_v2/swm/outline/globe-1.svg',
  'grid-2-horizontal' = '/media/icons_v2/swm/outline/grid-2-horizontal.svg',
  'grid-2-vertical' = '/media/icons_v2/swm/outline/grid-2-vertical.svg',
  'grid-4' = '/media/icons_v2/swm/outline/grid-4.svg',
  'headphones' = '/media/icons_v2/swm/outline/headphones.svg',
  'heart' = '/media/icons_v2/swm/outline/heart.svg',
  'home-1' = '/media/icons_v2/swm/outline/home-1.svg',
  'home-2' = '/media/icons_v2/swm/outline/home-2.svg',
  'home-hospital' = '/media/icons_v2/swm/outline/home-hospital.svg',
  'horizontal' = '/media/icons_v2/swm/outline/horizontal.svg',
  'hourglass' = '/media/icons_v2/swm/outline/hourglass.svg',
  'ice' = '/media/icons_v2/swm/outline/ice.svg',
  'image-1' = '/media/icons_v2/swm/outline/image-1.svg',
  'inbox' = '/media/icons_v2/swm/outline/inbox.svg',
  'info-circle' = '/media/icons_v2/swm/outline/info-circle.svg',
  'info-small' = '/media/icons_v2/swm/outline/info-small.svg',
  'info' = '/media/icons_v2/swm/outline/info.svg',
  'key' = '/media/icons_v2/swm/outline/key.svg',
  'laptop' = '/media/icons_v2/swm/outline/laptop.svg',
  'like' = '/media/icons_v2/swm/outline/like.svg',
  'link' = '/media/icons_v2/swm/outline/link.svg',
  'list-center' = '/media/icons_v2/swm/outline/list-center.svg',
  'list-left' = '/media/icons_v2/swm/outline/list-left.svg',
  'list-pointers' = '/media/icons_v2/swm/outline/list-pointers.svg',
  'list-right' = '/media/icons_v2/swm/outline/list-right.svg',
  'location-1' = '/media/icons_v2/swm/outline/location-1.svg',
  'location-2' = '/media/icons_v2/swm/outline/location-2.svg',
  'location-med-1' = '/media/icons_v2/swm/outline/location-med-1.svg',
  'location-med-2' = '/media/icons_v2/swm/outline/location-med-2.svg',
  'lock-off' = '/media/icons_v2/swm/outline/lock-off.svg',
  'lock-on' = '/media/icons_v2/swm/outline/lock-on.svg',
  'login' = '/media/icons_v2/swm/outline/login.svg',
  'logout' = '/media/icons_v2/swm/outline/logout.svg',
  'mail' = '/media/icons_v2/swm/outline/mail.svg',
  'male' = '/media/icons_v2/swm/outline/male.svg',
  'map' = '/media/icons_v2/swm/outline/map.svg',
  'medkit' = '/media/icons_v2/swm/outline/medkit.svg',
  'meds' = '/media/icons_v2/swm/outline/meds.svg',
  'menu-hamburger' = '/media/icons_v2/swm/outline/menu-hamburger.svg',
  'menu-horizontal' = '/media/icons_v2/swm/outline/menu-horizontal.svg',
  'menu-vertical' = '/media/icons_v2/swm/outline/menu-vertical.svg',
  'messages-circle-dots' = '/media/icons_v2/swm/outline/messages-circle-dots.svg',
  'messages-circle-lines' = '/media/icons_v2/swm/outline/messages-circle-lines.svg',
  'messages-circle' = '/media/icons_v2/swm/outline/messages-circle.svg',
  'messages-square-dots' = '/media/icons_v2/swm/outline/messages-square-dots.svg',
  'messages-square-lines' = '/media/icons_v2/swm/outline/messages-square-lines.svg',
  'messages-square' = '/media/icons_v2/swm/outline/messages-square.svg',
  'microphone-disabled' = '/media/icons_v2/swm/outline/microphone-disabled.svg',
  'microphone' = '/media/icons_v2/swm/outline/microphone.svg',
  'minus-circle' = '/media/icons_v2/swm/outline/minus-circle.svg',
  'minus-small' = '/media/icons_v2/swm/outline/minus-small.svg',
  'minus' = '/media/icons_v2/swm/outline/minus.svg',
  'money' = '/media/icons_v2/swm/outline/money.svg',
  'moon' = '/media/icons_v2/swm/outline/moon.svg',
  'music' = '/media/icons_v2/swm/outline/music.svg',
  'navigation' = '/media/icons_v2/swm/outline/navigation.svg',
  'newscreen' = '/media/icons_v2/swm/outline/newscreen.svg',
  'next' = '/media/icons_v2/swm/outline/next.svg',
  'offer' = '/media/icons_v2/swm/outline/offer.svg',
  'package' = '/media/icons_v2/swm/outline/package.svg',
  'pause' = '/media/icons_v2/swm/outline/pause.svg',
  'phone-call' = '/media/icons_v2/swm/outline/phone-call.svg',
  'phone-cross' = '/media/icons_v2/swm/outline/phone-cross.svg',
  'phone-down' = '/media/icons_v2/swm/outline/phone-down.svg',
  'pin-1' = '/media/icons_v2/swm/outline/pin-1.svg',
  'pin-2' = '/media/icons_v2/swm/outline/pin-2.svg',
  'pinpaper-check' = '/media/icons_v2/swm/outline/pinpaper-check.svg',
  'pinpaper-cross' = '/media/icons_v2/swm/outline/pinpaper-cross.svg',
  'pinpaper-filled' = '/media/icons_v2/swm/outline/pinpaper-filled.svg',
  'pinpaper-minus' = '/media/icons_v2/swm/outline/pinpaper-minus.svg',
  'pinpaper-plus' = '/media/icons_v2/swm/outline/pinpaper-plus.svg',
  'play' = '/media/icons_v2/swm/outline/play.svg',
  'plus-circle' = '/media/icons_v2/swm/outline/plus-circle.svg',
  'plus-small' = '/media/icons_v2/swm/outline/plus-small.svg',
  'plus' = '/media/icons_v2/swm/outline/plus.svg',
  'power' = '/media/icons_v2/swm/outline/power.svg',
  'previous' = '/media/icons_v2/swm/outline/previous.svg',
  'print' = '/media/icons_v2/swm/outline/print.svg',
  'question-circle' = '/media/icons_v2/swm/outline/question-circle.svg',
  'question-small' = '/media/icons_v2/swm/outline/question-small.svg',
  'question' = '/media/icons_v2/swm/outline/question.svg',
  'quote' = '/media/icons_v2/swm/outline/quote.svg',
  'redo-circle' = '/media/icons_v2/swm/outline/redo-circle.svg',
  'redo-small' = '/media/icons_v2/swm/outline/redo-small.svg',
  'redo' = '/media/icons_v2/swm/outline/redo.svg',
  'refresh-circle' = '/media/icons_v2/swm/outline/refresh-circle.svg',
  'refresh-small' = '/media/icons_v2/swm/outline/refresh-small.svg',
  'refresh' = '/media/icons_v2/swm/outline/refresh.svg',
  'resize-circle-horizontal' = '/media/icons_v2/swm/outline/resize-circle-horizontal.svg',
  'resize-circle-vertical' = '/media/icons_v2/swm/outline/resize-circle-vertical.svg',
  'resize-small-horizontal' = '/media/icons_v2/swm/outline/resize-small-horizontal.svg',
  'resize-small-vertical' = '/media/icons_v2/swm/outline/resize-small-vertical.svg',
  'rewind' = '/media/icons_v2/swm/outline/rewind.svg',
  'rotate-circle-left' = '/media/icons_v2/swm/outline/rotate-circle-left.svg',
  'rotate-circle-right' = '/media/icons_v2/swm/outline/rotate-circle-right.svg',
  'rotate-small-left' = '/media/icons_v2/swm/outline/rotate-small-left.svg',
  'rotate-small-right' = '/media/icons_v2/swm/outline/rotate-small-right.svg',
  'save' = '/media/icons_v2/swm/outline/save.svg',
  'screen-disabled' = '/media/icons_v2/swm/outline/screen-disabled.svg',
  'screen-share' = '/media/icons_v2/swm/outline/screen-share.svg',
  'screen' = '/media/icons_v2/swm/outline/screen.svg',
  'search' = '/media/icons_v2/swm/outline/search.svg',
  'send-1' = '/media/icons_v2/swm/outline/send-1.svg',
  'send-2' = '/media/icons_v2/swm/outline/send-2.svg',
  'settings' = '/media/icons_v2/swm/outline/settings.svg',
  'share-1' = '/media/icons_v2/swm/outline/share-1.svg',
  'share-2' = '/media/icons_v2/swm/outline/share-2.svg',
  'shield-check' = '/media/icons_v2/swm/outline/shield-check.svg',
  'shield-cross' = '/media/icons_v2/swm/outline/shield-cross.svg',
  'shield-empty' = '/media/icons_v2/swm/outline/shield-empty.svg',
  'shirt' = '/media/icons_v2/swm/outline/shirt.svg',
  'smartphone' = '/media/icons_v2/swm/outline/smartphone.svg',
  'sound-0' = '/media/icons_v2/swm/outline/sound-0.svg',
  'sound-1' = '/media/icons_v2/swm/outline/sound-1.svg',
  'sound-2' = '/media/icons_v2/swm/outline/sound-2.svg',
  'speaker-0' = '/media/icons_v2/swm/outline/speaker-0.svg',
  'speaker-1' = '/media/icons_v2/swm/outline/speaker-1.svg',
  'speaker-2' = '/media/icons_v2/swm/outline/speaker-2.svg',
  'speaker-cross' = '/media/icons_v2/swm/outline/speaker-cross.svg',
  'speaker-disabled' = '/media/icons_v2/swm/outline/speaker-disabled.svg',
  'star-1' = '/media/icons_v2/swm/outline/star-1.svg',
  'star-2' = '/media/icons_v2/swm/outline/star-2.svg',
  'stop' = '/media/icons_v2/swm/outline/stop.svg',
  'stopwatch' = '/media/icons_v2/swm/outline/stopwatch.svg',
  'suitcase' = '/media/icons_v2/swm/outline/suitcase.svg',
  'sun' = '/media/icons_v2/swm/outline/sun.svg',
  'syringe' = '/media/icons_v2/swm/outline/syringe.svg',
  'tag' = '/media/icons_v2/swm/outline/tag.svg',
  'test-tube' = '/media/icons_v2/swm/outline/test-tube.svg',
  'tooth' = '/media/icons_v2/swm/outline/tooth.svg',
  'trash-1' = '/media/icons_v2/swm/outline/trash-1.svg',
  'trash-2' = '/media/icons_v2/swm/outline/trash-2.svg',
  'trending-down' = '/media/icons_v2/swm/outline/trending-down.svg',
  'trending-up' = '/media/icons_v2/swm/outline/trending-up.svg',
  'trophy' = '/media/icons_v2/swm/outline/trophy.svg',
  'umbrella-1' = '/media/icons_v2/swm/outline/umbrella-1.svg',
  'umbrella-2' = '/media/icons_v2/swm/outline/umbrella-2.svg',
  'undo-circle' = '/media/icons_v2/swm/outline/undo-circle.svg',
  'undo-small' = '/media/icons_v2/swm/outline/undo-small.svg',
  'undo' = '/media/icons_v2/swm/outline/undo.svg',
  'upload' = '/media/icons_v2/swm/outline/upload.svg',
  'user-1' = '/media/icons_v2/swm/outline/user-1.svg',
  'user-2' = '/media/icons_v2/swm/outline/user-2.svg',
  'user-check' = '/media/icons_v2/swm/outline/user-check.svg',
  'user-cross' = '/media/icons_v2/swm/outline/user-cross.svg',
  'user-info' = '/media/icons_v2/swm/outline/user-info.svg',
  'user-minus' = '/media/icons_v2/swm/outline/user-minus.svg',
  'user-plus' = '/media/icons_v2/swm/outline/user-plus.svg',
  'user-question' = '/media/icons_v2/swm/outline/user-question.svg',
  'user-warning' = '/media/icons_v2/swm/outline/user-warning.svg',
  'users-more' = '/media/icons_v2/swm/outline/users-more.svg',
  'users' = '/media/icons_v2/swm/outline/users.svg',
  'vertical' = '/media/icons_v2/swm/outline/vertical.svg',
  'virus' = '/media/icons_v2/swm/outline/virus.svg',
  'wallet' = '/media/icons_v2/swm/outline/wallet.svg',
  'wand' = '/media/icons_v2/swm/outline/wand.svg',
  'warning-circle' = '/media/icons_v2/swm/outline/warning-circle.svg',
  'warning-small' = '/media/icons_v2/swm/outline/warning-small.svg',
  'warning' = '/media/icons_v2/swm/outline/warning.svg',
  'waterdrop' = '/media/icons_v2/swm/outline/waterdrop.svg',
  'windows' = '/media/icons_v2/swm/outline/windows.svg',
  'zoom-in' = '/media/icons_v2/swm/outline/zoom-in.svg',
  'zoom-out' = '/media/icons_v2/swm/outline/zoom-out.svg',
}
