import { Row } from "react-table";
import { DragHandle } from "./DragHandle";
import { FC } from "react";

interface StaticTableRowProps {
  row: Row;
}

export const StaticTableRow: FC<StaticTableRowProps> = ({ row }: { row: Row }) => {
  return (
    <tr {...row.getRowProps()} className="border-b border-neutral-30">
      {row.cells.map((cell, i) => {
        if (i === 0) {
          return (
            <td {...cell.getCellProps()} className="p-4">
              <DragHandle isDragging />
              <span>{cell.render("Cell")}</span>
            </td>
          );
        }
        return (
          <td {...cell.getCellProps()} className="p-4">
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};
