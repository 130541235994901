
import { FC, useState } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { PageTitle } from 'src/app/components/Layout/header/HeaderTitle'
import {
  Card,
} from 'src/app/components/Libs'
import TabsComponent from 'src/app/components/Widgets/Tabs'
import { useWebTitle } from 'src/app/hooks/title-hook'
import PromotionScreens, { SectionBannerTab } from '../../Screens'
import OngoingSection from './OngoingSection/OngoingSection.page'
import SectionList from './SectionList/SectionList.page'

const ListSectionBannerPage: FC = () => {
  useWebTitle(PromotionScreens.LIST_BANNER.TITLE)
  const history = useHistory()
  const { tab } = useParams<{ tab: string }>()
  const [selectedTab, setSelectedTab] = useState(tab);

  const handleTabChange = (value: any) => {
    setSelectedTab(value);
    history.push(`${PromotionScreens.SECTION_BANNER.PATH}/list/${value}`)
  };

  const tabsDomain = [
    { label: 'Home Section', value: 'ongoing' },
    { label: 'Section List', value: 'section' },
  ];

  return (
    <div data-testid='list-section-banner'>
      <PageTitle description={PromotionScreens.SECTION_BANNER.TITLE} >{PromotionScreens.MAIN_BANNER.TITLE}</PageTitle>

      <Card.Root>
        <Card.Header className='flex items-center justify-between'>
          <Card.HeaderTitle className='my-5'>
            Section Banner List
          </Card.HeaderTitle>
          <TabsComponent tabs={tabsDomain} tabActive={tab} onTabChange={handleTabChange} />
        </Card.Header>
        <div className='w-full'>
          <Switch>
            <Route
              path={SectionBannerTab.ONGOING_BANNER.PATH}
              render={(props) => <OngoingSection {...props} selectedTab={selectedTab} />}
            />
            <Route
              path={SectionBannerTab.SECTION_LIST.PATH}
              render={() => <SectionList />}
            />
          </Switch>
        </div>
      </Card.Root>

    </div>
  )

}
export default ListSectionBannerPage
