import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const GeneralConfigScreens = {
  GENERAL_CONFIG: {
    PATH: '/general-config',
    TITLE: 'General Config',
    TITLE_ALT: 'General Config',
    PERMISSION: 'general-config.get-list',
  },
  FAQ: {
    PATH: '/general-config/faq',
    HEADER_NAME: 'FAQ Setting',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'General Config',
    PERMISSION: 'faq.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  FAQ_LIST: {
    PATH: '/general-config/faq/list',
    HEADER_NAME: 'List FAQ',
    TITLE: 'General Configs',
    TITLE_ALT: 'Frequently Asked Questions Settings',
    PERMISSION: 'faq.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  FAQ_CREATE: {
    PATH: '/general-config/faq/list/create',
    HEADER_NAME: 'General Configs',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'Create Frequently Asked Questions',
    PERMISSION: 'faq.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  FAQ_DETAIL: {
    PATH: '/general-config/faq/list/detail/:id',
    HEADER_NAME: 'General Configs',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'Detail Frequently Asked Questions',
    PERMISSION: 'faq.view-detail',
    buildPath: (id: string) => `/general-config/faq/list/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_FAQ: {
    PATH: '/general-config/faq/list/edit/:id',
    HEADER_NAME: 'General Configs',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'Edit Frequently Asked Questions',
    PERMISSION: 'faq.update',
    buildPath: (id: string) => `/general-config/faq/list/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  TNC: {
    PATH: '/general-config/tnc',
    HEADER_NAME: 'Terms & Conditions Setting',
    TITLE: 'Terms & Conditions Setting',
    TITLE_ALT: 'Terms & Conditions Setting',
    PERMISSION: 'tnc.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_TNC: {
    PATH: '/general-config/tnc/edit',
    HEADER_NAME: 'Edit Terms & Conditions',
    TITLE: 'Edit Terms & Conditions',
    TITLE_ALT: 'Edit Terms & Conditions',
    PERMISSION: 'tnc.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRIVACY_POLICY: {
    PATH: '/general-config/privacy-policy',
    HEADER_NAME: 'Privacy Policy',
    TITLE: 'Privacy Policy',
    TITLE_ALT: 'Privacy Policy',
    PERMISSION: 'privacy-policy.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_PRIVACY_POLICY: {
    PATH: '/general-config/privacy-policy/edit',
    HEADER_NAME: 'Edit Privacy Policy',
    TITLE: 'Edit Privacy Policy',
    TITLE_ALT: 'Edit Privacy Policy',
    PERMISSION: 'privacy-policy.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTIFICATION: {
    PATH: '/general-config/push-notification',
    HEADER_NAME: 'Push Notification',
    TITLE: 'Push Notification',
    TITLE_ALT: 'Notification List',
    PERMISSION: 'push-notification.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTIFICATION_LIST: {
    PATH: '/general-config/push-notification/list/:tab',
    BASE_PATH: '/general-config/push-notification/list/',
    BROADCAST_PATH: '/general-config/push-notification/list/broadcast',
    HEADER_NAME: 'Notification List',
    TITLE: 'Notification List',
    TITLE_ALT: 'Notification List',
    PERMISSION: 'push-notification.get-list',
    buildPath: (tab: string) => `/promotion-management/section-banner/list/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTIFICATION_ADD: {
    PATH: '/general-config/push-notification/add',
    HEADER_NAME: 'Create New Push Notification',
    TITLE: 'Create New Push Notification',
    TITLE_ALT: 'Create New Push Notification',
    PERMISSION: 'push-notification.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTIFICATION_DETAIL: {
    PATH: '/general-config/push-notification/detail/:id/:type',
    BASE_PATH: '/general-config/push-notification/detail',
    HEADER_NAME: 'View Detail Notification',
    TITLE: 'View Detail Notification',
    TITLE_ALT: 'Detail Notification',
    buildPath: (id: string, type: string) => `/general-config/push-notification/detail/${id}/${type}`,
    PERMISSION: 'push-notification.view-detail',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTIFICATION_EDIT: {
    PATH: '/general-config/push-notification/edit/:id',
    HEADER_NAME: 'Edit Notification',
    TITLE: 'Edit Notification',
    TITLE_ALT: 'Edit Notification',
    PERMISSION: 'push-notification.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTIFICATION_DETAIL_CUSTOMER: {
    PATH: '/general-config/push-notification/customer/detail/:id',
    HEADER_NAME: 'General Config',
    TITLE: 'View Detail Customer Push Notification',
    TITLE_ALT: 'Detail Notification',
    PERMISSION: 'push-notification.view-detail',
    buildPath: (id: string) => `/general-config/push-notification/customer/detail/${id}`,
  },
  PUSH_NOTIFICATION_ACTIVITY_EDIT: {
    PATH: '/general-config/push-notification-activity/edit/:id',
    HEADER_NAME: 'Edit Notification',
    TITLE: 'Edit Notification',
    TITLE_ALT: 'Edit Notification',
    PERMISSION: 'push-notification.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  VERSION_MANAGEMENT: {
    PATH: '/general-config/version-management',
    HEADER_NAME: 'Version History',
    TITLE: 'Version Management',
    TITLE_ALT: 'Version Management',
    PERMISSION: 'version-management.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  VERSION_DETAIL: {
    PATH: '/general-config/version-management/detail/:id',
    HEADER_NAME: 'General Configs',
    TITLE: 'Detail Version',
    TITLE_ALT: 'Version Management',
    PERMISSION: 'version-management.view-detail',
    buildPath: (id: string) => `/general-config/version-management/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  VERSION_EDIT: {
    PATH: '/general-config/version-management/update/:id',
    PATH_IOS: '/general-config/version-management/update/ios',
    PATH_ANDROID: '/general-config/version-management/update/android',
    HEADER_NAME: 'General Configs',
    TITLE: 'Minimum Version',
    TITLE_ALT: 'Version Management',
    PERMISSION: 'version-management.update',
    buildPath: (id: string) => `/general-config/version-management/update/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
}

const listBreadcrumbs = generateBreadcrumb([GeneralConfigScreens.GENERAL_CONFIG])

GeneralConfigScreens.FAQ.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.TNC.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.EDIT_FAQ.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.EDIT_TNC.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.PRIVACY_POLICY.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.EDIT_PRIVACY_POLICY.breadcrumbs = () => listBreadcrumbs

export default GeneralConfigScreens
