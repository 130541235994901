import {ChangeEvent, FC, useCallback} from 'react'
import {FormText, FormTextProps} from '../partials'

interface FormPercentageProps extends FormTextProps {
  value: string
  onChangeValue: (value: string, first?: boolean) => void
}

const FormPercentage: FC<FormPercentageProps> = ({value, onChangeValue, onChange, ...rest}) => {
  const setValue = useCallback(
    (trimmed: string, first?: boolean) => {
      if (onChangeValue) onChangeValue(trimmed, first)
    },
    [onChangeValue]
  )

  const onChangeWrapper = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value.replace(/\D/g, ''))
    },
    [setValue]
  )

  return <FormText value={value} onChange={onChangeWrapper} suffix='%' {...rest} />
}

export {FormPercentage}
