import clsx from 'clsx'
import {FC} from 'react'
import VIcon from '../../../VIcon/VIcon'
import {VFormLabelProps} from './VFormLabel.models'

const VFormLabel: FC<VFormLabelProps> = ({children, required, appendIcon, appendClassname, description, className = ''}, iconType = 'base') => {
  return (
    <div className={clsx('flex flex-col', className)} data-testid='form-label-container'>
      <label className='flex-grow font-medium text-fs-9 text-neutral-80'>
        {children}
        {required && <span className='ml-1 text-danger'>*</span>}
        {appendIcon && (
          <VIcon
            icon={appendIcon as any}
            type={iconType}
            className={clsx('cursor-pointer mr-2 w-5 h-5', appendClassname)}
          />
        )}
      </label>

      {description && (
        <div className='flex items-center mt-1'>
          <label className='text-fs-10 text-neutral-70'>{description}</label>
        </div>
      )}
    </div>
  )
}

export {VFormLabel}
