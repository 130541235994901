import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormLabelProps} from './Label.types'
import GIcon from '../../../Icon/GIcon'
import {VTooltip} from 'src/app/components/Libs_v2'

export const FormLabel: FC<FormLabelProps> = ({
  children,
  required,
  optional,
  appendIcon,
  appendClassname,
  clickAppendIcon,
  description,
  className,
  typeContent = 'email',
  styleTitle = 'normal',
  position = 'bottom-start',
  noArrow = true

}) => {
  return (
    <div>
      <div className={cn('flex flex-col', className)} data-testid='form-label-container'>
        <label className={cn('flex flex-row font-medium text-fs-9 text-neutral-100', {'font-bold': styleTitle === 'bold'})}>
          {children}
          {required && <span className='ml-1 text-danger'>*</span>}
          {optional && <span className='ml-1 text-neutral-70'>(Opsional)</span>}
          {appendIcon && (
            <div data-tooltip-id={`MultilineBlackTooltip${children}`} data-place="bottom" className={cn('cursor-pointer ml-2 w-5 h-5', appendClassname)}>
              <GIcon
                icon={appendIcon} 
                onClick={clickAppendIcon}
              />
            </div>
          )}
        </label>

        {description && (
          <div className='flex items-center mt-1'>
            <label className='text-fs-10 text-neutral-70'>{description}</label>
          </div>
        )}

      </div>
        <VTooltip tooltipId={`MultilineBlackTooltip${children}`} variant='DARK' position={position} noArrow={noArrow} typeContent={typeContent}>
        </VTooltip>
    </div>
  )
}
