import clsx from 'clsx'
import React, { Children } from 'react'
import { useLocation } from 'react-router'
import { useMasterLayout } from 'src/app/context/MasterContext'
import { checkIsActive } from 'src/app/utils/router-helpers-utils'
import { KTSVG } from '../../Libs/Icon/KTSVG'
import BulletItem from './BulletItem'
import DisabledMenu from './DisabledMenu'

type Props = {
  children?: any
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activePath?: string
  disabled?: boolean
  isChildren?: boolean
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  disabled = false,
  isChildren,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { toggleActiveSidebar, isSidebarOpen, acitveSidebar, minimize } = useMasterLayout()

  const allChildren = Children.toArray(children)

  let countArray = allChildren.length
  // let newMaxHeight = `max-h-[${3 * countArray + 3}rem]`

  allChildren.forEach((child: any) => {
    const { props } = child

    if (props.menu?.children !== undefined) {
      const menuLength = props.menu.children.length
      countArray += menuLength
    }
  })

  return (
    <div className='relative'>
      {disabled && <DisabledMenu />}

      <div
        className={clsx('p-0 block overflow-hidden text-fs-9 relative', {
          'overflow-visible': to,
        })}
        onClick={() => toggleActiveSidebar?.(to)}
      >
        <span
          className={clsx(
            'bg-none p-3 mx-3 cursor-pointer flex items-center',
            isChildren ? "ml-8 mr-3" : "mx-3",
            {
              'text-white': isSidebarOpen,
              'text-neutral-10': !isSidebarOpen,
            }
          )}
        >
          {hasBullet && <BulletItem isActive={isActive} />}
          {icon && (
            <span className='flex items-center w-8 mr-2 shrink-0'>
              <KTSVG path={icon} className='w-6 h-6' />
            </span>
          )}
          {/* still bootstrap style / change it !  */}
          {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
          <span
            className={clsx('truncate flex items-center flex-1 transition-all duration-300', {
              'opacity-0 group-hover:opacity-100': minimize,
            })}
          >
            {title}
          </span>
          <span
            className={clsx(
              'flex items-stretch relative overflow-hidden ml-2 w-[0.8rem] h-[0.8rem] transition ease-in',
              'after:block after:content-[""] after:w-full after:bg-[url("/public/media/icons/IconChevronRight.svg")] after:bg-center after:bg-no-repeat',
              { 'rotate-90': acitveSidebar === to }
            )}
          ></span>
        </span>
        <div
          onClick={(e) => e.stopPropagation()}
          className={clsx(
            `ml-2 pl-3 rounded-lg transition-all overflow-hidden duration-300 ease-in`,
            { 'max-h-0 ': acitveSidebar !== to },
            { 'ml-0 mr-0 pl-0 group-hover:ml-2 group-hover:pl-3': minimize }
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export { AsideMenuItemWithSub }

