import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {
  VIconBank,
  VIconBase,
  VIconSWMBroken,
  VIconSWMCurved,
  VIconSWMDuotone,
  VIconSWMOutline,
} from './Collection'
import {VIconProps} from './Icon'

const VIcon: FC<VIconProps> = ({icon, className, onClick, type}) => {
  let src

  if (type === 'base') src = toAbsoluteUrl(VIconBase[icon])
  if (type === 'bank') src = toAbsoluteUrl(VIconBank[icon])
  if (type === 'swm-broken') src = toAbsoluteUrl(VIconSWMBroken[icon])
  if (type === 'swm-curved') src = toAbsoluteUrl(VIconSWMCurved[icon])
  if (type === 'swm-duotone') src = toAbsoluteUrl(VIconSWMDuotone[icon])
  if (type === 'swm-outline') src = toAbsoluteUrl(VIconSWMOutline[icon])

  return <InlineSVG src={src as string} className={className} onClick={onClick} />
}

export default VIcon
