import {FC} from 'react'
import GIcon from '../Icon/GIcon'

interface TableErrorProps {
  onClick?: any
}

const TableError: FC<TableErrorProps> = ({onClick}) => {
  const reload = () => window.location.reload()

  return (
    <div className='min-h-[196px] flex items-center justify-center flex-col'>
      <div className='font-medium text-fs-5'>
        <GIcon
          icon='IconRefresh'
          className='w-24 h-24 cursor-pointer text-primary'
          onClick={onClick ?? reload}
        />
      </div>
      <div className='mt-5 text-neutral-80'>The content can't be loaded. Click the icon reload</div>
    </div>
  )
}

export default TableError
