import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import {HeaderToast} from 'src/app/components/Libs'
import {HeaderToastProvider} from 'src/app/context/ToastContext'
import AuthScreens from './Screens'

const AuthRoutes: FC = () => {
  return (
    <HeaderToastProvider>
      <HeaderToast variant='FULL' />
      <Switch>
        <Route
          path={AuthScreens.INVALID_TOKEN.PATH}
          component={AuthScreens.INVALID_TOKEN.COMPONENT}
        />
      </Switch>
    </HeaderToastProvider>
  )
}

export default AuthRoutes
