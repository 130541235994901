import { ChangeEvent, useCallback, useMemo } from "react"
import { InputCheckboxProps } from "src/app/components/Libs"

export const useFilterCheckbox = (formik: any, value: string, placeholder: string, filterData: any = []) => {
  const handleCheckboxAll = useCallback((isChecked: boolean) => {
    if (!formik?.values) return;
    formik.setValues({
      ...formik.values,
      [value]: isChecked ? filterData.map((data: any) => data.id) : [],
    });
  }, [formik, filterData]);

  const handleOtherCheckboxChange = useCallback((checked: boolean, id: number) => {
    if (!formik?.values) return;
    const values = formik.values[value] || [];
    const updatedValues = checked
      ? [...values, id]
      : values.filter((valueId: any) => valueId !== id);
    formik.setFieldValue(value, updatedValues);
  }, [formik]);

  return useMemo(() => {
    if (!formik?.values || !filterData) return [];

    const allCheckboxProps = {
      type: 'checkbox',
      variant: 'classic',
      value: 'all',
      name: 'all',
      placeholder,
      activeValue: 'all',
      inactiveValue: undefined,
      checkedValue: formik.values[value].length === filterData.length ? 'all' : '',
      onChange: (e: ChangeEvent<HTMLInputElement>) => handleCheckboxAll(e.target.checked),
    };

    const otherCheckboxProps = filterData.map((data: any) => ({
      type: 'checkbox',
      variant: 'classic',
      value: `${value}[${data.id}]`,
      name: `${value}[${data.id}]`,
      placeholder: data.name,
      activeValue: data.id,
      inactiveValue: undefined,
      checkedValue: formik.values[value].includes(data.id) ? data.id : undefined,
      onChange: (e: ChangeEvent<HTMLInputElement>) => handleOtherCheckboxChange(e.target.checked, data.id),
    }));

    return [allCheckboxProps, ...otherCheckboxProps] as InputCheckboxProps[];
  }, [formik.values, handleCheckboxAll, handleOtherCheckboxChange, filterData, value]);
}