import {FC, MouseEventHandler} from 'react'
import {GButton} from '../Button/Button.component'

interface TableNotFoundSearch2Props {
  onClick?: MouseEventHandler
}

const TableNotFoundSearch2: FC<TableNotFoundSearch2Props> = ({onClick}) => {
  return (
    <div className='min-h-[196px] flex items-center justify-center flex-col'>
      <div className='font-medium text-fs-5'>Result not found</div>
      <div className='mt-3 text-neutral-80'>Enter new keywords or turning off some filters.</div>
      <div className='mt-10'>
        <GButton size='small' variant='OUTLINED' onClick={onClick}>
          Reset Filter
        </GButton>
      </div>
    </div>
  )
}

export default TableNotFoundSearch2
