import { FC, MouseEventHandler, useEffect, useState } from 'react'

interface Tab {
  label: string;
  value: any;
}
interface Props {
  tabs: any
  tabActive: any
  onTabChange: (value: any) => void;
}

interface PropsTab {
  label: string
  isActive: boolean
  onClick: MouseEventHandler
}

const Tab: FC<PropsTab> = ({ label, isActive, onClick }) => {
  return (
    <li
      role="tab"
      className={`flex items-center justify-center text-center  w-full h-full relative bg-transparent py-2 px-5 antialiased text-base font-bold leading-relaxed select-none cursor-pointer ${isActive ? 'bg-opacity-100 text-primary' : 'text-neutral-50'}`}
      onClick={onClick}
      style={{ whiteSpace: 'nowrap' }}
    >
      <div className="z-20 text-fs-8 font-semibold">{label}</div>
      {isActive && <div className="absolute inset-0 z-10 h-full bg-white rounded-xl text-nowrap shadow" style={{ transform: 'none', transformOrigin: '50% 50% 0px' }}></div>}
    </li>
  );
};

const TabsComponent: FC<Props> = ({ tabs, tabActive = 'featured', onTabChange }) => {
  const [activeTab, setActiveTab] = useState(tabActive)

  const handleTabClick = (value: any) => {
    setActiveTab(value)
    onTabChange(value)
  }

  useEffect(() => {
    setActiveTab(tabActive)
  }, [tabActive])

  return (
    <nav>
      <ul role="tablist" className="flex relative bg-neutral-10 rounded-2xl p-1 flex-row">
        {tabs.map((tab: Tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            isActive={activeTab === tab.value}
            onClick={() => handleTabClick(tab.value)}
          />
        ))}
      </ul>
    </nav>
  );
}

export default TabsComponent
