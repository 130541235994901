import React, { useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { useTable } from "react-table";
import { DraggableTableRow } from "./DraggableTableRow";
import { StaticTableRow } from "./StaticTableRow";

interface TableProps {
  columns: any[]
  data: any[]
  setData: React.Dispatch<React.SetStateAction<any[]>>
  onReorderData?: (data: any[]) => void 
}

export function DraggableTable({ columns, data, setData, onReorderData }: TableProps) {
  const [activeId, setActiveId] = useState<string | null>(null);
  const items = useMemo(() => data?.map(({ id }) => id), [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setData((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over?.id || '');
        const result = arrayMove(data, oldIndex, newIndex);
        if (onReorderData) onReorderData(result);
        return result;
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    if (row) prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <table {...getTableProps()} className="w-full table text-left text-neutral-50">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-neutral-30">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className={`p-4 text-sm font-bold uppercase text-${column.render("align") ?? "left"}`}
                style={{ width: column.width, maxWidth: column.width }}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {rows.map((row) => {
              prepareRow(row);
              return <DraggableTableRow key={row.id} row={row} />;
            })}
          </SortableContext>
        </tbody>
      </table>
      <DragOverlay>
        {activeId && (
          <table className="w-full table text-neutral-50 border-b border-neutral-30 bg-neutral-10">
            <tbody>
              {selectedRow && <StaticTableRow row={selectedRow} />}
            </tbody>
          </table>
        )}
      </DragOverlay>
    </DndContext>
  );
}
