import React from 'react'
import {Order} from 'src/app/models/api.types'
import GIcon, {GIconProps} from '../Icon/GIcon'

interface Props {
  trigger: (name: string) => any
  name: string
  currentOrder?: Order
  currentSort?: string
}

const getIconSrc = (name: string, currentSort?: string, currentOrder?: Order): GIconProps => {
  if (name !== currentSort) return 'IconUpDown'
  if (currentOrder === 'ASC') return 'IconDown'
  return 'IconUp'
}

const IconSort: React.FC<Props> = ({name, trigger, currentSort, currentOrder}) => (
  <span className='cursor-pointer' onClick={() => trigger(name)}>
    <GIcon icon={getIconSrc(name, currentSort, currentOrder)} />
  </span>
)

export default IconSort
