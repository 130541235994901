import {FC} from 'react'
import GIcon from '../Icon/GIcon'

const TableLoading: FC = () => {
  return (
    <div className='min-h-[256px] flex items-center justify-center flex-col'>
      <div>
        <GIcon icon='IconLoading' className='mb-4 w-14 h-14 animate-spin text-primary' />
      </div>
      <div className='text-fs-7 text-neutral-80'>Please wait a moment...</div>
    </div>
  )
}

export default TableLoading
