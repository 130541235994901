export enum VIconBank {
  'BankAGI' = '/media/icons_v2/bank/BankAGI.svg',
  'BankAladin' = '/media/icons_v2/bank/BankAladin.svg',
  'BankBCA_1' = '/media/icons_v2/bank/BankBCA_1.svg',
  'BankBCA_2' = '/media/icons_v2/bank/BankBCA_2.svg',
  'BankBCA_Digital' = '/media/icons_v2/bank/BankBCA_Digital.svg',
  'BankBCA_Fill1' = '/media/icons_v2/bank/BankBCA_Fill1.svg',
  'BankBCA_Fill2' = '/media/icons_v2/bank/BankBCA_Fill2.svg',
  'BankBCA_Flazz1' = '/media/icons_v2/bank/BankBCA_Flazz1.svg',
  'BankBCA_Flazz2' = '/media/icons_v2/bank/BankBCA_Flazz2.svg',
  'BankBJB' = '/media/icons_v2/bank/BankBJB.svg',
  'BankBNI' = '/media/icons_v2/bank/BankBNI.svg',
  'BankBNP' = '/media/icons_v2/bank/BankBNP.svg',
  'BankBPD' = '/media/icons_v2/bank/BankBPD.svg',
  'BankBRI' = '/media/icons_v2/bank/BankBRI.svg',
  'BankBSI_Fill' = '/media/icons_v2/bank/BankBSI_Fill.svg',
  'BankBSI' = '/media/icons_v2/bank/BankBSI.svg',
  'BankBTN' = '/media/icons_v2/bank/BankBTN.svg',
  'BankBTPN' = '/media/icons_v2/bank/BankBTPN.svg',
  'BankBumiArta' = '/media/icons_v2/bank/BankBumiArta.svg',
  'BankCapital' = '/media/icons_v2/bank/BankCapital.svg',
  'BankCIMB' = '/media/icons_v2/bank/BankCIMB.svg',
  'BankCommonwealth' = '/media/icons_v2/bank/BankCommonwealth.svg',
  'BankDanamon' = '/media/icons_v2/bank/BankDanamon.svg',
  'BankDKI' = '/media/icons_v2/bank/BankDKI.svg',
  'BankHana' = '/media/icons_v2/bank/BankHana.svg',
  'BankHSBC' = '/media/icons_v2/bank/BankHSBC.svg',
  'BankJago_Fill' = '/media/icons_v2/bank/BankJago_Fill.svg',
  'BankJago' = '/media/icons_v2/bank/BankJago.svg',
  'BankJateng' = '/media/icons_v2/bank/BankJateng.svg',
  'BankJatim' = '/media/icons_v2/bank/BankJatim.svg',
  'BankJenius_Business_Fill' = '/media/icons_v2/bank/BankJenius_Business_Fill.svg',
  'BankJenius_Business' = '/media/icons_v2/bank/BankJenius_Business.svg',
  'BankJenius_Fill' = '/media/icons_v2/bank/BankJenius_Fill.svg',
  'BankJenius_Personal' = '/media/icons_v2/bank/BankJenius_Personal.svg',
  'BankJTrust' = '/media/icons_v2/bank/BankJTrust.svg',
  'BankKBBukopin' = '/media/icons_v2/bank/BankKBBukopin.svg',
  'BankMandiri_emoney' = '/media/icons_v2/bank/BankMandiri_emoney.svg',
  'BankMandiri_Fill' = '/media/icons_v2/bank/BankMandiri_Fill.svg',
  'BankMandiri_LinkAka' = '/media/icons_v2/bank/BankMandiri_LinkAka.svg',
  'BankMandiri_Livin_Fill' = '/media/icons_v2/bank/BankMandiri_Livin_Fill.svg',
  'BankMandiri_Livin' = '/media/icons_v2/bank/BankMandiri_Livin.svg',
  'BankMandiri' = '/media/icons_v2/bank/BankMandiri.svg',
  'BankMaspion' = '/media/icons_v2/bank/BankMaspion.svg',
  'BankMaybank' = '/media/icons_v2/bank/BankMaybank.svg',
  'BankMayora' = '/media/icons_v2/bank/BankMayora.svg',
  'BankMega' = '/media/icons_v2/bank/BankMega.svg',
  'BankMNC' = '/media/icons_v2/bank/BankMNC.svg',
  'BankMuamalat' = '/media/icons_v2/bank/BankMuamalat.svg',
  'BankOCBCNISP' = '/media/icons_v2/bank/BankOCBCNISP.svg',
  'BankPanin' = '/media/icons_v2/bank/BankPanin.svg',
  'BankPermata_Fill' = '/media/icons_v2/bank/BankPermata_Fill.svg',
  'BankPermata' = '/media/icons_v2/bank/BankPermata.svg',
  'BankSeabank' = '/media/icons_v2/bank/BankSeabank.svg',
  'BankSinarmas_Fill' = '/media/icons_v2/bank/BankSinarmas_Fill.svg',
  'BankSinarmas' = '/media/icons_v2/bank/BankSinarmas.svg',
  'BankUOB' = '/media/icons_v2/bank/BankUOB.svg',
}
