import {FC} from 'react'
import ReactStars from 'react-stars'

interface ReactStarsProps {
  className?: string
  count?: number
  value?: number
  char?: string
  color1?: string
  color2?: string
  size?: number
  edit?: boolean
  half?: boolean
  onChange?: (new_rating: number) => void
}

const VStars: FC<ReactStarsProps> = ({size = 24, color2 = '#DEAC39', count = 5, ...props}) => {
  return <ReactStars count={count} size={size} color2={color2} {...props} />
}

export default VStars
