export const StatusType = [
  {
    id: 'ACTIVE',
    name: 'Active'
  },
  {
    id: 'INACTIVE',
    name: 'Inactive'
  }
]

export const StatusCustomerType = [
  {
    id: 'ACTIVE',
    name: 'Active'
  },
  {
    id: 'INACTIVE',
    name: 'Inactive'
  },
  {
    id: 'SUSPEND',
    name: 'Suspended'
  },
  {
    id: 'DELETED',
    name: 'Deleted Account'
  }
]

export const StatusAllPlatform = [
  {
    id: 'app',
    name: 'KFCKu Apps'
  },
  {
    id: 'order',
    name: 'order.kfcku.com'
  },
  {
    id: 'web',
    name: 'KFCku.com'
  }
]

export const StatusBanner = [
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'inactive',
    name: 'Inactive'
  },
  {
    id: 'expired',
    name: 'Expired'
  }
]

export const StatusBooleanType = [
  {
    id: 1,
    name: 'Active'
  },
  {
    id: 0,
    name: 'Inactive'
  }
]

export const gamificationTypeList = [
  {
    name: 'Daily Check-In',
    id: 'DAILY_CHECK_IN',
  },
  {
    name: 'Collect Stamp',
    id: 'COLLECT_STAMP',
  },
  {
    name: 'Spin The Wheel',
    id: 'SPIN_THE_WHEEL',
  },
]

export const StatusBestSeller = [
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'inactive',
    name: 'Inactive'
  },
  {
    id: 'expired',
    name: 'Expired'
  }
]

export const StatusQualification = [
  {
    id: 'ACTIVE',
    name: 'Active'
  },
  {
    id: 'SCHEDULED',
    name: 'Scheduled'
  },
  {
    id: 'INACTIVE',
    name: 'Inactive'
  },
  
]

export const AdminType = [
  {
    id: 1,
    name: 'Need Approval'
  },
  {
    id: 0,
    name: 'No Need Approval'
  }
]

export const TierList = [
  {
    id: 'BRONZE',
    name: 'Bronze'
  },
  {
    id: 'SILVER',
    name: 'Silver'
  },
  {
    id: 'GOLD',
    name: 'Gold'
  },
  {
    id: 'PLATINUM',
    name: 'Platinum'
  },
]

export const PlatformList = [
  {
    id: 'Android',
    name: 'ANDROID'
  },
  {
    id: 'iOS',
    name: 'IOS'
  },
  {
    id: 'Web',
    name: 'WEB'
  },
]

export const VoucherType = [
  {
    id: 'general',
    name: 'Voucher General'
  },
  {
    id: 'unique',
    name: 'Unique Code'
  },
  {
    id: 'coupon',
    name: 'Coupon Code'
  },
]

export const PlatformType = [
  {
    id: 'mobile',
    name: 'Mobile'
  },
  {
    id: 'web',
    name: 'Web Order'
  }
]

export const TargetCustomerType = [
  {
    id: 'guest',
    name: 'Guest'
  },
  {
    id: 'birthday',
    name: 'Birthday'
  },
  {
    id: 'new',
    name: 'New Register'
  },
  {
    id: 'profile_completed',
    name: 'Profile Completed'
  },
  {
    id: 'register',
    name: 'Member'
  },
  {
    id: 'selected_customer',
    name: 'Selected Customer'
  },
  {
    id: 'loyalty',
    name: 'Loyalty'
  }
]


export const OrderStatuses = [
  {
    id: 'WAITING_FOR_PAYMENT',
    name: 'Waiting for Payment'
  },
  {
    id: 'PREPARING',
    name: 'Being Prepared'
  },
  {
    id: 'ON_DELIVERY',
    name: 'Delivered'
  },
  {
    id: 'COMPLETED',
    name: 'Completed'
  },
  {
    id: 'CANCELED',
    name: 'Cancelled'
  },
  {
    id: 'EXPIRED',
    name: 'Expired'
  }
]

export const Channels = [
  {
    id: 'web',
    name: 'Web'
  },
  {
    id: 'mobile',
    name: 'Mobile Apps'
  }
]

export const OrderTypes = [
  {
    id: 'DIN',
    name: 'Dine In'
  },
  {
    id: 'TKA',
    name: 'Take Away'
  },
  {
    id: 'DRT',
    name: 'Drive Thru'
  },
  {
    id: 'HMD',
    name: 'Home Delivery'
  },
  {
    id: 'PNP',
    name: 'Pick and Pay'
  },
]