import { ReactNode } from 'react';
import { components, ControlProps, GroupBase, NoticeProps, OptionProps, SelectComponentsConfig } from 'react-select';
import { twMerge } from 'tailwind-merge';
import GIcon from '../../../Icon/GIcon';
import { IndicatorSeparator } from '../../Custom/CustomReactSelect';
import { FormCheckbox } from '../Checkbox';
import { useMultiSelectContext } from './MultiSelectAll.context';

function Control<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: Readonly<ControlProps<Option, IsMulti, Group>>) {
  return (
    <components.Control
      className={twMerge(
        "shadow-none bg-neutral-10 min-h-[44px]",
        props.isFocused && "focus",
        props.isDisabled && "disabled"
      )}
      {...props}
    >
      <GIcon
        className="ml-4 medium"
        icon="IconSearch"
      />
      {children}
    </components.Control>
  );
}

const Option = (props: OptionProps<any>) => {

  const context = useMultiSelectContext();
  const isChecked = context.getSelectedOption(props.data)

  return (
    <components.Option
      {...props}
      isSelected={isChecked}
      className={twMerge(
        props.data.sectionGroup && 'pl-8',
        props.data?.disabled && 'cursor-not-allowed text-neutral-50'
      )}
    >
      <div className={'flex flex-row items-center'}>
        <div className='flex flex-row items-center flex-1'>
          {(!!props.data?.image) && (
            <img
              className='w-[32px] rounded-full mr-3'
              src={props.data?.image}
              alt={props.label}
            />
          )}
          {props.label}
        </div>
        <FormCheckbox
          disabled={props.data?.disabled}
          variant='classic'
          checked={isChecked}
          className='ml-4 '
          height='min-h-0'
        />
      </div>
    </components.Option>
  );
}

function NoOptionsAction(children: ReactNode) {
  return (props: NoticeProps<any, boolean, any>) => (
    <components.NoOptionsMessage {...props}>
      {children || <div className='font-medium text-start'>Result not found</div>}
    </components.NoOptionsMessage>
  );
}

interface CustomMultiSelectAllProps {
  withInputSearch?: boolean
  noOptionsComponent?: ReactNode
}

export function CustomMultiSelectAll(props: CustomMultiSelectAllProps): SelectComponentsConfig<any, boolean, any> {
  return {
    GroupHeading: () => null,
    Control: props.withInputSearch ? Control : () => null,
    Option,
    IndicatorSeparator,
    NoOptionsMessage: NoOptionsAction(props.noOptionsComponent)
  };
}
