import clsx from 'clsx'
import {FC} from 'react'
import {
  VCheckboxItemProps,
  VDotSizes,
  VFormCheckboxProps,
  VSwitchSizes,
} from './VFormCheckbox.models'

const ClassicCheckbox: FC<VCheckboxItemProps> = ({placeholder, ...rest}) => {
  return (
    <div className='flex items-center flex-1'>
      <label className='flex items-center'>
        <div>
          <input
            className='checked:bg-primary-500 focus:ring focus:ring-primary-200'
            type='checkbox'
            {...rest}
          />
        </div>
        {placeholder && (
          <div className='ml-4 font-medium text-neutral-80 text-fs-9'>{placeholder}</div>
        )}
      </label>
    </div>
  )
}

const SwitchCheckbox: FC<VCheckboxItemProps> = ({placeholder, sizebox = 'normal', ...rest}) => {
  return (
    <div className='flex items-center flex-1'>
      <label className='flex items-center'>
        <div className='flex items-center cursor-pointer'>
          <div className='relative'>
            <input type='checkbox' className='sr-only' {...rest} />
            <div
              className={clsx('switched bg-neutral-40 rounded-full', VSwitchSizes[sizebox])}
            ></div>
            <div
              className={clsx(
                'dot absolute left-[2px] top-[2px] bg-white rounded-full transition duration-200 ',
                VDotSizes[sizebox]
              )}
            ></div>
          </div>
        </div>
        {placeholder && (
          <div className='ml-4 font-medium text-neutral-80 text-fs-9'>{placeholder}</div>
        )}
      </label>
    </div>
  )
}

export const VFormCheckbox: FC<VFormCheckboxProps> = ({
  className,
  error,
  touched,
  placeholder,
  variant = 'switch',
  checkedValue,
  changedValue,
  activeValue,
  inactiveValue,
  ...rest
}) => {
  return (
    <div className={clsx(className, 'min-h-[44px] flex items-center')}>
      {variant === 'classic' && (
        <ClassicCheckbox
          placeholder={placeholder}
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}
      {variant === 'switch' && (
        <SwitchCheckbox
          placeholder={placeholder}
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}

      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}
