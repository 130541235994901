import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from 'src/setup'
import MasterLayout from '../components/Layout/MasterLayout'
import AuthRoutes from '../modules/auth/AuthRoutes'
import {Logout} from '../modules/auth/page/Logout/Logout'
import Error404 from '../modules/error/error404'
import {PrivateRoutes} from './PrivateRoutes'
import InvalidRoutes from '../modules/auth/InvalidRoutes'

const Routes: FC = () => {
  const accessToken: any = useSelector<RootState>(
    ({auth}) => Boolean(auth.accessToken),
    shallowEqual
  )
  
  return (
    <Switch>
      {!accessToken ? (
        <Switch>
          <Route path='/auth'>
            <AuthRoutes />
          </Route>
          <Route path='/invalid'>
            <InvalidRoutes />
          </Route>
          <Redirect to='/auth/login-email' />
        </Switch>
      ) : (
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/logout' component={Logout} />

      {!accessToken ? (
        <Redirect to='/auth/login-email' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
          <Route path='/error' component={Error404} />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
