import {DetailedHTMLProps, InputHTMLAttributes} from 'react'

export type VariantCheckbox = 'classic' | 'switch'

export interface VFormCheckboxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string
  error?: string
  touched?: boolean
  placeholder?: string
  variant?: VariantCheckbox
  checkedValue?: boolean
  changedValue?: any
  activeValue?: string | boolean | number
  inactiveValue?: string | boolean | number
  sizebox?: SwitchSize
}

export enum VSwitchSizes {
  large = 'w-[52px] h-7',
  normal = 'w-9 h-5',
}

export enum VDotSizes {
  large = 'w-6 h-6',
  normal = 'w-4 h-4',
}

export type SwitchSize = 'large' | 'normal'

export interface VCheckboxItemProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder?: string
  sizebox?: SwitchSize
}
