import {useCallback, useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from 'src/setup'
import {ModulePermissionQuery} from '../modules/permissions/models/Role.model'
import {Admin} from '../modules/permissions/models/Admin.model'

export function usePermissions(pageQuery?: ModulePermissionQuery) {
  const history = useHistory()
  const permissions = useSelector<RootState>(
    ({auth}) => auth?.user?.role?.permissions ?? [],
    shallowEqual
  ) as ModulePermissionQuery[]
  const user: Admin = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as Admin

  const hasAccess = useCallback(
    (q?: ModulePermissionQuery) => {
      if (user?.is_superadmin) return true
      if (!user?.role?.is_active) return false

      const code = q ?? pageQuery
      if (!code) return true
      return Boolean(permissions?.find((data: any) => data?.permission_code === (q ?? pageQuery ?? '')))
    },
    [pageQuery, permissions, user]
  )

  const canAccessPage = useMemo(() => hasAccess(), [hasAccess])

  const accessRedirect = useCallback(
    (q?: ModulePermissionQuery, path?: string) => {
      if (q) {
        if (canAccessPage && hasAccess(q)) {
          return
        }
        if (path) return history.push(path)
        return history.goBack()
      }
    },
    [canAccessPage, hasAccess, history]
  )
  return {hasAccess, canAccessPage, accessRedirect}
}
