import clsx from 'clsx'
import {FC, ReactNode} from 'react'

export enum VChipCollection {
  secondary = 'bg-secondary-50 text-secondary-600',
  tertiary = 'bg-tertiary-50 text-tertiary-600',
  primary = 'bg-primary-50 text-primary-600',
  danger = 'bg-danger-50 text-danger-600',
  warning = 'bg-warning-50 text-warning-600',
  info = 'bg-info-50 text-info-600',
  success = 'bg-success-50 text-success-600',
}

export enum VChipSizes {
  sm = 'py-[2px] px-2 text-fs-10',
  md = 'py-[2px] px-[10px] text-fs-9',
  lg = 'py-1 px-4 text-fs-8',
}

export type VChipProps = keyof typeof VChipCollection

type Props = {
  children: ReactNode
  variant?: VChipProps
  uppercase?: boolean
  className?: string
  sizes?: 'sm' | 'md' | 'lg'
}

const VChip: FC<Props> = ({
  children,
  variant = 'primary',
  uppercase,
  className = '',
  sizes = 'md',
}) => {
  return (
    <span
      data-testid='v-chip-test'
      className={clsx(
        VChipCollection[variant],
        VChipSizes[sizes],
        'flex items-center font-semibold rounded-lg',
        className,
        {uppercase}
      )}
    >
      {children}
    </span>
  )
}

export default VChip
