import clsx from 'clsx'
import {ButtonHTMLAttributes, FC} from 'react'
import {Link} from 'react-router-dom'
import {VIconProps} from '../VIcon/Icon'
import VIcon from '../VIcon/VIcon'

export type VIconButtonSize = 'tiny' | 'small' | 'medium' | 'large' | 'giant'
export type VIconButtonVariant = 'filled' | 'outline' | 'ghost'
export type VIconButtonColor =
  | 'primary'
  | 'danger'
  | 'warning'
  | 'info'
  | 'success'
  | 'secondary'
  | 'tertiary'

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconProps: VIconProps
  size?: VIconButtonSize
  variant?: VIconButtonVariant
  color?: VIconButtonColor
}

export enum VIconButtonSizes {
  tiny = 'v-icon-button-tiny',
  small = 'v-icon-button-small',
  medium = 'v-icon-button-medium',
  large = 'v-icon-button-large',
  giant = 'v-icon-button-giant',
}

export enum IconSizes {
  tiny = 'w-4 h-4',
  small = 'w-5 h-5',
  medium = 'w-6 h-6',
  large = 'w-7 h-7',
  giant = 'w-8 h-8',
}

export interface VIconButtonProps extends IconButtonProps {
  href?: string
}

const IconButton: FC<IconButtonProps> = ({
  className,
  size = 'medium',
  variant = 'filled',
  color = 'primary',
  iconProps,
  children,
  ...rest
}) => {
  return (
    <button
      className={clsx(
        'v-icon-button',
        `v-icon-button-${variant}-${color}`,
        VIconButtonSizes[size],
        className
      )}
      {...rest}
    >
      <div className='flex items-center justify-center'>
        <VIcon className={clsx(IconSizes[size], iconProps.className)} {...iconProps} />
      </div>
    </button>
  )
}

const VIconButton: FC<VIconButtonProps> = ({children, href, ...rest}) => {
  if (href) {
    return (
      <Link to={href}>
        <IconButton {...rest}>{children}</IconButton>
      </Link>
    )
  } else {
    return <IconButton {...rest}>{children}</IconButton>
  }
}

export default VIconButton
