export type BaseResponse<T> = {
  response_schema?: {
    response_code?: string
    response_message?: string
  }
  response_output?: T
}

export type PaginateModel = {
  page?: number
  total_rows?: number
  size?: number
  limit?: number
  total_pages?: number
  rows_per_page?: number
  total?: number
}

export type Pagination<T> = {
  list?: {
    content?: T
    pagination?: PaginateModel
  }
}
export type PaginationMeta<T, U> = {
  list?: {
    content?: T
    meta?: U
    pagination?: PaginateModel
  }
}

export type Response<T> = {
  detail?: T
}

export type Constraint = {
  code?: string
  message?: string
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum OrderStatus {
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  DELIVERED = 'DELIVERED',
  BEING_PREPARED = 'BEING PREPARED',
  WAITING_FOR_PAYMENT = 'WAITING FOR PAYMENT',
}

export const StatusName: Record<string, string> = {
  [Status.ACTIVE]: 'Active',
  [Status.INACTIVE]: 'Inactive',
}

export type QueryModel = {
  page?: number
  limit?: number
  size?: number
  search?: string
  is_active?: number[]
  role_ids?: string[]
  order?: Order
  sort?: string
  start_date?: string
  end_date?: string
  action?: string
  outlet_code?: string
  outlet_codes?: string[]
}
