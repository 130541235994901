import clsx from 'clsx'
import React, { useEffect, useMemo } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { twMerge } from 'tailwind-merge'
import GIcon from '../../Icon/GIcon'
import { GIconButton } from '../../IconButton/IconButton.component'

export interface FormUploadImageProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void
  onClear: () => void
  preview: string | undefined
  title: string
  message: string
  disabled?: boolean
  required?: boolean
  error?: string
  touched?: boolean
  formik?: any
  desc?: boolean
  className?: string
  actionButtonPlacement?: 'left' | 'right' | 'center'
  acceptFile?: Array<string>
  loading?: boolean
}

const FormUploadImage: React.FC<FormUploadImageProps> = ({
  onDrop,
  onClear,
  preview,
  title,
  message,
  disabled,
  required,
  error,
  touched,
  formik,
  actionButtonPlacement = 'center',
  className,
  loading,
  acceptFile = ['.jpg', '.jpeg', '.png']
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    useMemo(
      () => ({
        onDrop,
        multiple: false,
        accept: acceptFile,
      }),
      [onDrop, acceptFile]
    )
  )

  useEffect(() => {
    if (formik && preview) {
      if (formik.setErrors) {
        formik.setErrors({});
      }
    }
  }, [formik, preview])

  const isDisabled = disabled || loading;

  return (
    <div>
      <div {...(isDisabled ? {} : getRootProps())} className={twMerge('rounded-xl mr-12', className)}>
        {isDisabled ? null : <input {...getInputProps()} />}
        <div className={clsx('w-full rounded-xl bg-neutral-10 relative  h-full', { 'border border-danger': error && touched })}>
          {(loading) && (
            <div className='absolute inset-0 flex items-center justify-center bg-white/75'>
              <GIcon
                icon='IconLoading'
                className={`w-[48px] h-[48px] text-primary animate-spin`}
              />
            </div>
          )}
          {preview ? (
            <div className='relative w-full h-full overflow-hidden'>
              <img alt={title} src={preview} className='object-contain max-w-full max-h-full rounded-xl' />
              <div className='absolute inset-x-0 bottom-0 h-12'>
                <div className={clsx('flex w-full',
                  { 'justify-end mr-4': actionButtonPlacement === 'right' },
                  { 'justify-center': actionButtonPlacement === 'center' },
                  { 'justify-start ml-4': actionButtonPlacement === 'left' })}>
                  <GIconButton
                    data-testid='gbutton-test'
                    colors='warning'
                    type='button'
                    onClick={(event) => {
                      event.stopPropagation()
                      onClear()
                    }}
                    icon='IconRemove'
                    style={{ left: 60, bottom: 12, boxShadow: '0px 8px 12px rgba(33, 37, 41, 0.24)' }}
                    className='mx-2'
                  />
                  <GIconButton
                    colors='info'
                    type='button'
                    style={{ left: 16, bottom: 12, boxShadow: '0px 8px 12px rgba(33, 37, 41, 0.24)' }}
                    icon='IconEdit'
                    className='mx-2'
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={clsx('flex flex-col items-center justify-center w-full h-full', className)}>
              <svg width="53" height="42" viewBox="0 0 53 42" fill="none" xmlns="http://www.w3.org/2000/svg" className='mb-3'>
                <path d="M0.5 5.4002C0.5 2.5402 2.84 0.200195 5.7 0.200195H47.3C48.6791 0.200195 50.0018 0.748051 50.977 1.72324C51.9521 2.69843 52.5 4.02107 52.5 5.4002V36.6002C52.5 37.9793 51.9521 39.302 50.977 40.2772C50.0018 41.2523 48.6791 41.8002 47.3 41.8002H5.7C4.32087 41.8002 2.99823 41.2523 2.02304 40.2772C1.04786 39.302 0.5 37.9793 0.5 36.6002V5.4002ZM29.1 28.8002L21.3 21.0002L5.7 36.6002H47.3L34.3 23.6002L29.1 28.8002ZM39.5 18.4002C40.8791 18.4002 42.2018 17.8523 43.177 16.8771C44.1521 15.902 44.7 14.5793 44.7 13.2002C44.7 11.8211 44.1521 10.4984 43.177 9.52324C42.2018 8.54805 40.8791 8.0002 39.5 8.0002C38.1209 8.0002 36.7982 8.54805 35.823 9.52324C34.8479 10.4984 34.3 11.8211 34.3 13.2002C34.3 14.5793 34.8479 15.902 35.823 16.8771C36.7982 17.8523 38.1209 18.4002 39.5 18.4002Z" fill="#ADB5BD" />
              </svg>
              <div className='mb-3 text-xs text-center text-bold text-neutral-70'>
                {isDragActive ? `Drop the files here ...` : message}
              </div>
              <div className='p-1 px-3 py-2 text-xs font-bold border text-primary border-primary rounded-xl'>
                Select Image
              </div>
            </div>
          )}
        </div>
      </div>
      {(error && touched) && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export { FormUploadImage }

