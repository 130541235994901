import clsx from 'clsx'
import {ButtonHTMLAttributes, FC} from 'react'
import {Link} from 'react-router-dom'
import VIcon from '../VIcon/VIcon'

export type VButtonSize = 'tiny' | 'small' | 'medium' | 'large' | 'giant'
export type VButtonVariant = 'filled' | 'outline' | 'ghost'
export type VButtonColor =
  | 'primary'
  | 'danger'
  | 'warning'
  | 'info'
  | 'success'
  | 'secondary'
  | 'tertiary'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: VButtonSize
  variant?: VButtonVariant
  color?: VButtonColor
  loading?: boolean
  startIcon?: any
  endIcon?: any
}

export enum VButtonSizes {
  tiny = 'v-button-tiny',
  small = 'v-button-small',
  medium = 'v-button-medium',
  large = 'v-button-large',
  giant = 'v-button-giant',
}

export interface VButtonProps extends ButtonProps {
  href?: string
}

const Button: FC<ButtonProps> = ({
  className = '',
  size = 'medium',
  variant = 'filled',
  color = 'primary',
  startIcon,
  endIcon,
  children,
  loading,
  ...rest
}) => {
  return (
    <button
      data-testid='v-button-test'
      className={clsx('v-button', `v-button-${variant}-${color}`, VButtonSizes[size], className)}
      {...rest}
    >
      <div className='flex items-center justify-center'>
        {loading ? <VIcon icon='loader' type='base' className='animate-spin' /> : children}
      </div>
    </button>
  )
}

const VButton: FC<VButtonProps> = ({children, href, ...rest}) => {
  if (href) {
    return (
      <Link to={href}>
        <Button {...rest}>{children}</Button>
      </Link>
    )
  } else {
    return <Button {...rest}>{children}</Button>
  }
}

export default VButton
