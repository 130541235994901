import { FC } from 'react'
import { GChip } from '../Libs'

type Props = {
  value?: string
}

const HandleStatus: FC<Props> = ({ value }) => {
  switch (value) {
    case 'ACTIVE':
      return <GChip variant='success'>ACTIVE</GChip>
    case 'COMPLETED':
      return <GChip variant='success'>COMPLETED</GChip>
    case 'DELETED':
      return <GChip variant='neutral'>DELETED</GChip>
    case 'OPEN':
      return <GChip variant='success'>OPEN</GChip>
    case 'REINSTATED':
      return <GChip variant='neutral'>REINSTATED</GChip>
    case 'NOT_VERIFIED':
      return <GChip variant='info'>NOT VERIFIED</GChip>
    case 'IN_PROGRESS':
      return <GChip variant='success'>ACTIVE</GChip>
    case 'SCHEDULED':
      return <GChip variant='info'>SCHEDULED</GChip>
    case 'EXPIRED':
      return <GChip variant='warning'>EXPIRED</GChip>
    case 'DRAFT':
      return <GChip variant='neutral'>DRAFT</GChip>
    case 'PUBLISH':
      return <GChip variant='success'>PUBLISH</GChip>
    case 'UNPUBLISH':
    return <GChip variant='neutral'>UNPUBLISH</GChip>
    default:
      return <GChip variant='neutral'>INACTIVE</GChip>
  }
}

export default HandleStatus
