import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const OnboardingScreens = {
  ONBOARDING_LIST: {
    PATH: '/general-config/onboarding-management/list',
    HEADER_NAME: 'Onboarding List',
    TITLE: 'Onboarding Screen',
    TITLE_ALT: 'Onboarding Screen',
    PERMISSION: 'onboarding.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ONBOARDING: {
    PATH: '/general-config/onboarding-management/list/detail/:id',
    TITLE: 'Onboarding Detail',
    TITLE_ALT: 'Onboarding Detail',
    PERMISSION: 'onboarding.view-detail',
    buildPath: (id: string) => `/general-config/onboarding-management/list/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ONBOARDING: {
    PATH: '/general-config/onboarding-management/list/edit/:id',
    TITLE: 'Edit Onboarding',
    TITLE_ALT: 'Edit Onboarding',
    PERMISSION: 'onboarding.update',
    buildPath: (id: string) => `/general-config/onboarding-management/list/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ONBOARDING: {
    PATH: '/general-config/onboarding-management/list/create',
    TITLE: 'Create Onboarding',
    TITLE_ALT: 'Create Onboarding',
    PERMISSION: 'onboarding.create',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const onboardingBreadcrumbs = generateBreadcrumb([OnboardingScreens.ONBOARDING_LIST])

OnboardingScreens.DETAIL_ONBOARDING.breadcrumbs = () => onboardingBreadcrumbs
OnboardingScreens.EDIT_ONBOARDING.breadcrumbs = () => onboardingBreadcrumbs

export default OnboardingScreens