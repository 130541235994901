import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const VoucherScreens = {
  VOUCHER: {
    PATH: '/voucher-management',
    TITLE: 'Voucher Management',
    TITLE_ALT: 'Voucher',
    PERMISSION: 'voucher.get-list',
  },
  LIST_VOUCHER: {
    PATH: '/voucher-management/voucher',
    HEADER_NAME: 'Voucher',
    TITLE: 'Voucher',
    TITLE_ALT: 'Voucher List',
    PERMISSION: 'voucher.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_VOUCHER: {
    PATH: '/voucher-management/voucher/add',
    HEADER_NAME: 'Create New Voucher',
    TITLE: 'Create New Voucher',
    TITLE_ALT: 'Create New Voucher',
    PERMISSION: 'voucher.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DRAFT_VOUCHER: {
    PATH: '/voucher-management/voucher/add/:id',
    HEADER_NAME: 'View Detail Voucher',
    TITLE: 'View Detail Voucher',
    TITLE_ALT: 'Create New Voucher',
    PERMISSION: 'voucher.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_VOUCHER: {
    PATH: '/voucher-management/voucher/detail/:id',
    TITLE: 'View Detail Voucher',
    TITLE_ALT: 'View Detail Voucher',
    PERMISSION: 'voucher.view-detail',
    buildPath: (id: string) => `/voucher-management/voucher/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_VOUCHER: {
    PATH: '/voucher-management/voucher/edit/:id',
    TITLE: 'Edit Voucher',
    TITLE_ALT: 'Edit Voucher',
    PERMISSION: 'voucher.view-detail',
    buildPath: (id: string) => `/voucher-management/voucher/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CUSTOMER: {
    PATH: '/voucher-management/voucher/customer/detail/:id',
    TITLE: 'View Detail Customer',
    TITLE_ALT: 'View Detail Customer',
    buildPath: (id: string) => `/voucher-management/voucher/customer/detail/${id}`,
    PERMISSION: 'voucher.view-detail',
    breadcrumbs: defaultBreadcrumbs,
  },

  COMBINATION: {
    PATH: '/voucher-management/combination',
    TITLE: 'Combination',
    TITLE_ALT: 'Group List',
    PERMISSION: 'combination.get-list',
  },
  ADD_COMBINATION: {
    PATH: '/voucher-management/combination/add',
    HEADER_NAME: 'Create Combination',
    TITLE: 'Create Combination',
    TITLE_ALT: 'Create Group',
    PERMISSION: 'combination.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_COMBINATION: {
    PATH: '/voucher-management/combination/detail/:id',
    HEADER_NAME: 'Detail Combination',
    TITLE: 'Detail Combination',
    TITLE_ALT: 'Detail Combination',
    PERMISSION: 'combination.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_COMBINATION: {
    PATH: '/voucher-management/combination/edit/:id',
    HEADER_NAME: 'Edit Combination',
    TITLE: 'Edit Combination',
    TITLE_ALT: 'Edit Group',
    PERMISSION: 'combination.create',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const listBreadcrumbs = generateBreadcrumb([VoucherScreens.VOUCHER])

VoucherScreens.LIST_VOUCHER.breadcrumbs = () => listBreadcrumbs
VoucherScreens.DETAIL_VOUCHER.breadcrumbs = () => listBreadcrumbs

export const DetailVoucherTab = {
  GENERAL_INFO: {
    PATH: '/voucher-management/voucher-detail/:id/general-info',
    TITLE: 'General Info',
    TITLE_ALT: 'General Info',
    LABEL: 'General Info',
    SLUG: 'general-info',
  },
  RULE_SECTION: {
    PATH: '/voucher-management/voucher-detail/:id/rule-section',
    TITLE: 'RULE SECTION',
    TITLE_ALT: 'RULE SECTION',
    LABEL: 'RULE SECTION',
    SLUG: 'rule-section',
  },
  REWARD_SECTION: {
    PATH: '/voucher-management/voucher-detail/:id/reward-section',
    TITLE: 'Reward Section',
    TITLE_ALT: 'Reward Section',
    LABEL: 'Reward Section',
    SLUG: 'reward-section',
  },
  VOUCHER: {
    PATH: '/voucher-management/voucher-detail/:id/voucher',
    TITLE: 'Voucher',
    TITLE_ALT: 'Voucher',
    LABEL: 'Voucher',
    SLUG: 'voucher',
  },
}

export default VoucherScreens
