import { cva } from 'class-variance-authority';

const sizes = {
  small: ['py-[4px]', 'px-[14px]', 'h-[32px]', 'text-fs-9'],
  medium: ['py-[9px]', 'px-[16px]', 'h-[38px]', 'text-fs-9'],
  large: ['py-[10px]', 'px-[20px]', 'h-[44px]', 'text-fs-8'],
}

const buttonBaseStyles = [
  'border',
  'outline-none',
  'font-semibold',
  'rounded-xl',
  'transition-all',
  'duration-300',
  'disabled:bg-neutral-20',
  'disabled:text-neutral-60',
  'disabled:border-neutral-20',
  'min-w-[116px]',
];

function createButton(borderStyle: any) {
  return cva(
    [...buttonBaseStyles, borderStyle === 'solid' ? 'border' : 'border-dashed border-2'], // Combine base styles with border style
    {
      variants: {
        colors: {
          primary: ['text-primary', 'border-primary', 'hover:bg-primary-light-2'],
          success: ['text-success', 'border-success', 'hover:bg-success-light-2'],
          danger: ['text-danger', 'border-danger', 'hover:bg-danger-light-2'],
          warning: ['text-warning', 'border-warning', 'hover:bg-warning-light-2'],
          info: ['text-info', 'border-info', 'hover:bg-info-light-2'],
        },
        size: sizes,
      },
      compoundVariants: [
        {
          colors: 'primary',
          size: 'medium',
        },
      ],
      defaultVariants: {
        colors: 'primary',
        size: 'medium',
      },
    }
  );
}

export const filledButton = cva(
  [
    'border',
    'outline-none',
    'font-semibold',
    'rounded-xl',
    'transition-all',
    'duration-300',
    'disabled:bg-neutral-20',
    'disabled:text-neutral-60',
    'disabled:border-neutral-20',
    'min-w-[116px]',
  ],
  {
    variants: {
      colors: {
        primary: ['bg-primary', 'border-primary', 'hover:bg-primary-dark-1', 'text-white'],
        success: ['bg-success', 'border-success', 'hover:bg-success-dark-1', 'text-white'],
        danger: ['bg-danger', 'border-danger', 'hover:bg-danger-dark-1', 'text-white'],
        warning: ['bg-warning', 'border-warning', 'hover:bg-warning-dark-1', 'text-white'],
        info: ['bg-info', 'border-info', 'hover:bg-info-dark-1', 'text-white'],
      },
      size: sizes,
    },
    compoundVariants: [
      {
        colors: 'primary',
        size: 'medium',
      },
    ],
    defaultVariants: {
      colors: 'primary',
      size: 'medium',
    },
  }
)

export const outlinedButton = createButton('solid');

export const dashedButton = createButton('dashed');

export const ghostButton = cva(
  [
    'border-none',
    'outline-none',
    'font-semibold',
    'rounded-lg',
    'transition-all',
    'duration-300',
    'disabled:text-neutral-60',
    'min-w-[116px]',
  ],
  {
    variants: {
      colors: {
        primary: ['text-primary', 'hover:bg-primary-light-2'],
        success: ['text-success', 'hover:bg-success-light-2'],
        danger: ['text-danger', 'hover:bg-danger-light-2'],
        warning: ['text-warning', 'hover:bg-warning-light-2'],
        info: ['text-info', 'hover:bg-info-light-2'],
      },
      size: sizes,
    },
    compoundVariants: [
      {
        colors: 'primary',
        size: 'medium',
      },
    ],
    defaultVariants: {
      colors: 'primary',
      size: 'medium',
    },
  }
)

export const unstyledButton = cva(
  ['p-0', 'font-semibold', 'bg-transparent', 'border-0', 'outline-0'],
  {
    variants: {
      size: sizes,
    },
    compoundVariants: [
      {
        size: 'medium',
      },
    ],
    defaultVariants: {
      size: 'medium',
    },
  }
)
