import { FC, MouseEvent } from 'react'
import InlineSVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/app/utils/asset-helpers-utils'

export enum GIconCollection {
  IconAdd = '/media/icons/IconAdd.svg',
  IconAddOutlane = '/media/icons/IconAddOutlane.svg',
  IconAddOutlaneDisabled = '/media/icons/IconAddOutlaneDisabled.svg',
  IconArrowTop = '/media/icons/IconArrowTop.svg',
  IconAvatar = '/media/icons_v2/base/user.svg',
  IconBack = '/media/icons/IconBack.svg',
  IconBurger = '/media/icons/IconBurger.svg',
  IconCalendar = '/media/icons/IconCalendar.svg',
  IconCalendarRounded = '/media/icons/IconCalendarRounded.svg',
  IconCheck = '/media/icons/IconCheck.svg',
  IconCheckCircle = '/media/icons/IconCheckCircle.svg',
  IconClone = '/media/icons/IconClone.svg',
  IconClock = '/media/icons/IconClock.svg',
  IconClose = '/media/icons/IconClose.svg',
  IconCloseRound = '/media/icons/IconCloseRound.svg',
  IconCustomerDetail = '/media/icons/IconCustomerDetail.svg',
  IconCreditCard = '/media/icons/IconCreditCard.svg',
  IconDana = '/media/icons/IconDana.svg',
  IconDocumentDownload = '/media/icons/IconDocumentDownload.svg',
  IconDoubleChevronLeft = '/media/icons/IconDoubleChevronLeft.svg',
  IconDot = '/media/icons/IconDot.svg',
  IconDotMenu = '/media/icons/IconDotMenu.svg',
  IconDown = '/media/icons/IconDown.svg',
  IconDownload = '/media/icons/IconDownload.svg',
  IconDelete = '/media/icons/IconDelete.svg',
  IconDeleteDisabled = '/media/icons/IconDeleteDisabled.svg',
  IconEdit = '/media/icons/IconEdit.svg',
  IconEditCurved = '/media/icons/IconEditCurved.svg',
  IconEditOutlined = '/media/icons/IconEditOutlined.svg',
  IconEmail = '/media/icons/IconEmail.svg',
  IconEmot = '/media/icons/IconEmot.svg',
  IconEyeOn = '/media/icons/IconEyeOn.svg',
  IconEyeOff = '/media/icons/IconEyeOff.svg',
  IconEnvelope = '/media/icons/IconEnvelope.svg',
  IconExclamation = '/media/icons/IconExclamation.svg',
  IconFilter = '/media/icons/IconFilter.svg',
  IconFileCSV = '/media/icons/IconFileCSV.svg',
  IconGopay = '/media/icons/IconGopay.svg',
  IconImagePlaceHolder = '/media/icons/IconImagePlaceHolder.svg',
  IconImageRounded = '/media/icons/IconImageRounded.svg',
  IconHomeMenu = '/media/icons/IconHomeMenu.svg',
  IconInfo = '/media/icons/IconInfo.svg',
  IconInfoNoBackground = '/media/icons/IconInfoNoBackground.svg',
  IconLaptop = '/media/icons/IconLaptop.svg',
  IconLink = '/media/icons/IconLink.svg',
  IconLoading = '/media/icons/IconLoading.svg',
  IconLock = '/media/icons/IconLock.svg',
  IconMoon = '/media/icons/IconMoon.svg',
  IconOutlet = '/media/icons/IconOutlet.svg',
  IconPaginationRight = '/media/icons/IconPaginationRight.svg',
  IconPaginationLeft = '/media/icons/IconPaginationLeft.svg',
  IconPaperPlane = '/media/icons/IconPaperPlane.svg',
  IconPhone = '/media/icons_v2/base/phone.svg',
  IconRefresh = '/media/icons/IconRefresh.svg',
  IconSearch = '/media/icons/IconSearch.svg',
  IconSun = '/media/icons/IconSun.svg',
  IconTriangleTop = '/media/icons/IconTriangleTop.svg',
  IconRedEnvelope = '/media/icons/IconRedEnvelope.svg',
  IconRedLeptop = '/media/icons/IconRedLeptop.svg',
  IconUserFriends = '/media/icons/IconUserFriends.svg',
  IconUp = '/media/icons/IconUp.svg',
  IconUpdate = '/media/icons/IconUpdate.svg',
  IconUpDown = '/media/icons/IconUpDown.svg',
  IconUpload = '/media/icons/IconUpload.svg',
  IconSignOut = '/media/icons/IconSignOut.svg',
  IconRemove = '/media/icons_v2/base/trash-2.svg',
  IconView = '/media/icons/IconView.svg',
  IconWarning = '/media/icons/IconWarning.svg',
  IconCloudUpload = '/media/icons/IconCloudUpload.svg',
  IconTag = '/media/icons/IconTag.svg',
  IconCaret = '/media/icons/IconCaret.svg',
  IconsTimesCircle = '/media/icons/IconsTimesCircle.svg',
  IconStar = '/media/icons/IconStar.svg',
  IconDragdrop = '/media/icons/IconDragdrop.svg',
  IconMoreVertical = '/media/icons/IconMoreVertical.svg',
  IconEyeOutline = '/media/icons/IconEyeOutline.svg',
  IconUsersOutline = '/media/icons/IconUsersOutline.svg',
  IconQrOutline = '/media/icons/IconQrOutline.svg',
  IconLinkOutline = '/media/icons/IconLinkOutline.svg',
  IconDownloadOutline = '/media/icons/IconDownloadOutline.svg',
  IconTimesCircle = '/media/icons/IconTimesCircle.svg',
}

export type GIconProps = keyof typeof GIconCollection

type Props = {
  icon: GIconProps
  className?: string
  onClick?: (event: MouseEvent<SVGElement>) => void
}

const GIcon: FC<Props> = ({ icon, className, onClick }) => {
  return (
    <InlineSVG src={toAbsoluteUrl(GIconCollection[icon])} className={className} onClick={onClick} />
  )
}

export default GIcon
