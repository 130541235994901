import { MouseEvent, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { useMutation } from '@tanstack/react-query';
import { useFileDownload } from 'src/app/hooks/file-download.hook';
import { humanFileSize } from 'src/app/utils/input-utils';
import { GButton } from "../../../Button/Button.component";
import { HelperText } from '../../../HelperText/HelperText.component';
import GIcon from "../../../Icon/GIcon";
import { FileCsvDraft, UploadCsvProps } from './UploadCsv.types';
import { getErrorRejection } from './UploadCsv.utils';

export function UploadCsv({
  validateCsvOptions = {},
  name = '',
  downloadTemplateOptions,
  onSuccess,
  onError,
  error,
  touched,
  ...props
}: Readonly<UploadCsvProps>) {

  const [rejectReasons, setRejectReasons] = useState<string[]>([]);
  const [draft, setDraft] = useState<FileCsvDraft>({});

  const isShouldDisplayError = touched && !!error;
  const hasValue = !!draft?.filename;

  const validateCsv = useMutation(validateCsvOptions);

  const handleClearInput = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();
    onSuccess?.('');
    setDraft({});
  };

  const handleAcceptedFiles = async ([file]: File[]) => {
    try {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileData: FileCsvDraft = {
          base64: reader.result?.toString(),
          filename: file.name,
          size: humanFileSize(file.size),
          status: 'loading'
        };
        setDraft(fileData);
      };

      const response = await validateCsv.mutateAsync(file);
      const {
        upload_lines,
        upload_id
      } = response.data.response_output?.detail ?? {};
      onSuccess?.(upload_id);
      setRejectReasons([]);
      setDraft((prevState) => ({
        ...prevState,
        message: `${upload_lines ?? 0} outlet(s) uploaded`,
        status: 'success'
      }));

    } catch (error: any) {
      onError?.(error);
      setDraft((prevState) => ({
        ...prevState,
        message: '',
        status: 'error'
      }));
    }
  };

  const dropzone = useDropzone({
    multiple: false,
    accept: ['.csv'],
    onDrop: (acceptedFiles, fileRejections) => {
      if (Array.isArray(fileRejections) && fileRejections.length > 0) {
        const errorRejection = getErrorRejection(fileRejections, props);
        setRejectReasons(errorRejection);
      } else {
        handleAcceptedFiles(acceptedFiles);
      }
    }
  });

  const fileDownload = useFileDownload(downloadTemplateOptions!);

  return (
    <div className="p-5 border rounded-lg border-neutral-30">
      <input {...dropzone.getInputProps()} />
      <div className="flex flex-row items-center gap-3">
        <div className={`flex flex-row items-center flex-1 gap-4 p-5 border-2 border-dashed rounded-xl bg-neutral-10 ${isShouldDisplayError ? "border-danger" : "border-neutral-30"}`} {...dropzone.getRootProps()}>
          <GIcon className={hasValue ? "text-success" : "text-neutral-40"} icon="IconFileCSV" />
          {hasValue ? (
            <>
              <div className="flex-1">
                <p className="font-semibold">{draft.filename}</p>
                <p className="text-neutral-60">File Size {draft.size}</p>
              </div>
              <GIcon onClick={handleClearInput} icon="IconDeleteDisabled" />
            </>
          ) : (
            <>
              <div className="flex-1">
                <p className="font-semibold">Upload CSV File</p>
                <p className="text-neutral-60">Document only support .CSV format</p>
              </div>
              <GIcon icon="IconCloudUpload" />
            </>
          )}
        </div>
        <GButton
          onClick={() => fileDownload.download(downloadTemplateOptions?.filename!, {})}
          loading={fileDownload.isLoading}
          variant="GHOST"
          type="button"
        >
          Download Template
        </GButton>
      </div>
      {rejectReasons.length > 0
        ? rejectReasons.map((_) => <HelperText key={_}>{_}</HelperText>)
        : isShouldDisplayError && <HelperText>{error}</HelperText>
      }
      {draft.status === "loading" && (
        <HelperText variant="loading">
          Validating CSV File...
        </HelperText>
      )}
      {draft.status === "success" && (
        <HelperText variant="success">
          {draft.message} <GIcon className="inline-block ml-1" icon="IconCheckCircle" />
        </HelperText>
      )}
    </div>
  );
}
