import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const PromotionScreens = {
  PROMOTION: {
    PATH: '/promotion-management',
    TITLE: 'Promotion Management',
    TITLE_ALT: 'Promotion Management',
    PERMISSION: 'main-banner.get-list',
  },
  MAIN_BANNER: {
    PATH: '/promotion-management/banner',
    FEATURE: '/promotion-management/banner/list/featured',
    HEADER_NAME: 'Main Banner',
    TITLE: 'Promotion Management',
    TITLE_ALT: 'Main Banner',
    PERMISSION: 'main-banner.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_BANNER: {
    PATH: '/promotion-management/banner/list/:tab',
    CONTENT: '/promotion-management/banner/list/content',
    HEADER_NAME: 'Main Banner List',
    TITLE: 'Main Banner List',
    TITLE_ALT: 'Main Banner List',
    PERMISSION: 'main-banner.get-list',
    buildPath: (tab: string) => `/promotion-management/banner/list/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_BANNER: {
    PATH: '/promotion-management/banner/create/:domain',
    TITLE: 'Create New Banner',
    TITLE_ALT: 'Create New Banner',
    PERMISSION: 'main-banner.create',
    buildPath: (domain: string) => `/promotion-management/banner/create/${domain}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_BANNER: {
    PATH: '/promotion-management/banner/edit/:id',
    TITLE: 'Edit Banner',
    TITLE_ALT: 'Edit Banner',
    PERMISSION: 'main-banner.update',
    buildPath: (id: string) => `/promotion-management/banner/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_BANNER: {
    PATH: '/promotion-management/banner/detail/:id',
    TITLE: 'Detail Banner',
    TITLE_ALT: 'Detail Banner',
    PERMISSION: 'main-banner.view-detail',
    buildPath: (id: string) => `/promotion-management/banner/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  SECTION_BANNER: {
    PATH: '/promotion-management/section-banner',
    HEADER_NAME: 'Section Banner List',
    TITLE: 'Section Banner',
    TITLE_ALT: 'Section Banner',
    PERMISSION: 'section-banner.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_SECTION_BANNER: {
    PATH: '/promotion-management/section-banner/list/:tab',
    ONGOING: '/promotion-management/section-banner/list/ongoing',
    SECTION: '/promotion-management/section-banner/list/section',
    HEADER_NAME: 'Section Banner List',
    TITLE: 'Ongoing Section Banner List',
    TITLE_ALT: 'Ongoing Section Banner List',
    PERMISSION: 'section-banner.get-list',
    buildPath: (tab: string) => `/promotion-management/section-banner/list/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_SECTION_BANNER: {
    PATH: '/promotion-management/section-banner/create',
    TITLE: 'Create List',
    TITLE_ALT: 'Create List',
    PERMISSION: 'section-banner.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_SECTION_BANNER: {
    PATH: '/promotion-management/section-banner/edit/:id',
    TITLE: 'Edit List',
    TITLE_ALT: 'Edit List',
    PERMISSION: 'section-banner.update',
    buildPath: (id: string) => `/promotion-management/section-banner/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_SECTION_BANNER: {
    PATH: '/promotion-management/section-banner/detail/:id',
    TITLE: 'Detail Section Banner',
    TITLE_ALT: 'Detail Section Banner',
    PERMISSION: 'section-banner.view-detail',
    buildPath: (id: string) => `/promotion-management/section-banner/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  PROMO_BANNER: {
    PATH: '/promotion-management/promo-banner',
    HEADER_NAME: 'Promo',
    TITLE: 'Promo',
    TITLE_ALT: 'Promo',
    PERMISSION: 'banner.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_PROMO_BANNER: {
    PATH: '/promotion-management/promo-banner/list',
    HEADER_NAME: 'Promotion Management',
    TITLE: 'Promo List',
    TITLE_ALT: 'Promo List',
    PERMISSION: 'banner.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  CREATE_PROMO_BANNER: {
    PATH: '/promotion-management/promo-banner/create',
    HEADER_NAME: 'Create Promo',
    TITLE: 'Create Promo',
    TITLE_ALT: 'Create Promo',
    PERMISSION: 'banner.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_PROMO_BANNER: {
    PATH: '/promotion-management/promo-banner/detail/:id',
    HEADER_NAME: 'Detail Promo',
    TITLE: 'Detail Promo',
    TITLE_ALT: 'Detail Promo',
    PERMISSION: 'banner.create',
    buildPath: (id: string) => `/promotion-management/promo-banner/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_PROMO_BANNER: {
    PATH: '/promotion-management/promo-banner/edit/:id',
    HEADER_NAME: 'Edit Promo',
    TITLE: 'Edit Promo',
    TITLE_ALT: 'Edit Promo',
    PERMISSION: 'banner.create',
    buildPath: (id: string) => `/promotion-management/promo-banner/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  POPUP_BANNER: {
    PATH: '/promotion-management/popup-banner',
    FEATURED: '/promotion-management/popup-banner/list/featured',
    HEADER_NAME: 'Popup Banner',
    TITLE: 'Popup Banner',
    TITLE_ALT: 'Popup Banner',
    PERMISSION: 'popup-banner.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_POPUP_BANNER: {
    PATH: '/promotion-management/popup-banner/list/:tab',
    CONTENT: '/promotion-management/popup-banner/list/content',
    HEADER_NAME: 'Popup Banner List',
    TITLE: 'Popup Banner',
    TITLE_ALT: 'Popup Banner List',
    PERMISSION: 'popup-banner.get-list',
    buildPath: (tab: string) => `/promotion-management/popup-banner/list/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_POPUP_BANNER: {
    PATH: '/promotion-management/popup-banner/create',
    HEADER_NAME: 'Create Popup Banner',
    TITLE: 'Create Popup Banner',
    TITLE_ALT: 'Create Popup Banner',
    PERMISSION: 'popup-banner.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_POPUP_BANNER: {
    PATH: '/promotion-management/popup-banner/edit/:id',
    TITLE: 'Edit Popup Banner',
    TITLE_ALT: 'Edit Popup Banner',
    PERMISSION: 'popup-banner.update',
    buildPath: (id: string) => `/promotion-management/popup-banner/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_POPUP_BANNER: {
    PATH: '/promotion-management/popup-banner/detail/:id',
    TITLE: 'Detail Popup Banner',
    TITLE_ALT: 'Detail Popup Banner',
    PERMISSION: 'popup-banner.view-detail',
    buildPath: (id: string) => `/promotion-management/popup-banner/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
}

export const ListBannerTab = {
  FEATURED_BANNER: {
    PATH: '/promotion-management/banner/list/featured',
    TITLE: 'Ongoing Banner',
    TITLE_ALT: 'Ongoing Banner',
    LABEL: 'Ongoing Banner',
    SLUG: 'featured',
  },
  CONTENT_LIST: {
    PATH: '/promotion-management/banner/list/content',
    TITLE: 'Main Banner List',
    TITLE_ALT: 'Main Banner List',
    LABEL: 'Banner List',
    SLUG: 'content',
  },
}

export const SectionBannerTab = {
  ONGOING_BANNER: {
    PATH: '/promotion-management/section-banner/list/ongoing',
    TITLE: 'Ongoing Banner',
    TITLE_ALT: 'Ongoing Banner',
    LABEL: 'Ongoing Banner',
    SLUG: 'ongoing',
  },
  SECTION_LIST: {
    PATH: '/promotion-management/section-banner/list/section',
    TITLE: 'Section Banner List',
    TITLE_ALT: 'Section Banner List',
    LABEL: 'Section List',
    SLUG: 'section',
  },
}

export const ListPopupBannerTab = {
  FEATURED_BANNER: {
    PATH: '/promotion-management/popup-banner/list/featured',
    TITLE: 'Ongoing Banner',
    TITLE_ALT: 'Ongoing Banner',
    LABEL: 'Ongoing Banner',
    SLUG: 'featured',
  },
  CONTENT_LIST: {
    PATH: '/promotion-management/popup-banner/list/content',
    TITLE: 'Popup Banner List',
    TITLE_ALT: 'Popup Banner List',
    LABEL: 'Banner List',
    SLUG: 'content',
  },
}

const listBreadcrumbs = generateBreadcrumb([PromotionScreens.PROMOTION])
const bannerBreadcrumbs = generateBreadcrumb([PromotionScreens.LIST_BANNER])

PromotionScreens.ADD_BANNER.breadcrumbs = () => bannerBreadcrumbs
PromotionScreens.EDIT_BANNER.breadcrumbs = () => bannerBreadcrumbs
PromotionScreens.DETAIL_BANNER.breadcrumbs = () => bannerBreadcrumbs

PromotionScreens.PROMO_BANNER.breadcrumbs = () => listBreadcrumbs
PromotionScreens.CREATE_PROMO_BANNER.breadcrumbs = () => listBreadcrumbs

export default PromotionScreens
