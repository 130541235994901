import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const AdminRoleScreens = {
  USER_ROLE: {
    PATH: '/permissions',
    TITLE: 'Admin Management',
    TITLE_ALT: 'Admin Management',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_ADMIN: {
    PATH: '/permissions/admin/list',
    HEADER_NAME: 'Admin Management',
    TITLE: 'Admin List',
    TITLE_ALT: 'Admin List',
    PERMISSION: 'admin.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ADMIN: {
    PATH: '/permissions/admin/list/add',
    TITLE: 'Create New Admin',
    TITLE_ALT: 'Create New Admin',
    PERMISSION: 'admin.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ADMIN: {
    PATH: '/permissions/admin/list/edit/:id',
    TITLE: 'Edit Admin',
    TITLE_ALT: 'Edit Admin',
    PERMISSION: 'admin.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ADMIN: {
    PATH: '/permissions/admin/list/detail/:id',
    TITLE: 'View Admin',
    TITLE_ALT: 'View Admin',
    PERMISSION: 'admin.view-detail',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_ROLE: {
    PATH: '/permissions/role/list',
    HEADER_NAME: 'Admin Management',
    TITLE: 'Role Setting',
    TITLE_ALT: 'Admin Role Setting',
    PERMISSION: 'role.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ROLE: {
    PATH: '/permissions/role/list/create',
    TITLE: 'Create Role',
    TITLE_ALT: 'Create Role',
    PERMISSION: 'role.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ROLE: {
    PATH: '/permissions/role/list/edit/:id',
    TITLE: 'Edit Role',
    TITLE_ALT: 'Edit Role',
    PERMISSION: 'role.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ROLE: {
    PATH: '/permissions/role/list/detail/:id',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Detail Role',
    PERMISSION: 'role.view-detail',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const adminBreadcrumbs = generateBreadcrumb([AdminRoleScreens.LIST_ADMIN])
const roleBreadcrumbs = generateBreadcrumb([AdminRoleScreens.LIST_ROLE])

AdminRoleScreens.ADD_ADMIN.breadcrumbs = () => adminBreadcrumbs
AdminRoleScreens.EDIT_ADMIN.breadcrumbs = () => adminBreadcrumbs
AdminRoleScreens.DETAIL_ADMIN.breadcrumbs = () => adminBreadcrumbs
AdminRoleScreens.ADD_ROLE.breadcrumbs = () => roleBreadcrumbs
AdminRoleScreens.EDIT_ROLE.breadcrumbs = () => roleBreadcrumbs
AdminRoleScreens.DETAIL_ROLE.breadcrumbs = () => roleBreadcrumbs

export default AdminRoleScreens
