import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { BaseResponse, Response } from "../models/api.types";
import { convertFormData } from "../utils/api-utils";

const URL_COMMON_UPLAOD = '/v1/common/upload';

type MutationResponse = BaseResponse<Response<{ name: string }>>;
type OptionsParams<TVariables, TContext> = UseMutationOptions<AxiosResponse<MutationResponse>, AxiosError<BaseResponse<Response<any>>>, TVariables, TContext>;

export function useFileUpload<
  TVariables = any,
  TContext = unknown
>(
  options?: OptionsParams<TVariables, TContext>
) {

  const mutation = useMutation({
    ...options,
    mutationFn: (_: any) => axios.post<MutationResponse>(URL_COMMON_UPLAOD, convertFormData(_, true))
  });

  const upload = async (params?: TVariables) => {
    try {

      const response = await mutation.mutateAsync(params!);
      return Promise.resolve(response.data.response_output?.detail);

    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    ...mutation,
    upload
  }
}
