import {cva} from 'class-variance-authority'

export const chipTheme = cva(['py-[2px]', 'px-2', 'rounded', 'font-semibold', 'text-fs-10'], {
  variants: {
    variant: {
      primary: ['bg-primary-light-2', 'text-primary'],
      success: ['bg-success-light-2', 'text-success'],
      danger: ['bg-danger-light-2', 'text-danger'],
      warning: ['bg-warning-light-2', 'text-warning'],
      info: ['bg-info-light-2', 'text-info'],
      neutral: ['bg-neutral-20', 'text-neutral-60'],
    },
  },
  compoundVariants: [
    {
      variant: 'success',
    },
  ],
  defaultVariants: {
    variant: 'success',
  },
})
