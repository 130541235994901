import {FC, useMemo} from 'react'
import InlineSVG from 'react-inlinesvg'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

export type PaginationType = 'TYPE_1' | 'TYPE_2'

export type PaginationProps = {
  limit: number
  setPerPage: (number: number) => void
  page: number
  setPage: (number: number) => void
  maxData: number
  type?: PaginationType
}

const Pagination: FC<PaginationProps> = ({
  limit,
  setPerPage,
  page,
  setPage,
  maxData,
  type = 'TYPE_1',
}) => {
  const pageNum = useMemo(() => Math.ceil(maxData / limit), [maxData, limit])

  const pageList = useMemo(() => {
    const result: (number | undefined)[] = []
    if (pageNum === 0) return result
    const normalizedPage = Math.min(pageNum, Math.max(0, page))
    if (pageNum < 8)
      for (let i = 1; i <= pageNum; i++) {
        result.push(i)
      }
    else {
      if (normalizedPage > 4) result.push(1, undefined, Math.min(normalizedPage, pageNum - 3) - 1)
      else result.push(1, 2, 3, 4)
      if (normalizedPage + 3 < pageNum) {
        if (normalizedPage > 4) result.push(normalizedPage)
        result.push(Math.max(normalizedPage, 4) + 1, undefined, pageNum)
      } else result.push(pageNum - 3, pageNum - 2, pageNum - 1, pageNum)
    }
    return result
  }, [pageNum, page])

  return (
    <div className='flex flex-wrap pt-6'>
      <div className='flex items-center w-full md:w-auto'>
        <div className='relative'>
          {type === 'TYPE_1' && (
            <select
              className='pr-13 text-neutral-70 w-[72px] text-fs-10 py-2 px-3'
              autoComplete='off'
              value={limit}
              onChange={(e) => setPerPage(Number(e.currentTarget.value))}
            >
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
          )}

          {type === 'TYPE_2' && (
            <div className='font-semibold text-fs-10 text-neutral-70'>
              Display {page * limit + 1} -{' '}
              {(page + 1) * limit > maxData ? maxData : (page + 1) * limit} to {maxData} entries
            </div>
          )}
        </div>
      </div>


      {pageList.length > 0 ? (
        <ul className='flex justify-end flex-1 p-0 m-0 list-none flex-nowrap'>
          <li className='mr-3'>
            <button
              onClick={() => setPage(page - 1)}
              className={`text-neutral-60 font-medium text-fs-10 w-8 h-8 py-2.5 px-1 transition-all duration-300 rounded-xl ${
                page === 0 || page === 1
                  ? 'cursor-not-allowed'
                  : 'hover:bg-primary-light-1 hover:text-black'
              }`}
              disabled={page === 1}
            >
              <InlineSVG
                src={toAbsoluteUrl('/media/icons/IconPaginationLeft.svg')}
                className='w-full h-full'
              />
            </button>
          </li>
          {pageList.map((x, i) =>
            x === undefined ? (
              <li className='mr-3' key={`${x ? x : i + i}-dot`}>
                <button disabled className='font-medium text-neutral-60 text-fs-10'>
                  ...
                </button>
              </li>
            ) : (
              <li className='mr-3' key={x}>
                <button
                  onClick={() => setPage(x)}
                  className={`flex justify-center items-center transition-all duration-300 rounded-xl h-8 w-8 font-semibold text-fs-10 z-30 ${
                    page !== x
                      ? 'bg-neutral-10 text-neutral-90 hover:bg-primary-light-1 hover:text-black dark:text-black dark:bg-neutral-40 dark:hover:bg-primary-light-2 dark:hover:text-neutral-80'
                      : 'bg-danger text-white'
                  }`}
                >
                  {x}
                </button>
              </li>
            )
          )}
          <li>
            <button
              onClick={() => setPage(page + 1)}
              className={`text-neutral-60 font-medium text-fs-10 w-8 h-8 py-2.5 px-1 transition-all duration-300 rounded-xl ${
                page === pageNum
                  ? 'cursor-not-allowed'
                  : 'hover:bg-primary-light-1 hover:text-black'
              }`}
              disabled={page === pageNum}
            >
              <InlineSVG
                src={toAbsoluteUrl('/media/icons/IconPaginationRight.svg')}
                className='w-full h-full'
              />
            </button>
          </li>
        </ul>
      ) : null}
    </div>
  )
}

export default Pagination