import {FC, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {GButton, Card} from 'src/app/components/Libs'
import AuthScreens from '../../Screens'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {useHistory, useParams} from 'react-router-dom'
import BasicModal from 'src/app/components/Widgets/ModalCollection/BasicModal'
import {useFormik} from 'formik'
import {forgotPassword} from '../../../permissions/services/Permission.services'

const InvalidToken: FC = () => {
  const history = useHistory()
  const [resendModal, setResendModal] = useState<boolean>(false)
  const { email } = useParams<{ email: string;}>()

  const initialValues = {
    email: email,
  }

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return forgotPassword(payload)
    },
    onSuccess: () => {
      setResendModal(true)
    },
    onError: (e: any) => {
      const status = e.response.status

      if (status >= 500) {
        formik.setStatus('Service Temporarily Unavailable. Please try again later.')
      } else {
        formik.setErrors({
          email: 'Email not found, please use registered email.'
        });
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => submitFn.mutate(values),
  })

  return (
    <div data-testid='invalid-token' className='flex w-screen h-screen bg-neutral-20 justify-center content-center items-center'>
      <Card.Root className='w-4/12 h-4/6 rounded-xl'>
        <Card.Body>
          <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
            <div className='flex flex-col justify-center text-center h-full'>
              <div className='w-full flex justify-center'>
                <img src={toAbsoluteUrl('/KFC_jagonya_ayam.png')} alt='KFC Logo' className='w-20' />
              </div>
              <div className='w-full flex justify-center mb-4'>
                <img src={toAbsoluteUrl('/kfc_invalid_link.svg')} alt='KFC Invalid' className='w-44' />
              </div>
              <div className='mb-4 font-national2Condensed font-bold text-fs-4'>Password Reset Link Expired</div>
              <div className='mb-4 text-neutral-50'>
              Sorry, the password reset link you clicked on has expired. Please request a new reset link to regain access to your account.
              </div>
              <GButton type='submit' className='w-full' loading={submitFn.isLoading} disabled={submitFn.isLoading}>Request New Link</GButton>
            </div>
          </form>
        </Card.Body>
      </Card.Root>
      <BasicModal
        show={resendModal}
        headerWeight='bold'
        handleClose={() => {}}
        handleSuccess={() =>
          history.replace(AuthScreens.LOGIN_EMAIL.PATH)

        }
        header='Please Check Your Inbox'
        positiveLabel='Back to Sign in'
        loading={false}
        >
          <div className='text-fs-6'>
            Please click the link sent to your inbox to reset your password.
          </div>
      </BasicModal>
    </div>
  )
}

export default InvalidToken
