import {useEffect, useState} from 'react'

type FilterValue = string | boolean | object | undefined

interface FilterValues {
  [key: string]: FilterValue
}
export interface FilterCountResult {
  tempFilterCount: number
  setTempFilterCount: React.Dispatch<React.SetStateAction<number>>
}

export const useFilterCount = (deps: {values: FilterValues}): FilterCountResult => {
  const [tempFilterCount, setTempFilterCount] = useState<number>(0)

  useEffect(() => {
    const updatedValues = {...deps.values}
    delete updatedValues.start_date
    delete updatedValues.end_date

    const mapFilteredValue: FilterValue[] = Object.values(updatedValues)

    const count = mapFilteredValue.reduce((accumulator, value) => {
      if (typeof value === 'string' && value !== '') return accumulator + 1

      if (typeof value === 'boolean' && value !== undefined) return accumulator + 1

      if (typeof value === 'object') {
        if (Object.prototype.toString.call(value) === '[object Date]') return accumulator + 1
        else if (Array.isArray(value)) {
          const arrayFilteredCount = value.filter((val) => val !== undefined).length
          return accumulator + arrayFilteredCount
        }
      }

      return accumulator
    }, 0)

    setTempFilterCount(count)
  }, [deps])

  return {tempFilterCount, setTempFilterCount}
}
