export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

export function getModuleName(url: string) {
  const modulePatterns = [
    { pattern: 'promotion-management/section-banner/', moduleName: 'section-banner' },
    { pattern: 'promotion-management/banner/', moduleName: 'banner' },
  ];

  for (const { pattern, moduleName } of modulePatterns) {
    if (url.includes(pattern)) {
      return moduleName;
    }
  }
  
  return null; 
}
