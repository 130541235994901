import { FC, ReactNode } from 'react'
import { cn } from 'src/app/utils/cn-utils'
import { twMerge } from 'tailwind-merge'
import { FormError } from '../FormError'
import { CheckboxItemProps, DotSizes, FormCheckboxProps, SwitchSizes } from './Checkbox.types'

const Container: FC<{ children: ReactNode, disabled?: boolean }> = ({ children, disabled }) => {
  return (
    <div className={twMerge("flex items-center flex-1", disabled && "opacity-70")}>
      <label className='flex items-center'>{children}</label>
    </div>
  )
}

const Placeholder: FC<{ children?: string }> = ({ children }) => {
  return <div className='ml-4 font-medium text-neutral-80 text-fs-9'>{children}</div>
}

const ClassicCheckbox: FC<CheckboxItemProps> = ({ placeholder, ...rest }) => {
  return (
    <Container disabled={rest.disabled}>
      <input type='checkbox' className={twMerge(rest.disabled && "cursor-not-allowed")} {...rest} />
      {placeholder && <Placeholder>{placeholder}</Placeholder>}
    </Container>
  )
}

const SwitchCheckbox: FC<CheckboxItemProps> = ({ placeholder, sizebox = 'large', ...rest }) => {
  return (
    <Container>
      <div className='flex items-center cursor-pointer'>
        <div className='relative'>
          <input type='checkbox' className='sr-only' {...rest} />
          <div className={cn('backside bg-neutral-40 rounded-full', SwitchSizes[sizebox])}></div>
          <div
            className={cn(
              'dot absolute left-[2px] top-[2px] bg-white rounded-full transition duration-200',
              DotSizes[sizebox]
            )}
          ></div>
        </div>
      </div>
      {placeholder && <Placeholder>{placeholder}</Placeholder>}
    </Container>
  )
}

const Checklist: FC<CheckboxItemProps> = ({ className, onChange, options, disabled }) => {
  return (
    <div className={twMerge("grid grid-cols-4 gap-5", className)}>
      {options?.map((item) => (
        <ClassicCheckbox
          placeholder={item.label}
          disabled={disabled}
          onChange={onChange}
          key={item.value}
          {...item}
        />
      ))}
    </div>
  );
};

export const FormChecklist: FC<FormCheckboxProps> = ({
  error,
  touched,
  variant,
  checkedValue,
  changedValue,
  activeValue,
  inactiveValue,
  ...rest
}) => {
  return (
    <div className="relative">
      <Checklist {...rest} />
      {touched && error && <FormError>{error}</FormError>}
    </div>
  );
}

export const FormCheckbox: FC<FormCheckboxProps> = ({
  className,
  error,
  touched,
  variant = 'switch',
  checkedValue,
  changedValue,
  activeValue,
  inactiveValue,
  height = 'min-h-[44px]',
  options,
  ...rest
}) => {
  return (
    <div className={cn('flex items-center', height, className)}>
      {variant === 'classic' && (
        <ClassicCheckbox
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}
      {variant === 'switch' && (
        <SwitchCheckbox
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
