import clsx from 'clsx'
import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { useDebounceEffect } from 'src/app/hooks/debounce-hook'
import { FormText, GButton } from '../Libs'
import QueryTable from '../Libs/Table/QueryTable/QueryTable'
import TableError from '../Libs/Table/TableError'
import CalendarModal from './ModalCollection/CalendarModal'
import TableFilter from './TableFilter'
interface EmptyTable {
  title: string
  body: string
}

interface Props {
  renderTable: any
  headers: any
  generatedForm?: any
  filterCount?: number
  formik?: any
  resetFilterFn?: any
  isShow?: any
  setIsShow?: any
  isShowCalendar?: any
  setIsShowCalendar?: any
  isShowDownload?: any
  setIsShowDownload?: any
  isSearch?: boolean
  isFilter?: boolean
  isPagination?: boolean
  filterCalendar?: boolean
  filterDownload?: boolean
  stickyHeader?: boolean
  titleCalendar?: string
  placeholder?: string
  notFoundType?: 'TYPE_1' | 'TYPE_2'
  onResetFilterClick?: MouseEventHandler
  buttonJustify?: string
  buttonWidth?: string
  styleTitleFilter?: 'bold' | 'normal'
  emptyTable?: EmptyTable
  downloadButton?: React.ReactNode
}

const BodyTableTemplate: FC<Props> = ({
  formik,
  generatedForm,
  resetFilterFn,
  renderTable,
  headers,
  isShow,
  setIsShow,
  isShowCalendar,
  setIsShowCalendar,
  isShowDownload,
  setIsShowDownload,
  filterCount = 0,
  isSearch = true,
  isFilter = true,
  filterCalendar = false,
  filterDownload = false,
  stickyHeader = false,
  titleCalendar,
  placeholder = 'Search',
  notFoundType = 'TYPE_1',
  isPagination = true,
  buttonJustify,
  buttonWidth,
  onResetFilterClick,
  styleTitleFilter = 'normal',
  emptyTable,
  downloadButton = null
}) => {
  const searchFn = useDebounceEffect(
    useCallback(
      (search: string) => renderTable.setQuery((prev: any) => ({ ...prev, search })),
      //eslint-disable-next-line
      [renderTable.setQuery]
    ),
    500
  )
  
  const [dateRange, setDateRange] = useState<any>()
  const [selectedDate, setSelectedDate] = useState<any>()

  useEffect(() => {
    if (dateRange !== undefined && dateRange.length === 2) {
      const startDate = dateRange[0].format('YYYY-MM-DD')
      const endDate = dateRange[1].format('YYYY-MM-DD')

      formik.setFieldValue('start_date', startDate)
      formik.setFieldValue('end_date', endDate)
    }
  }, [dateRange, formik?.values])

  const onResetCalendar = () => {
    setDateRange('')
    setSelectedDate('')
    setIsShowCalendar(false)
    formik.setFieldValue('start_date', '')
    formik.setFieldValue('end_date', '')
    formik.handleSubmit()
  }

  const onSubmitCalendar = () => {
    setSelectedDate(dateRange)
    setIsShowCalendar(false)
    formik.handleSubmit()
  }

  return (
    <>
      {!renderTable.isError ? (
        <>
          {(!renderTable.isFetching || renderTable.state.query.search !== '') && (
            <div className='flex items-center justify-between'>
              {(isFilter || filterCalendar || filterDownload) && (
                <div className='flex'>
                  {isFilter && (
                    <TableFilter
                      filterCount={filterCount}
                      formik={formik}
                      generatedForm={generatedForm}
                      resetFilterFn={resetFilterFn}
                      isShow={isShow}
                      setIsShow={setIsShow}
                      buttonJustify={buttonJustify}
                      buttonWidth={buttonWidth}
                      styleTitleFilter={styleTitleFilter}
                    />
                  )}
                  {filterCalendar && (
                    <GButton
                      size='large'
                      variant='OUTLINED'
                      startIcon='IconCalendarRounded'
                      className={clsx('bg-netral border-primary hover:bg-primary-light-1 ml-4', { 'bg-primary-light-2 border-none': selectedDate })}
                      onClick={() => setIsShowCalendar(!isShowCalendar)}
                    >
                      {selectedDate ? (selectedDate.toString().replace(',', ' - ')) : 'Select Date'}
                    </GButton>
                  )}
                  {filterDownload && downloadButton === null &&(
                    <GButton
                      size='large'
                      variant='OUTLINED'
                      startIcon='IconDownload'
                      className='ml-4 bg-netral border-primary hover:bg-primary-light-1'
                      onClick={() => setIsShowDownload(!isShowDownload)}
                    >
                      Download
                    </GButton>
                  )}
                  {filterDownload && downloadButton !== null && (
                    <>
                      {downloadButton}
                    </>
                  )}
                </div>
              )}
              {isSearch && (
                <div className='md:min-w-[400px] mr-4'>
                  <FormText
                    placeholder={placeholder}
                    prependInnerIcon='IconSearch'
                    appendInnerIcon={renderTable.state.query.search ? 'IconClose' : undefined}
                    appendInnerClassname='text-neutral-60'
                    clickAppendInnerIcon={() => searchFn('')}
                    onChange={(e) => searchFn(e.target.value)}
                    name='search'
                  />
                </div>
              )}
            </div>
          )}

          <QueryTable
            render={renderTable}
            headers={headers}
            notFoundType={notFoundType}
            onResetFilterClick={onResetFilterClick}
            showPagination={isPagination}
            isStickyHeader={stickyHeader}
            isFilter={(isFilter || filterCalendar || filterDownload || isSearch)}
            emptyTable={emptyTable}
          />

          <CalendarModal
            title={titleCalendar || 'Date Filter'}
            isShowCalendar={isShowCalendar}
            setIsShowCalendar={setIsShowCalendar}
            setSelectedDate={setSelectedDate}
            value={dateRange}
            setValue={setDateRange}
            reset={onResetCalendar}
            apply={onSubmitCalendar}
          />
        </>
      ) : (
        <TableError />
      )}
    </>
  )
}

export default BodyTableTemplate
