import { FC } from 'react'
import { Tooltip } from 'react-tooltip'
import { cn } from 'src/app/utils/cn-utils'
import GIcon from '../../../Icon/GIcon'
import { FormError } from '../FormError'
import { FormHelper } from '../FormHelper'
import { FormTextProps } from './Text.types'

export const FormText: FC<FormTextProps> = ({
  className,
  inputClassName,
  error,
  touched,
  type = 'text',
  prependIcon,
  prependInnerIcon,
  appendIcon,
  appendInnerIcon,
  prependClassname,
  prependInnerClassname,
  appendClassname,
  appendInnerClassname,
  clickPrependIcon,
  clickPrependInnerIcon,
  clickAppendIcon,
  clickAppendInnerIcon,
  prependNode,
  appendNode,
  tooltip,
  prefix,
  suffix,
  appendMaxLength,
  helperText,
  ...rest
}) => {
  const defaultClassname = 'cursor-default w-5 h-5'
  return (
    <div className={className}>
      <div className='relative flex items-center'>
        {prependNode && <div className={cn('ml-2')}>{prependNode}</div>}
        {prependIcon && (
          <GIcon
            icon={prependIcon}
            className={cn(defaultClassname, 'mr-2', prependClassname)}
            onClick={clickPrependIcon}
          />
        )}
        <div className='relative flex items-center flex-grow'>
          {prependInnerIcon && (
            <GIcon
              icon={prependInnerIcon}
              className={cn(defaultClassname, 'absolute left-3', prependInnerClassname)}
              onClick={clickPrependInnerIcon}

            />
          )}
          {prefix && (
            <div
              data-testid='formtext-prefix'
              className='absolute h-5 font-medium left-3 text-fs-9'
            >
              {prefix}
            </div>
          )}
          <input
            type={type}
            className={cn(
              {
                'border border-solid border-danger focus:border focus:border-solid focus:border-danger':
                  touched && error,
              },
              { 'pl-10': prependInnerIcon ?? prefix },
              { 'pr-10': appendInnerIcon ?? suffix },
              inputClassName,
              'rounded-xl'
            )}
            {...rest}
          />
          {suffix && (
            <div
              data-testid='formtext-suffix'
              className='absolute h-5 font-medium min-w-5 right-4 text-fs-9'
            >
              {suffix}
            </div>
          )}
          {appendInnerIcon && (
            <GIcon
              icon={appendInnerIcon}
              className={cn(defaultClassname, 'absolute right-4', appendInnerClassname)}
              onClick={clickAppendInnerIcon}
            />
          )}
          {appendMaxLength && rest.maxLength && !appendInnerIcon && (
            <div className='absolute h-5 font-medium min-w-5 right-4 text-fs-9'>
              {rest.value?.toLocaleString().length}/{rest.maxLength}
            </div>
          )}
        </div>
        {appendIcon && (
          <GIcon
            icon={appendIcon}
            className={cn(defaultClassname, 'ml-2', appendClassname)}
            onClick={clickAppendIcon}
          />
        )}
        {appendNode && <div className={cn('ml-2')}>{appendNode}</div>}

        {tooltip && (
          <Tooltip anchorSelect={`.${appendInnerClassname}`}>
            {tooltip}
          </Tooltip>
        )}
      </div>
      <div className="space-y-1">
        {touched && error && <FormError>{error}</FormError>}
        <FormHelper>{helperText}</FormHelper>
      </div>
    </div>
  )
}
