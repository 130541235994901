import { FC } from 'react'
import { formatDateWib } from 'src/app/utils/input-utils'

const HandleDateTime: FC<{ data?: any }> = ({ data }) => {
  if (data.includes('undefined')) return <>-</>

  const dateTime = formatDateWib(data, true)

  if (!dateTime) return null

  const [date, time] = dateTime.split(' ')

  if (!date || !time) return null

  return (
    <div className="flex flex-col">
      <div>
        {date}
      </div>
      <div className="text-fs-9">
        {time}
      </div>
    </div>
  )
}


export default HandleDateTime
