import {FC} from 'react'

const TableNotFoundSearch: FC = () => {
  return (
    <div className='min-h-[196px] flex items-center justify-center flex-col'>
      <div className='font-medium text-fs-5'>Result not found</div>
      <div className='mt-3 text-neutral-80'>
        Entering a new keyword or try rewording your search.
      </div>
    </div>
  )
}

export default TableNotFoundSearch
