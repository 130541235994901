import clsx from "clsx";
import { FC } from "react";
import { GButton } from "../Libs";
import FilterCard from "./FilterCard";

interface TableFilterProps {
  filterCount: number
  formik: any
  generatedForm: any
  resetFilterFn: any
  isShow: any
  setIsShow: any
  buttonJustify?: string
  buttonWidth?: string
  styleTitleFilter?: 'bold' | 'normal'
}

const TableFilter: FC<TableFilterProps> = ({ filterCount, formik, generatedForm, resetFilterFn, isShow, setIsShow, buttonJustify, buttonWidth, styleTitleFilter }) => {
  return (
    <div className='relative'>
      <GButton
        size='large'
        variant='OUTLINED'
        startIcon='IconFilter'
        className={clsx('bg-netral border-primary hover:bg-primary-light-1', {
          'bg-primary-light-2': filterCount > 0
        })}
        onClick={() => setIsShow(!isShow)}
      >
        {filterCount > 1 && `${filterCount}`} Filter {filterCount > 0 && 'Applied'}
      </GButton>
      <FilterCard
        isShow={isShow}
        setIsShow={setIsShow}
        formik={formik}
        generatedForm={generatedForm}
        resetFilterFn={resetFilterFn}
        buttonJustify={buttonJustify}
        buttonWidth={buttonWidth}
        maxHeight={true}
        styleTitleFilter={styleTitleFilter}
      />
    </div>
  )
}

export default TableFilter