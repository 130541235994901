import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { DragHandle } from "./DragHandle"
import { Row } from "react-table"
import { FC } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";

interface DraggableTableRowProps {
  row: Row<{
    id: UniqueIdentifier; original: { id: string }
  }>
}

export const DraggableTableRow: FC<DraggableTableRowProps> = ({ row }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...row.getRowProps()} className="border-b border-neutral-30">
      {isDragging ? (
        <td colSpan={row.cells.length}>&nbsp;</td>
      ) : (
        row.cells.map((cell, i) => {
          if (i === 0) {
            return (
              <td {...cell.getCellProps()} className="p-2">
                <DragHandle {...attributes} {...listeners} />
                <span>{cell.render("Cell")}</span>
              </td>
            );
          }
          return (
            <td {...cell.getCellProps()} className="p-2">
              {cell.render("Cell")}
            </td>
          );
        })
      )}
    </tr>
  );
};
