import {ChangeEvent, FC, useCallback, useEffect} from 'react'
import {addThousandSeparator, isNumeric, trimNonNumeric} from 'src/app/utils/input-utils'
import {FormText, FormTextProps} from '../partials'

interface FormRupiahProps extends FormTextProps {
  onChangeValue: (value: string, first?: boolean) => void
  value: string
}

const FormRupiah: FC<FormRupiahProps> = ({value, onChangeValue, onChange, ...rest}) => {
  const setValue = useCallback(
    (trimmed: string, first?: boolean) => {
      if (onChangeValue) onChangeValue(trimmed, first)
    },
    [onChangeValue]
  )

  const onChangeWrapper = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(trimNonNumeric(e.currentTarget.value))
    },
    [setValue]
  )

  useEffect(() => {
    if (!isNumeric(value)) setValue(trimNonNumeric(value), true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormText
      value={value ? addThousandSeparator(value): ''}
      onChange={onChangeWrapper}
      prefix='Rp'
      {...rest}
    />
  )
}

export {FormRupiah}
