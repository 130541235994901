import { FC, useState, useCallback, useEffect } from "react"
import BodyTableTemplate from 'src/app/components/Widgets/BodyTableTemplate'
import { useFormik } from 'formik'
import { useFilterCount } from 'src/app/hooks/filter-count-hook'
import { usePaginationQuery } from 'src/app/hooks/pagination-query-hook'
import { useClickOutside } from 'src/app/hooks/click-outside-hook'
import { FilterBanner } from "../../../templates/banner"
import { SectionBannerModel, SectionBannerQuery } from "../../../models/SectionBanner.model"
import { LIST_BANNER_KEY, getListBanner } from "../../../services/Banner.services"
import { useTableSectionBanner } from "../../../templates/section_banner/table-data"

const ModalListBanner: FC<{ onChange: (data: any) => void }> = ({ onChange }) => {
  const [, isShow, setIsShow] = useClickOutside(false)
  const [, isShowCalendar, setIsShowCalendar] = useClickOutside(false)
  const [data, setData] = useState([] as object[]);
  const [maxData, setMaxData] = useState(0);

  const formik = useFormik({
    initialValues: FilterBanner,
    onSubmit: (values) => applyFilterFn(values),
  })

  const renderTable = usePaginationQuery<SectionBannerModel[], SectionBannerQuery>({
    queryKey: [LIST_BANNER_KEY],
    queryFn: async (params) => {
      const payload: any = await getListBanner({ ...params, limit: 99999, statuses: ['active'] }, 'section-banner')
      const content = payload.data.response_output?.list?.content || []
      const pagination = payload.data.response_output?.list?.pagination
      const max = payload.data.response_output?.list?.meta?.maximum_featured

      setTotal(pagination?.total_rows as number)
      setData(content)
      setMaxData(max ?? 5)
      return content
    },
  })

  const { tempFilterCount } = useFilterCount(formik)

  const { setQuery, state, setTotal } = renderTable

  const applyFilterFn = useCallback(
    (params: SectionBannerQuery) => {
      const { start_date, end_date } = params
      setIsShow(false)
      setQuery((prev) => ({ ...prev, start_date, end_date }))
    },
    [setQuery, setIsShow, tempFilterCount]
  )

  const { headers, selectedRows, listBannerActive } = useTableSectionBanner(
    state,
    data,
    maxData
  )

  useEffect(() => {
    onChange(selectedRows)
  }, [selectedRows, listBannerActive])

  return (
    <div>
      <BodyTableTemplate
        isFilter={false}
        renderTable={renderTable}
        formik={formik}
        generatedForm={[]}
        headers={headers}
        isShow={isShow}
        setIsShow={setIsShow}
        filterCalendar={true}
        isShowCalendar={isShowCalendar}
        setIsShowCalendar={setIsShowCalendar}
        placeholder='Search Section List Name'
        titleCalendar='Section Date Filter'
        isPagination={false}
        stickyHeader={true}
      />
    </div>
  )
}


export default ModalListBanner