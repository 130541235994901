import CreateNewPassword from './page/CreateNewPassword/CreateNewPassword'
import ForgotPassword from './page/ForgotPassword/ForgotPassword'
import LoginEmail from './page/LoginEmail/LoginEmail'
import InvalidToken from './page/InvalidToken/InvalidToken'

const AuthScreens = {
  LOGIN_EMAIL: {
    COMPONENT: LoginEmail,
    PATH: '/auth/login-email',
  },
  FORGOT: {
    COMPONENT: ForgotPassword,
    PATH: '/auth/forgot-password',
  },
  CREATE_PASSWORD: {
    COMPONENT: CreateNewPassword,
    PATH: '/auth/create-password',
  },
  SET_PASSWORD: {
    COMPONENT: CreateNewPassword,
    PATH: '/auth/set-password',
  },
  INVALID_TOKEN: {
    COMPONENT: InvalidToken,
    PATH: '/invalid/token/:email',
    BASE_PATH: '/invalid/token',
    buildPath: (email: string) => `/invalid/token/${email}`,
  },
}

export default AuthScreens
