import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { cn } from 'src/app/utils/cn-utils'

export interface CardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

const CardRoot: FC<CardProps> = ({ children, className, ...rest }) => {
  return (
    <div
      className={cn(
        'relative bg-white flex flex-col min-w-0 shadow-[0_0_12px_0_rgba(33,37,41,0.1)] rounded-lg',
        'dark:bg-inverse-primary-light-2 dark:text-neutral-20',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

const CardHeader: FC<CardProps> = ({ children, className, ...rest }) => {
  return (
    <div
      className={cn(
        'px-9 border-b border-neutral-20 flex-nowrap min-h-[72px]',
        'dark:border-neutral-80',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

const CardHeaderTitle: FC<CardProps> = ({ children, className }) => {
  return <div className={cn('flex-1 font-semibold text-fs-6', className)}>{children}</div>
}

const CardBody: FC<CardProps> = ({ children, className, ...rest }) => {
  return (
    <div className={cn('px-9 py-6 min-h-[72px] flex-[1_1_auto]', className)} {...rest}>
      {children}
    </div>
  )
}

const CardFooter: FC<CardProps> = ({ children, className, ...rest }) => {
  return (
    <div
      className={cn(
        'px-9 border-t border-neutral-20 flex-nowrap min-h-[24px]',
        'dark:border-neutral-80',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export const Card = {
  Root: CardRoot,
  Header: CardHeader,
  HeaderTitle: CardHeaderTitle,
  Body: CardBody,
  Footer: CardFooter,
}
