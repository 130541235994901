import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const OrderScreens = {
  ORDER_LIST: {
    PATH: '/order-management/list',
    HEADER_NAME: 'Order List',
    TITLE: 'Order Management',
    TITLE_ALT: 'Order List',
    PERMISSION: 'order.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ORDER: {
    PATH: '/order-management/list/detail/:id/:tab',
    TITLE: 'Order Detail',
    TITLE_ALT: 'Order Detail',
    PERMISSION: 'order.view-detail',
    buildPath: (id: string, tab: string) => `/order-management/list/detail/${id}/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
}

const orderBreadcrumbs = generateBreadcrumb([OrderScreens.ORDER_LIST])

OrderScreens.DETAIL_ORDER.breadcrumbs = () => orderBreadcrumbs

export const DetailOrderTab = {
  ORDER: {
    PATH: '/order-management/list/detail/:id/order',
    TITLE: 'Order',
    TITLE_ALT: 'Order',
    LABEL: 'Order',
    SLUG: 'order',
  },
  STATUS: {
    PATH: '/order-management/list/detail/:id/status',
    TITLE: 'Status',
    TITLE_ALT: 'Status',
    LABEL: 'Status',
    SLUG: 'status',
  },
  ORDER_DETAILS: {
    PATH: '/order-management/list/detail/:id/order-details',
    TITLE: 'Order Details',
    TITLE_ALT: 'Order Details',
    LABEL: 'Order Details',
    SLUG: 'order-details',
  },
  PAYMENT_DETAILS: {
    PATH: '/order-management/list/detail/:id/payment-details',
    TITLE: 'Payment Details',
    TITLE_ALT: 'Payment Details',
    LABEL: 'Payment Details',
    SLUG: 'payment-details',
  },
  DELIVERY_INFO: {
    PATH: '/order-management/list/detail/:id/delivery-info',
    TITLE: 'Delivery Info',
    TITLE_ALT: 'Delivery Info',
    LABEL: 'Delivery Info',
    SLUG: 'delivery-info',
  },
  FEEDBACK: {
    PATH: '/order-management/list/detail/:id/order-feedback',
    TITLE: 'Order Feedback',
    TITLE_ALT: 'Order Feedback',
    LABEL: 'Order Feedback',
    SLUG: 'order-feedback',
  }
}

export default OrderScreens