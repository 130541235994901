import React, { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface MasterContextModel {
  minimize?: boolean
  setMinimize: (_value: boolean) => void
  currentTheme?: string
  changeCurrentTheme: (_value: 'light' | 'dark') => void
  isSidebarOpen?: boolean
  acitveSidebar?: string
  toggleActiveSidebar?: (value: string) => void
}

const MasterContext = createContext({} as MasterContextModel)

const MasterProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { pathname } = useLocation()

  const splitPathname = '/' + pathname.split('/')[1]
  const [acitveSidebar, setActiveSidebar] = useState<string | undefined>(splitPathname)
  const [minimize, setMinimize] = useState<boolean>(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

  const toggleActiveSidebar = (value: string) => {
    const toggleSidebar = acitveSidebar === value ? undefined : value;
    setActiveSidebar(toggleSidebar);
  }

  const changeCurrentTheme = (newTheme: 'light' | 'dark') => {
    setTheme(newTheme)
    localStorage.setItem('theme', newTheme)
  }

  useEffect(
    () => setActiveSidebar(splitPathname),
    [splitPathname]
  );

  useEffect(() => {
    if (theme === 'light') document.body.classList.remove('dark')
    else document.body.classList.add('dark')
  }, [theme])

  const value: MasterContextModel = useMemo(
    () => ({
      isSidebarOpen: !!acitveSidebar,
      acitveSidebar,
      toggleActiveSidebar,
      minimize,
      setMinimize,
      currentTheme: theme,
      changeCurrentTheme,
    }),
    [minimize, theme, acitveSidebar]
  )

  return <MasterContext.Provider value={value}>{children}</MasterContext.Provider>
}

function useMasterLayout() {
  return useContext(MasterContext)
}

export { MasterProvider, useMasterLayout }

