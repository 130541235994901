import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FileRejection } from 'react-dropzone'
import { PageToasts } from '../context/ToastContext'
import { VoucherType } from '../modules/general/templates'

dayjs.extend(utc)
dayjs.extend(timezone)

export const phoneRegExp = /^62\d{8,}$/gm
export const emailRegExp = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/

export const isNumeric = (text: string) => Boolean(text.match(/^\d+$/))
export const trimNonNumeric = (text: string) => text.replace(/\D/g, '')
export const trimPhoneNumber = (text: string, prefix: string) => {
  let result = text
  if (text.startsWith('+') && text.length >= prefix.length) result = text.slice(prefix.length + 1)
  if (result.startsWith('62')) {
    result = result.slice(2)
  }
  if (result[0] === '0') {
    result = result.slice(1)
  }
  return trimNonNumeric(result)
}

export const needAddPrefix = (text: string) => !text.startsWith('62') || text.startsWith('0')
export const addPhonePrefix = (text: string) => {
  let result = text
  if (result[0] === '0') {
    result = result.slice(1)
  }
  return `62${trimNonNumeric(result)}`
}

export const trimPhoneBasic = (text: string) => {
  let result = text
  if (result.startsWith('62')) {
    result = result.slice(2)
  }
  return trimNonNumeric(result)
}

export const addThousandSeparator = (numberString: string): string => {
  if (!/^\d+(\.\d+)?$/.test(numberString)) {
    throw new Error('Input must be a valid number string');
  }

  const [integerPart, decimalPart] = numberString.split('.');

  const formattedIntegerPart = formatCurrency(integerPart);

  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
};

export const formatCurrency = (text: string): string => {
  return text
    .split('')
    .reverse()
    .map((digit, index) => (index > 0 && index % 3 === 0 ? digit + '.' : digit))
    .reverse()
    .join('');
}

export const formatMoney = (number?: number, currency = '') =>
  `${number && number < 0 ? '-' : ''}${currency} ${Math.abs(number ?? 0).toLocaleString('id-ID')}`

export const formatRupiah = (number?: number, invalid?: boolean, currency = 'Rp') => {
  if (invalid) return `${currency}-`
  return formatMoney(number, currency)
}

export const formatRupiahSpaced = (number?: number, invalid?: boolean, currency = 'Rp ') =>
  formatRupiah(number, invalid, currency)

export const formatPhoneNumber = (phone: string): string => {
  if (!phone) return '';

  const cleanedNumber = phone.replace(/\D/g, '');
  const prefixedNumber = cleanedNumber.startsWith('62')
    ? cleanedNumber
    : '62' + cleanedNumber.replace(/^0+/, '');

  return '+' + prefixedNumber.replace(/(\d{2})(\d{3})(\d{4})(\d{4})/, '$1 $2 $3 $4').trim();
}

export const appendFormArray = (
  formData: FormData,
  name: string,
  props: (string | undefined)[]
) => {
  props.forEach((value) => {
    if (value) formData.append(`${name}[]`, value)
  })
}

export const appendAllFormData = (
  formData: FormData,
  names: { key: string; name: string }[],
  inputs: Record<any, any>
) => {
  names.forEach((name) => {
    const data = inputs[name.key]
    if (data || typeof data === 'boolean') {
      if (Array.isArray(data)) appendFormArray(formData, name.name, data)
      else formData.append(name.name, data)
    }
  })
}

export const deleteFilesFormData = (
  formData: FormData,
  names: { key?: string; name: string }[],
  inputs: Record<any, any>,
  delete_field: string = 'delete_files'
) => {
  names.forEach((name) => {
    if (!inputs[name.name] && (!name.key || !!inputs[name.key])) {
      formData.append(`${delete_field}[]`, name.name)
    }
  })
}

export const appendAllRecordData = (
  formData: Record<string, string>,
  names: { key: string; name: string }[],
  inputs: Record<any, any>
) => {
  names.forEach((name) => {
    const data = inputs[name.key]
    if (data || typeof data === 'boolean') {
      formData[name.name] = data
    }
  })
}

export const copyText = (text: string, listener: (toast: PageToasts) => void) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      listener({ scheme: 'info', text: 'Text copied.', fixed: true })
    })
    .catch(() => {
      listener({ scheme: 'danger', text: 'Failed to copy text.', fixed: true })
    })
}

export const openWhatsapp = (phone: string) => {
  window.open(
    `https://api.whatsapp.com/send?phone=62${encodeURI(trimPhoneNumber(phone, '62'))}`,
    '_blank'
  )
}

export const createBase64 = (file: File | Blob) => {
  const _URL = window.URL || window.webkitURL
  return _URL.createObjectURL(file)
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getExtensionFromMime = (mime: string) => {
  switch (mime) {
    case 'image/png':
      return 'png'
    case 'image/gif':
      return 'gif'
    case 'text/csv':
      return 'csv'
    default:
      return 'jpg'
  }
}

export const humanFileSize = (b: number): string => {
  let u = 0,
    s = 1024
  while (b >= s || -b >= s) {
    b /= s
    u++
  }
  return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'B'
}

export const validateBlob = async ({
  result,
  error,
  field,
  preview_field,
  setFieldTouched,
  setFieldError,
  setFieldValue,
  png = true,
}: {
  result?: Blob | null
  error?: any
  field: string
  preview_field?: string
  setFieldTouched?: Function
  setFieldError: Function
  setFieldValue: Function
  png?: boolean
}) => {
  if (error || !result) {
    setFieldError(field, error)
    if (setFieldTouched) setFieldTouched(field, true)
    return
  }
  await setFieldValue(field, new File([result], png ? 'image.png' : 'image.jpg'))
  await setFieldValue(preview_field, createBase64(result), true)
}

//maxSize: in MB, dimension: in pixel
const setFieldTouchedFn = (field: any, setFieldTouched: any) => {
  if (setFieldTouched) setFieldTouched(field, true);
};

const validateFileSize = (image: any, maxSize: any, field: any, setFieldTouched: any, setFieldError: any, errorSize: any) => {
  if (maxSize && image.size / 1024 / 1024 > maxSize) {
    setFieldTouchedFn(field, setFieldTouched);
    setFieldError(field, (errorSize)? errorSize : `Max upload size is ${maxSize}MB.`);
    return false;
  }
  return true;
};

const validateImageRatio = (img: any, ratio: any, field: any, setFieldError: any, setFieldTouched: any, errorMessage: string | undefined) => {
  const allowedRatios: { [key: string]: number } = {
    '1/1': 1,
    '16/9': 16 / 9,
    '9/16': 9 / 16
  };

  if (!allowedRatios[ratio]) {
    console.error(`Invalid ratio provided: ${ratio}`);
    return false;
  }

  const currentRatio = img.width / img.height;
  if (currentRatio.toFixed(2) !== allowedRatios[ratio].toFixed(2)) {
    setFieldTouchedFn(field, setFieldTouched);
    setFieldError(field,(errorMessage) ? errorMessage : `Image ratio must be ${ratio}`);
    return false;
  }
  return true;
};

const validateImageDimensions = (img: any, dimension: any, field: any, setFieldError: any) => {
  if (dimension && (img.width !== dimension.width || img.height !== dimension.height)) {
    setFieldError(field, `Image size must be ${dimension.width}x${dimension.height}`);
    return false;
  }
  return true;
};

const validateMimeType = (image: any, mime: string[], field: any, setFieldTouched: any, setFieldError: any, errorFormat: any) => {
  if (Array.isArray(mime) && image && !mime.includes(image.type)) {
    setFieldTouchedFn(field, setFieldTouched);
    setFieldError?.(field, (errorFormat) ? errorFormat : `File format not supported.`);
    return false;
  }
  return true;
}

export interface ValidateImageParams {
  image?: File
  rejects?: FileRejection[]
  field?: string
  preview_field?: string
  setFieldTouched?: Function
  setFieldError?: Function
  setFieldValue?: Function
  maxSize?: number
  dimension?: {
    width: number
    height: number
  }
  errorMessage?: string
  ratio?: "16/9" | "1/1" | "9/16"
  errorRatio?: string
  errorFormat?: string
  errorSize?: string
  adjustRatio?: boolean
  mime?: string[]
}

const adjustImageRatio = (file: any, targetRatio: number, backgroundColor = 'black') => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const img: any = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const originalWidth = img.width;
        const originalHeight = img.height;
        const originalRatio = originalWidth / originalHeight;

        let canvasWidth, canvasHeight;

        if (originalRatio > targetRatio) {
          // Lebih lebar dari rasio yang diinginkan
          canvasWidth = originalWidth;
          canvasHeight = originalWidth / targetRatio;
        } else {
          // Lebih tinggi dari rasio yang diinginkan
          canvasWidth = originalHeight * targetRatio;
          canvasHeight = originalHeight;
        }

        const canvas = document.createElement('canvas');
        const ctx: any = canvas.getContext('2d');

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Fill the background
        if (file.type === 'image/png') {
          ctx.fillStyle = 'transparent';
        } else {
          ctx.fillStyle = backgroundColor;
        }
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);

        // Calculate the position to center the image on the canvas
        const x = (canvasWidth - originalWidth) / 2;
        const y = (canvasHeight - originalHeight) / 2;

        // Draw the image on the canvas
        ctx.drawImage(img, x, y);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(new File([blob], file.name, { type: file.type }));
          } else {
            reject(new Error('Image processing failed.'));
          }
        }, file.type);
      };

      img.onerror = () => {
        reject(new Error('Image loading failed.'));
      };
    };

    reader.onerror = () => {
      reject(new Error('File reading failed.'));
    };

    reader.readAsDataURL(file);
  });
};


export const validateImage = async ({
  image,
  rejects,
  field,
  preview_field,
  setFieldTouched,
  setFieldError,
  setFieldValue,
  maxSize,
  dimension,
  ratio,
  adjustRatio = false,
  mime = ['image/jpeg', 'image/png', 'image/jpg'],
  errorRatio,
  errorFormat,
  errorSize,
}: ValidateImageParams) => {

  if (adjustRatio && image) {
    if (ratio) {
      const ratios: { [key: string]: number } = {
        '1/1': 1,
        '16/9': 16 / 9,
        '9/16': 9 / 16
      };

      const finalImage: any = await adjustImageRatio(image, ratios[ratio], '#000000');
      image = finalImage
    }
  }

  if (!image) {
    setFieldTouchedFn(field, setFieldTouched);
    if (rejects && rejects.length > 0) setFieldError?.(field, (errorFormat) ? errorFormat : `File type not supported.`);
    return;
  }

  const _URL = window.URL || window.webkitURL;
  const img = new Image();
  const objectUrl = _URL.createObjectURL(image);

  img.onload = async () => {
    setFieldTouchedFn(field, setFieldTouched);
    if (
      !validateImageRatio(img, ratio, field, setFieldError, setFieldTouched, errorRatio) ||
      !validateImageDimensions(img, dimension, field, setFieldError) ||
      !validateMimeType(image, mime, field, setFieldTouched, setFieldError, errorFormat) ||
      !validateFileSize(image, maxSize, field, setFieldTouched, setFieldError, errorSize)
    ) {
      _URL.revokeObjectURL(objectUrl);
      return;
    }

    setFieldError?.(field, undefined);
    await setFieldValue?.(preview_field, objectUrl);
    await setFieldValue?.(field, image, true);
    img.remove();
  };

  img.onerror = () => {
    setFieldError?.(field, 'Failed to load image.');
    _URL.revokeObjectURL(objectUrl);
  };

  img.src = objectUrl;
};


export const validateFile = ({
  file,
  rejects,
  field,
  preview_field,
  setFieldError,
  setFieldValue,
  maxSize,
}: {
  file?: File
  rejects?: FileRejection[]
  field: string
  preview_field?: string
  setFieldError: Function
  setFieldValue: Function
  maxSize?: number
}) => {
  if (!file) {
    if (rejects && rejects.length > 0) setFieldError(field, `File type not supported`)
    return
  }
  if (maxSize && file.size / 1024 / 1024 > maxSize) {
    setFieldError(field, `Max upload size is ${maxSize}MB`)
    return
  }
  const _URL = window.URL || window.webkitURL
  let objectUrl = _URL.createObjectURL(file)
  setFieldValue(field, file)
  setFieldValue(preview_field, objectUrl)
  return objectUrl
}

export const createRange = <T>(
  length: number,
  initializer: (index: number) => T
): T[] => {
  return [...new Array(length)].map((_, index) => initializer(index));
}

export const formatDate = (inputDate?: string): string => {
  if (inputDate) {
    const [month, day, year] = inputDate.split('/')
    return `${day}-${month}-${year}`
  } else {
    return ''
  }
}

export const reformatDate = (inputDate?: string): string => {
  if (inputDate) {
    const [month, day, year] = inputDate.split('-')
    return `${day}/${month}/${year}`
  } else {
    return ''
  }
}

export const formatDateWib = (date?: string, withTime: boolean = false): string => {
  if (!date) {
    return "-";
  }
  if (withTime) {
    return dayjs.utc(date).tz('Asia/Jakarta').format('DD/MM/YYYY HH:mm')
  }

  return dayjs.utc(date).tz('Asia/Jakarta').format('DD/MM/YYYY')
}

export const getVoucherType = (type?: string) => {
  if (type) {
    return VoucherType.find((item) => item.id === type)?.name || '';
  }
  return 'Voucher General';
}