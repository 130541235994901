import { useMutation } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { FC, useState } from 'react'
import { FormLabel, FormText, GAlert, GButton } from 'src/app/components/Libs'
import * as Yup from 'yup'
import { forgotPassword } from '../../../permissions/services/Permission.services'
import AuthScreens from '../../Screens'

const ForgotPassword: FC = () => {
  const [isSuccess, setIsSuccess] = useState(false)

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Make sure the email address you entered is correct.')
      .required('Email cannot empty'),
  })

  const initialValues = {
    email: '',
  }

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return forgotPassword(payload)
    },
    onSuccess: () => {
      setIsSuccess(true)
    },
    onError: (e: any) => {
      const status = e.response.status

      if (status >= 500) {
        formik.setStatus('Service Temporarily Unavailable. Please try again later.')
      } else {
        formik.setErrors({
          email: 'Email not found, please use registered email.'
        });
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  return (
    <div data-testid='forgot-password-page' className='w-full'>
      {isSuccess ? (
        <div className='mb-12 text-center'>
          <div className='mb-4 font-bold text-fs-5'>Please Check Your Inbox</div>
          <div className='mb-4 text-neutral-80'>
            Please click the link sent to your inbox to reset your password.
          </div>
          <GButton href={AuthScreens.LOGIN_EMAIL.PATH}>Back to Sign in</GButton>
        </div>
      ) : (
        <>
          <div className='mb-8 text-left'>
            <div className='font-bold mb- font-national2Condensed text-fs-4'>Forgot Password</div>
            <div className='text-neutral-80'>
              Please enter the registered email, we will send the link to your inbox to reset your password
            </div>
          </div>

          {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

          <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
            <div className='mb-12'>
              <FormLabel required className='mb-2'>
                Email
              </FormLabel>
              <FormText
                {...formik.getFieldProps('email')}
                prependInnerIcon='IconEmail'
                name='email'
                type='email'
                placeholder='Email address'
                maxLength={50}
                error={formik.errors.email}
                touched={formik.touched.email}
              />
            </div>

            <div className='flex items-center justify-between gap-4'>

              <GButton
                type='submit'
                size='large'
                className='flex-1'
                loading={submitFn.isLoading}
                disabled={submitFn.isLoading}
              >
                Reset Password
              </GButton>
            </div>
          </form>
        </>
      )}
    </div>
  )
}

export default ForgotPassword
