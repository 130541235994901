import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const OutletScreens = {
  OUTLET: {
    PATH: '/outlet',
    TITLE: 'Outlet Setting',
    TITLE_ALT: 'Outlet Management',
    PERMISSION: 'outlet.get-list',
  },
  LIST_OUTLET: {
    PATH: '/outlet/list',
    HEADER_NAME: 'Outlet List',
    TITLE: 'Outlet List',
    TITLE_ALT: 'Outlet List',
    PERMISSION: 'outlet.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_OUTLET: {
    PATH: '/outlet/list/detail/:id/:tab',
    TITLE: 'View Detail Outlet',
    TITLE_ALT: 'View Detail Outlet',
    PERMISSION: 'outlet.view-detail',
    buildPath: (id: string, tab: string) => `/outlet/list/detail/${id}/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
}

const listBreadcrumbs = generateBreadcrumb([OutletScreens.OUTLET])

OutletScreens.LIST_OUTLET.breadcrumbs = () => listBreadcrumbs
OutletScreens.DETAIL_OUTLET.breadcrumbs = () => listBreadcrumbs

export const DetailOutletTab = {
  INFO: {
    PATH: '/outlet/list/detail/:id/info',
    TITLE: 'Outlet Info',
    TITLE_ALT: 'Outlet Info',
    LABEL: 'Outlet Info',
    SLUG: 'info',
  },
  ADDRESS_DETAILS: {
    PATH: '/outlet/list/detail/:id/address-details',
    TITLE: 'Address Details',
    TITLE_ALT: 'Address Details',
    LABEL: 'Address Details',
    SLUG: 'address-details',
  },
  TAX_CHARGE: {
    PATH: '/outlet/list/detail/:id/tax-charge',
    TITLE: 'Tax & Charge',
    TITLE_ALT: 'Tax & Charge',
    LABEL: 'Tax & Charge',
    SLUG: 'tax-charge',
  },
  MENU_LIST: {
    PATH: '/outlet/list/detail/:id/menu-list',
    TITLE: 'Menu List',
    TITLE_ALT: 'Menu List',
    LABEL: 'Menu List',
    SLUG: 'menu-list',
  },
  OUTLET_HOUR: {
    PATH: '/outlet/list/detail/:id/outlet-hour',
    TITLE: 'Outlet Hour',
    TITLE_ALT: 'Outlet Hour',
    LABEL: 'Outlet Hour',
    SLUG: 'outlet-hour',
  },
}

export default OutletScreens
