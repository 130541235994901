import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const CustomerListScreens = {
  CUSTOMER_SETTING: {
    PATH: '/customer',
    TITLE: 'Customer Settings',
    TITLE_ALT: 'Customer Settings',
    PERMISSION: 'customer.get-list',
  },
  CUSTOMER: {
    PATH: '/customer',
    TITLE: 'Customer Management',
    TITLE_ALT: 'Customer Management',
    PERMISSION: 'customer.get-list',
  },
  LIST_CUSTOMER: {
    PATH: '/customer/list',
    HEADER_NAME: 'Customer List',
    TITLE: 'Customer List',
    TITLE_ALT: 'Customer List',
    PERMISSION: 'customer.get-list',
  },
  DETAIL_CUSTOMER: {
    PATH: '/customer/list/detail/:id/:tab',
    TITLE: 'Detail Customer',
    TITLE_ALT: 'Detail Customer',
    PERMISSION: 'customer.view-detail',
    buildPath: (id: string, tab: string) => `/customer/list/detail/${id}/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
}

const listBreadcrumbs = generateBreadcrumb([CustomerListScreens.LIST_CUSTOMER])

CustomerListScreens.DETAIL_CUSTOMER.breadcrumbs = () => listBreadcrumbs

export const DetailCustomerTab = {
  SUMMARY: {
    PATH: '/customer/list/detail/:id/summary',
    TITLE: 'Detail User',
    TITLE_ALT: 'Detail User',
    LABEL: 'Detail User',
    SLUG: 'summary',
  },
  ADDRESS_LIST: {
    PATH: '/customer/list/detail/:id/address-list',
    TITLE: 'Address List',
    TITLE_ALT: 'Address List',
    LABEL: 'Address List',
    SLUG: 'address-list',
  },
  ADDRESS_DETAIL: {
    PATH: '/customer/list/detail/:id/address-list/:aid',
    TITLE: 'Address Detail',
    TITLE_ALT: 'Address Detail',
    LABEL: 'Address Detail',
    SLUG: 'address-detail',
    buildPath: (id: string, aid: string) => `/customer/list/detail/${id}/address-list/${aid}`,
  },
  PAYMENT: {
    PATH: '/customer/list/detail/:id/payment',
    TITLE: 'Payment',
    TITLE_ALT: 'Payment',
    LABEL: 'Payment',
    SLUG: 'payment',
  },
  VOUCHER: {
    PATH: '/customer/list/detail/:id/voucher',
    TITLE: 'Voucher',
    TITLE_ALT: 'Voucher',
    LABEL: 'Voucher',
    SLUG: 'voucher',
  },
}

export default CustomerListScreens
